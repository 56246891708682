import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MobileService } from '../mobile/mobile.service';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private initialBreakpoint: number;

  constructor(
    private mobileService: MobileService,
    private modalController: ModalController
  ) {
    this.mobileService.celular$.subscribe((celular) => {
      this.initialBreakpoint = celular ? 1 : undefined;
    });
  }

  public async existeModal(): Promise<any> {
    return await this.modalController.getTop();
  }

  public async fecharModal(acao: any = ''): Promise<any> {
    await this.modalController.dismiss(acao);
  }

  public async abrirModal(
    id: string,
    cssClass: string,
    component: Function,
    data: any = {},
    callback: any = ''
  ): Promise<any> {
    const modal = await this.modalController.create({
      id: id,
      cssClass: cssClass,
      component: component,
      canDismiss: true,
      breakpoints: [0, 1],
      backdropDismiss: false,
      initialBreakpoint: this.initialBreakpoint,
      componentProps: {
        data: data,
      },
    });

    await modal.present();
    return await modal.onDidDismiss().then(callback);
  }
}
