import { CarrinhoService } from './../../../services/observers/carrinho.service';
import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { EnderecoService } from 'src/app/services/endereco.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from './../../../services/utils.service';
import { ModalController } from '@ionic/angular';
import { LojaService } from 'src/app/services/observers/loja.service';
import { CardapioService } from 'src/app/services/observers/cardapio.service';
import { ShinguruService } from 'src/app/services/shinguru/shinguru.service';

interface IAcao {
  titulo: string;
  paragrafos: Array<string>;
  label_acao: string;
  acao: string;
}

@Component({
  selector: 'app-sair',
  templateUrl: './sair.page.html',
  styleUrls: ['./sair.page.scss'],
})
export class SairPage implements OnInit {
  public data: { data: string };
  public dados_acao: IAcao;
  public carregando: boolean = false;

  constructor(
    private api: ApiService,
    private cardapioService: CardapioService,
    private carrinhoService: CarrinhoService,
    private enderecoService: EnderecoService,
    private usuarioService: UsuarioService,
    private shinguruService: ShinguruService,
    private utilsService: UtilsService,
    private lojaService: LojaService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    switch (this.data.data) {
      case 'logout':
        this.dados_acao = {
          titulo: 'Sair',
          paragrafos: ['Você deseja sair?'],
          label_acao: 'Sair',
          acao: 'logout',
        };
        break;
      case 'excluir-conta':
        this.dados_acao = {
          titulo: 'Excluir sua conta',
          paragrafos: [
            'Você tem certeza que quer excluir sua conta?',
            'De acordo com LGPD, n 13.709, ao excluir sua conta, todos seus dados serão removidos permanentemente. Caso tenha interesse em voltar, é só fazer um novo cadastro.',
          ],
          label_acao: 'Excluir minha conta',
          acao: 'excluir-conta',
        };
        break;
      case 'inativar-conta':
        this.dados_acao = {
          titulo: 'Desativar sua conta',
          paragrafos: [
            'Você tem certeza que quer desativar sua conta?',
            'Ao desativar sua conta nenhuma informação será perdida.',
          ],
          label_acao: 'Desativar minha conta',
          acao: 'inativar-conta',
        };
        break;
      default:
        break;
    }
  }

  public realizarAcao(acao: string): void {
    switch (acao) {
      case 'logout':
        this.sair();
        break;
      case 'excluir-conta':
        this.manipularConta('cliente');
        break;
      case 'inativar-conta':
        this.manipularConta('inativar_conta');
        break;
      default:
        break;
    }
  }

  async manipularConta(metodo: string): Promise<void> {
    const metodos = {
      inativar_conta:
        'A sua conta está inativa, mas se quiser reativa-la é simples! Basta tentar o login novamente que já vamos te identificar. Esperamos por você.',
      cliente:
        'A sua conta foi excluída permanentemente, mas estaremos te esperando caso queira se cadastrar novamente.',
    };

    this.carregando = true;

    let res;
    let req = {
      jwt: this.utilsService.getStorage('usuario')['jwt'],
    };
    if (metodo === 'cliente') {
      res = await this.api.delete(metodo, req);
    } else if (metodo === 'inativar_conta') {
      res = await this.api.post(metodo, req);
    }

    if (res.status === 'success') {
      this.carregando = false;

      const mensagem = metodos[metodo];

      if (mensagem) this.utilsService.exibirToast(mensagem, 'sucesso', 5500);

      this.sair();
    }
  }

  private sair(): void {
    localStorage.clear();
    this.carrinhoService.limparCarrinho();
    this.enderecoService.limparDadosArmazenados();
    this.usuarioService.atualizarUsuario(null);
    this.lojaService.atualizarLoja(null);
    this.cardapioService.buscarCardapio(null);
    this.shinguruService.confirmarConvite(false);
    this.utilsService.navegarPaginaRoot('tabs/cardapio');
    this.dismiss();
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
