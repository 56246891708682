import { Component, OnInit } from '@angular/core';

import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-termos-condicoes',
  templateUrl: './termos-condicoes.component.html',
  styleUrls: ['./termos-condicoes.component.scss'],
})
export class TermosCondicoesComponent implements OnInit {
  public topo: boolean;
  public data: { modal: true };

  constructor(
    private modalService: ModalsService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.topo = this.utilsService.mostrarTopo(
      '/tabs/perfil',
      '/tabs/termos-uso'
    );
  }

  public navegar(caminho: string): void {
    this.utilsService.navegarPagina(caminho);
  }

  public dismiss(): void {
    this.modalService.fecharModal();
  }
}
