import { Injectable } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Injectable({
  providedIn: 'root'
})
export class OneSignalService {

  constructor(
    private oneSignal: OneSignal,
    private utils: UtilsService,
    private api: ApiService,
  ) { }

  async configurarPush() {

    this.oneSignal.startInit('d764c2cd-843f-4465-9a87-7fbdf19ddab5', '776044976683');

    this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

    this.oneSignal.handleNotificationReceived().subscribe(res => {

      if (res.payload.additionalData != undefined) {

        const pedidoId = res.payload.additionalData.pedidoId;

        this.utils.setStorage('pedidoId', pedidoId);
        this.utils.navegarPagina('tabs/detalhes-pedido');

      }

    });

    this.oneSignal.handleNotificationOpened().subscribe(res => {

      if (res.notification.payload.additionalData != undefined) {

        const pedidoId = res.notification.payload.additionalData.pedidoId;

        this.utils.setStorage('pedidoId', pedidoId);
        this.utils.navegarPagina('tabs/detalhes-pedido');

      }

    });

    this.oneSignal.endInit();

  }

  public async registrarToken() {

    await this.oneSignal.getIds().then(async data => {

      const req = {
        token: data.userId
      };

      await this.api.post("dispositivo", req);

    });

  }

  public async registraTags() {

    const res = await this.api.get("tag");

    if (res.status === 'sucess') {

      this.oneSignal.sendTags({
        idade: res.data.idade,
        cidade: res.data.cidade,
        estado: res.data.estado,
        qtd_pedidos: res.data.qtdPedidos
      });

    }

  }

}
