import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { Platform } from '@ionic/angular';

import { v4 as uuidv4 } from 'uuid';

import { OneSignalService } from './services/one-signal.service';
import { MobileService } from './services/mobile/mobile.service';
import { UtilsService } from 'src/app/services/utils.service';

export let CELULAR: boolean;

declare let dataLayer: any;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private utilsService: UtilsService,
    private mobile: MobileService,
    private oneSignal: OneSignalService,
    private platform: Platform,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.startApp();

    this.mobile.celular$.subscribe((celular) => {
      CELULAR = celular;
    });
  }

  ngOnInit(): void {
    this.viewPage();
  }

  private startApp(): void {
    let uuid = uuidv4();

    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.src =
      `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=braspag_grupotrigo_split` +
      uuid;
    script.id = 'metrix_head';
    script.async = true;
    this.renderer.appendChild(document.head, script);

    const script2 = this.renderer.createElement(
      'noscript'
    ) as HTMLScriptElement;
    const scriptBody = this.renderer.createElement(
      'iframe'
    ) as HTMLScriptElement;
    scriptBody.src =
      `https://h.online-metrix.net/fp/tags.js?org_id=1snn5n9w&session_id=braspag_grupotrigo_split` +
      uuid;
    scriptBody.id = 'metrix_body';
    script2.id = 'metrix_noscript';
    scriptBody.style.width = '100px';
    scriptBody.style.height = '100px';
    scriptBody.style.border = '0';
    scriptBody.style.position = 'absolute';
    scriptBody.style.top = '-5000px';

    this.renderer.appendChild(script2, scriptBody);
    this.renderer.appendChild(document.body, script2);

    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.oneSignal.configurarPush();
      }
    });
  }

  private viewPage(): void {
    this.router.events.subscribe((event) => {
      let tipo_evento: string = 'virtal_page_view';

      if (this.utilsService.getStorage('canal-compra') == 'whatsapp') {
        tipo_evento = 'page_view_whats_app';
      }

      if (event instanceof NavigationEnd) {
        dataLayer.push({
          event: tipo_evento,
          path: event.url,
        });

        if (this.utilsService.getStorage('usuario')) {
          dataLayer.push({
            event: 'user_id',
            path: event.url,
            user_id: this.utilsService.getStorage('usuario').id_externo,
          });
        }
      }
    });
  }
}
