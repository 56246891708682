import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import {
  Beneficio,
  BeneficioResgatavel,
  Carteira,
  Nivel,
} from 'src/app/interfaces/Shinguru.interface';

import {
  areia,
  coral,
  onda,
  perola,
} from 'src/app/constants/beneficios-shinguru';

import { ICupom } from 'src/app/interfaces/IBeneficio.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { Subscription } from 'rxjs';

import { ApiService } from '../api.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from '../utils.service';
import { ILoja } from 'src/app/interfaces/ILoja.interface';


@Injectable({
  providedIn: 'root',
})
export class ShinguruService {
  private beneficioResgatavelSubject = new BehaviorSubject<
    BeneficioResgatavel[] | null
  >([]);

  private conviteSubject = new BehaviorSubject<boolean>(false);

  public beneficioResgatavel$: Observable<BeneficioResgatavel[] | null> =
    this.beneficioResgatavelSubject.asObservable();

  public convite$: Observable<boolean> = this.conviteSubject.asObservable();

  private beneficiosPorNivel: Record<string, Beneficio[]> = {
    Areia: areia,
    Onda: onda,
    Coral: coral,
    Pérola: perola,
  };

  private saldoFidelidadeSubject = new BehaviorSubject<Nivel | null>(null);
  public saldoFidelidade$: Observable<Nivel | null> =
    this.saldoFidelidadeSubject.asObservable();

  private usuario: IUsuario;
  private usuarioSubscription: Subscription;

  constructor(
    private apiService: ApiService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.usuarioSubscription = this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );

    if (this.usuario) {
      this.buscarBeneficios();
    }
  }

  public async saldoFidelidade(jwt: string): Promise<Nivel> {
    const res = await this.apiService.get('fidelidade', {
      jwt: jwt,
    });

    if (res.data && res.data.nivel !== null) {
      const dados: Nivel = {
        ...this.setDados(res.data?.nivel, res.data?.saldo_pontos),
        fidelidade: true,
      };

      this.saldoFidelidadeSubject.next(dados);
      return dados;
    }

    const dados: Nivel = {
      ...this.setDados('Areia'),
      fidelidade: false,
    };
    this.saldoFidelidadeSubject.next(dados);

    return dados;
  }

  public async extratoFidelidade(
    jwt: string,
    meses: number = 3,
    id_marca: number = 47
  ): Promise<Nivel> {
    const saldo_fidelidade = await this.saldoFidelidade(jwt);

    if (saldo_fidelidade.fidelidade) {
      const res = await this.apiService.get('fidelidade_extrato', {
        jwt: jwt,
        id_marca: id_marca,
        meses: meses,
      });

      if (res.status === 'success') {
        return {
          ...this.setDados(
            res.data?.nivel_atual,
            saldo_fidelidade.pontos_cliente
          ),
          fidelidade: true,
          dados_fidelidade: res.data,
        };
      }
    }

    return {
      ...this.setDados('Areia'),
      fidelidade: false,
    };
  }

  /**
   * @param jwt
   * @param filtro U-Utilizados, N-Não utilizados, I-Beneficios que o cliente não conseguiu utilizar, null-Todos benefícios
   * @param id_marca
   */
  public async carteiraFidelidade(
    jwt: string,
    filtro: string | null = null,
    id_marca = 47
  ): Promise<BeneficioResgatavel[] | null> {
    const res = await this.apiService.get('fidelidade_carteira', {
      jwt: jwt,
      id_marca: id_marca,
      filtro_utilizado: filtro,
    });

    if (res.data) {
      let beneficio: BeneficioResgatavel[] = res.data.map(
        (beneficio: Carteira) => {
          const status: string =
            beneficio.disponivel && !beneficio.reservado
              ? 'Disponível'
              : beneficio.reservado
                ? 'Reservado'
                : beneficio.utilizado
                  ? 'Utilizado'
                  : '';

          return {
            carteira: beneficio,
            classe: this.utilsService.validUri(status),
            status: status,
          };
        }
      );

      this.beneficioResgatavelSubject.next(beneficio);
      return beneficio;
    }

    this.beneficioResgatavelSubject.next(null);
    return null;
  }

  public progresso(pontos: number, total_pontos: number): number {
    return (pontos / total_pontos) * 100;
  }

  public setDados(nivel: string, saldo_pontos: number = 0): Nivel {
    const niveis: Record<string, Nivel> = {
      Areia: {
        background: 'var(--areia)',
        beneficios: this.beneficiosPorNivel['Areia'],
        border_color: 'var(--marrom3)',
        classe: 'areia',
        icone: '/assets/fidelidade/niveis/areia.svg',
        logo: '/assets/logo/logo-marrom.svg',
        max_pontos: 800,
        min_pontos: 1,
        nivel: 1,
        nome: 'Areia',
        pontos_cliente: saldo_pontos,
      },
      Onda: {
        background: 'var(--onda)',
        beneficios: this.beneficiosPorNivel['Onda'],
        border_color: 'var(--marrom2)',
        classe: 'onda',
        icone: '/assets/fidelidade/niveis/onda.svg',
        logo: '/assets/logo/logo-marrom.svg',
        max_pontos: 1600,
        min_pontos: 801,
        nivel: 2,
        nome: 'Onda',
        pontos_cliente: saldo_pontos,
      },
      Coral: {
        background: 'var(--coral)',
        beneficios: this.beneficiosPorNivel['Coral'],
        border_color: 'var(--marrom3)',
        classe: 'coral',
        icone: '/assets/fidelidade/niveis/coral.svg',
        logo: '/assets/logo/logo-branco.svg',
        max_pontos: 3200,
        min_pontos: 1601,
        nivel: 3,
        nome: 'Coral',
        pontos_cliente: saldo_pontos,
      },
      Pérola: {
        background: 'var(--atlantida)',
        beneficios: this.beneficiosPorNivel['Pérola'],
        border_color: 'var(--creme4)',
        classe: 'atlantida',
        icone: '/assets/fidelidade/niveis/atlantida.svg',
        logo: '/assets/logo/logo-branco.svg',
        max_pontos: 3200,
        min_pontos: 3200,
        nivel: 4,
        nome: 'Pérola',
        pontos_cliente: saldo_pontos,
      },
    };

    return niveis[nivel] || niveis['Areia'];
  }

  private beneficios(nivel: string): Beneficio[] {
    const beneficios: Record<string, Beneficio[]> = {
      Areia: areia,
      Onda: onda,
      Coral: coral,
      Pérola: perola,
    };

    beneficios[nivel].forEach(async (beneficio) => {
      beneficio.icone = `assets/fidelidade/niveis/beneficios/${this.utilsService.validUri(
        beneficio.nome
      )}.svg`;
    });

    return beneficios[nivel];
  }

  public async buscarBeneficios(): Promise<void> {
    const niveisMap = {
      '1': 'Areia',
      '2': 'Onda',
      '3': 'Coral',
      '4': 'Pérola'
    };

    this.beneficiosPorNivel = {
      Areia: [],
      Onda: [],
      Coral: [],
      Pérola: [],
    };

    try {
      const req = {
        jwt: this.usuario.jwt,
      };

      const res = await this.apiService.get('buscar_beneficios', req);

      if (res.data) {
        res.data.forEach((item) => {
          const nivelNome = niveisMap[item.nivel];
          if (nivelNome) {
            this.beneficiosPorNivel[nivelNome].push({
              icone: item.icone,
              nome: item.nome,
              descricao: item.descricao,
            });
          }
        });
      }
    } catch (error) {
      this.utilsService.exibirToast(error.message, 'erro');
    }
  }

  async cancelarReserva(beneficio: ICupom, toast: boolean = false): Promise<void> {
    const req = {
      jwt: this.usuario.jwt,
      loja_id: beneficio.loja_id,
      id_empresa_cupom: beneficio.id_empresa_cupom,
      id_cupom: beneficio.id_cupom,
      versao_cupom: beneficio.versao_cupom,
      sequencial: beneficio.sequencial,
    };

    const res = await this.apiService.delete('fidelidade_cupom', req);

    if (res.status === 'success') {
      this.carteiraFidelidade(this.usuario.jwt);
      if (toast)
        this.utilsService.exibirToast(res.message, 'sucesso');
    } else {
      this.utilsService.exibirToast(res.message, 'erro');
    }

    this.utilsService.removeStorage('beneficio-shinguru');
  }

  public async verConvite(jwt: string) {
    const dados = await this.saldoFidelidade(jwt);

    if (dados.nivel == 4 && !this.conviteSubject.value && !this.utilsService.getStorage('convite')) {
      this.utilsService.navegarPagina('/shinguru/convite');
    }
  }

  public confirmarConvite(opcao: boolean) {
    this.conviteSubject.next(opcao);

    if (opcao == true) {
      this.utilsService.setStorage('convite', true);
    }
  }
}
