import { Injectable } from '@angular/core';
import { IBanner } from 'src/app/interfaces/IBanner.interface';
import {
  IAdicionais,
  ICarrinho,
  IDadosTotalCarrinho,
} from 'src/app/interfaces/ICarrinho.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IProduto } from 'src/app/interfaces/IProduto.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import {
  IAddCoupon,
  IAddressNotAnswered,
  IAnalyticsEvent,
  IMessageError,
  IPromotion,
} from 'src/app/interfaces/analytics/IAnalyticsEvent.interface';

declare let dataLayer: any;
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {}

  /**Função que contemplará eventos de: select_item, view_item, add_to_cart e remove_from_cart
   * @param event O evento que deve ser registrado no dataLayer
   * @param item Produto que foi selecionado/visualizado pelo usuário
   * @param loja Loja para preencher o campo de affiliation e item_brand
   * @returns Objeto completo que deve ser enviado para o dataLayer
   */
  public item(
    event: string,
    item: IProduto | ICarrinho,
    loja: ILoja,
    canalCompra: string = ''
  ): IAnalyticsEvent {
    const [price, value] = this.price(item);

    let eventObj: IAnalyticsEvent = {
      event: event,
      affiliation: loja !== null ? loja.nome : 'Gurumê',
      ecommerce: {
        affiliation: loja !== null ? loja.nome : 'Gurumê',
        origin: canalCompra,
        items: [
          {
            item_list_id: String(item.categoriaId),
            item_list_name: (item as IProduto).categoria,
            item_id: String((item as IProduto).cod),
            item_name: item.produto ? item.produto : item.nome,
            currency: 'BRL',
            item_brand: 'Gurumê',
            item_category: (item as IProduto).categoria,
            item_category2: false,
            price: price,
            quantity: item.qtd ? item.qtd : 1,
          },
        ],
      },
    };

    if (event === 'add_to_cart' || event === 'remove_from_cart') {
      this.adicionarParametros(eventObj, 'add_to_cart', item);

      eventObj['value'] = value;
      eventObj.ecommerce['value'] = value;

      if ('adicionais' in item) {
        let adicionais_selecionados = this.adicionais(item.adicionais);

        if (adicionais_selecionados.length > 0) {
          this.adicionaisSelecionados(eventObj, item);
        }
      }
    }

    return eventObj;
  }

  /**Quando haver muitos itens que não possuem relação entre si.
   * Função que contemplará eventos de: remove_from_cart (na opção de limpar o carrinho)
   * @param event O evento que deve ser registrado no dataLayer
   * @param itens Array de produtos que foram selecionados
   * @param loja Loja para preencher o campo de affiliation e item_brand
   * @returns Objeto completo que deve ser enviado para o dataLayer
   */
  public itens(
    event: string,
    itens: Array<IProduto> | Array<ICarrinho> = null,
    loja: ILoja,
    valores: IDadosTotalCarrinho = null,
    data: any = null,
    usuario: IUsuario | null = null
  ): IAnalyticsEvent {
    let eventObj: IAnalyticsEvent;

    if (
      event === 'remove_from_cart' ||
      event === 'view_cart' ||
      event === 'begin_checkout' ||
      event === 'purchase' ||
      event === 'add_payment_info'
    ) {
      eventObj = {
        event: event,
        affiliation: loja.nome,
        ecommerce: {
          affiliation: loja.nome,
          items: [],
        },
      };
    }

    if (event === 'view_item_list') {
      eventObj = {
        event: event,
        ecommerce: {
          origin: data,
          items: [],
        },
      };
    }

    if (
      event === 'remove_from_cart' ||
      event === 'view_cart' ||
      event === 'begin_checkout' ||
      event === 'purchase' ||
      event === 'view_item_list' ||
      event === 'add_payment_info'
    ) {
      if (itens !== null) {
        itens.forEach((item) => {
          const [price] = this.price(item);

          eventObj.ecommerce.items.push({
            item_list_id:
              String(item.categoriaId) !== undefined ? item.categoriaId : '',
            item_list_name:
              (item as IProduto).categoria !== undefined ? item.categoria : '',
            item_id: String((item as IProduto).cod),
            item_name: item.produto ? item.produto : item.nome,
            currency: 'BRL',
            item_brand: 'Gurumê',
            item_category: (item as IProduto).categoria ? item.categoria : '',
            price: price,
            quantity: item.qtd ? item.qtd : 1,
          });

          if (event !== 'view_item_list') {
            eventObj.ecommerce.items[0]['item_category2'] = false;
          }

          this.adicionarParametros(eventObj, event, item);

          if ('adicionais' in item) {
            let adicionais_selecionados = this.adicionais(item.adicionais);

            if (adicionais_selecionados.length > 0) {
              this.adicionaisSelecionados(eventObj, item);
            }
          }
        });
      }
    }

    if (event === 'remove_from_cart' || event === 'view_cart') {
      const valor_itens =
        typeof valores.valor_itens == 'string'
          ? parseFloat(valores.valor_itens)
          : valores.valor_itens;

      eventObj.ecommerce['value'] = valor_itens;
    } else if (
      event === 'begin_checkout' ||
      event === 'purchase' ||
      event === 'add_payment_info'
    ) {
      const valor_total =
        typeof valores.total == 'string'
          ? parseFloat(valores.total)
          : valores.total;

      eventObj.ecommerce['value'] = valor_total;

      let coupon: string = '';
      let discount: number = 0;

      if (valores.desconto !== null) {
        coupon = valores.desconto.cupom;
        discount = valores.valor_desconto_porc;
      }

      eventObj.ecommerce['coupon'] = coupon;
      eventObj.ecommerce.items.forEach((item) => {
        item['discount'] = discount;
      });
    }

    if (event === 'purchase') {
      eventObj.ecommerce['shipping'] = data.shipping;
      eventObj.ecommerce['transaction_id'] = data.transaction_id;
      eventObj['transaction_cashback'] = data.transaction_cashback;
      eventObj['shipping_tier'] = 'Delivery'; // DLV

      if (usuario) {
        eventObj['telefone'] = usuario.celular;
        eventObj['email'] = usuario.email;
      }

      eventObj['transaction_discount'] =
        valores.desconto?.tipo == 'fixo' ? valores.desconto.desconto.valor : 0;
    }

    if (event === 'purchase' || event === 'add_payment_info') {
      eventObj.ecommerce['payment_type'] = data.payment_type;
    }

    return eventObj;
  }

  private adicionarParametros(
    eventObj: IAnalyticsEvent,
    event: string,
    item: IProduto | ICarrinho
  ) {
    const [opcionais, obrigatorios] = this.itemVariant(item);

    if (
      event === 'add_to_cart' ||
      event === 'view_cart' ||
      event === 'remove_from_cart' ||
      event === 'begin_checkout' ||
      event === 'purchase' ||
      event === 'add_payment_info'
    ) {
      eventObj.ecommerce['currency'] = 'BRL';
      eventObj.ecommerce.items[0]['item_variant'] =
        (item as ICarrinho).obrigatorios.length == 0 &&
        (item as ICarrinho).opcionais.length > 0
          ? opcionais
          : (item as ICarrinho).obrigatorios.length > 0 &&
            (item as ICarrinho).opcionais.length == 0
          ? obrigatorios
          : (item as ICarrinho).obrigatorios.length > 0 &&
            (item as ICarrinho).opcionais.length > 0
          ? obrigatorios + ' | ' + opcionais
          : '';

      eventObj.ecommerce.items.map((obj_item) => {
        obj_item['item_variant'] =
          (item as ICarrinho).obrigatorios.length == 0 &&
          (item as ICarrinho).opcionais.length > 0
            ? opcionais
            : (item as ICarrinho).obrigatorios.length > 0 &&
              (item as ICarrinho).opcionais.length == 0
            ? obrigatorios
            : (item as ICarrinho).obrigatorios.length > 0 &&
              (item as ICarrinho).opcionais.length > 0
            ? obrigatorios + ' | ' + opcionais
            : '';

        if (
          obj_item.item_category2 == undefined ||
          obj_item.item_category2 == null
        ) {
          obj_item['item_category2'] = false;
        } else {
          obj_item['item_category2'] = obj_item.item_category2;
        }
      });
    }
  }

  private adicionaisSelecionados(
    eventObj: IAnalyticsEvent,
    item: IProduto | ICarrinho
  ) {
    if ('adicionais' in item) {
      let adicionais_selecionados = this.adicionais(item.adicionais);

      if (adicionais_selecionados.length > 0) {
        adicionais_selecionados.forEach((adicional) => {
          eventObj.ecommerce.items.push({
            item_list_id: String(item.categoriaId),
            item_list_name: item.categoria,
            item_id: String(adicional.cod),
            item_name: adicional.nome,
            currency: 'BRL',
            item_brand: 'Gurumê',
            item_category: item.categoria,
            item_category2: adicional.itemCategory2,
            item_category3: String(item.cod),
            item_variant: '',
            price: adicional.preco.unitario.valor,
            quantity: adicional.qtd,
          });
        });
      }
    }
  }

  private itemVariant(item: IProduto | ICarrinho) {
    let opcionais: string[] = [];
    let obrigatorios: string[] = [];

    if ('opcionais' in item) {
      item.opcionais.forEach((opc) => {
        if (opc.selecionado) {
          opcionais.push(opc.opcional);
        }
      });
    }

    if ('obrigatorios' in item) {
      item.obrigatorios.forEach((obr) => {
        if (obr.selecionado) {
          obrigatorios.push(obr.opcional);
        }
      });
    }

    return [opcionais.join(', '), obrigatorios.join(', ')];
  }

  private price(item: IProduto | ICarrinho): [number, number] {
    let price: number;
    let value: number;

    if ('total' in item.preco) {
      price = item.preco.unitario.valor;
      value = item.preco.total.valor;
    } else {
      price = item.preco.valor;
      value = item.preco.valor;
    }

    return [price, value];
  }

  private adicionais(adicionais: Array<IAdicionais>) {
    return adicionais.filter((item) => item.qtd > 0);
  }

  public addCoupon(
    coupon: string,
    itsValid: boolean = false,
    affiliation: string
  ): IAddCoupon {
    const objEvent = {
      event: 'addCoupon',
      coupon: coupon,
      itsValid: itsValid,
      affiliation: affiliation,
    };

    return objEvent;
  }

  public addressNotAnswered(cep: string): IAddressNotAnswered {
    const objEvent = {
      event: 'address_not_answered',
      cep: cep,
    };

    return objEvent;
  }

  public promotion(
    event: string,
    banner: IBanner | null = null,
    banners: Array<IBanner> | null = null
  ): IPromotion {
    let objEvent: IPromotion = {
      event: event,
      items: [],
    };

    if (banners !== null) {
      banners.forEach((banner) => {
        objEvent.items.push({
          creative_name: banner.imagem,
          creative_slot: '',
          promotion_id: banner.id,
          promotion_name: banner.nome,
        });
      });

      return objEvent;
    }

    if (banner !== null) {
      objEvent.items.push({
        creative_name: banner.imagem,
        creative_slot: '',
        promotion_id: banner.id,
        promotion_name: banner.nome,
      });

      return objEvent;
    }
  }

  public showMessageError(mensagem: string): IMessageError {
    return {
      event: 'showErrorMessage',
      message: mensagem,
    };
  }

  public registrarEvento(objeto: any) {
    dataLayer.push({ ecommerce: null });
    dataLayer.push(objeto);
  }
}
