import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { FormService } from 'src/app/services/form/form.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { SairPage } from 'src/app/modals/usuario-v2/sair/sair.page';

@Component({
  selector: 'app-minha-conta',
  templateUrl: './minha-conta.component.html',
  styleUrls: ['./minha-conta.component.scss'],
})
export class MinhaContaComponent implements OnInit {
  public usuario: IUsuario;
  public form: FormGroup;
  public carregando: boolean = false;
  public topo: boolean;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService,
    private modalService: ModalsService
  ) {
    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });

    this.form = this.formBuilder.group({
      nome: [this.usuario.nome, Validators.required],
      celular: [
        this.usuario.celular,
        [Validators.required, this.formService.validaCelular()],
      ],
      data_nascimento: [
        this.usuario.dataNascimento,
        this.formService.validaData(),
      ],
      email: [this.usuario.email, [Validators.required, Validators.email]],
    });
  }

  ngOnInit() {
    this.topo = this.utilsService.mostrarTopo(
      '/tabs/perfil',
      '/tabs/minha-conta'
    );
  }

  public validaForm() {
    if (this.form.dirty) {
      if (
        this.formService.validaForm(this.form, {
          nome: 'nome',
          celular: 'celular',
          email: 'e-mail',
          data_nascimento: 'data de nascimento',
        })
      )
        this.salvar();
    } else this.utilsService.exibirToast('Nenhum dado foi alterado.');
  }

  async salvar() {
    const req = {
      jwt: this.usuario.jwt,
      nome: this.form.get('nome').value,
      celular: this.form.get('celular').value,
      cpf: this.usuario.cpf,
      dataNascimento: this.form.get('data_nascimento').value,
      email: this.form.get('email').value,
    };

    this.carregando = true;

    const res = await this.apiService.put('cliente', req);

    this.carregando = false;

    if (res.status === 'success') {
      this.usuarioService.atualizarUsuario(res.data);
      this.utilsService.exibirToast(res.message, 'sucesso');
    } else {
      this.utilsService.exibirToast(res.message, 'erro');
    }
  }

  desabilitarConta(acao: string) {
    this.modalService.abrirModal(
      'modal-desabilitar-conta',
      'modal-desabilitar-conta',
      SairPage,
      { data: acao }
    );
  }

  public navegar(caminho: string) {
    this.utilsService.navegarPagina(caminho);
  }

  public mascarar(tipo: string): void {
    const campo = this.form.get(tipo);

    if (campo !== null) {
      let letras: string = campo.value.replace(/[^a-zA-ZÀ-ÖØ-öø-ÿ\s']/g, '');

      campo.setValue(letras);
    }
  }
}
