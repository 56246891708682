import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthenticatorService } from './services/usuario/authenticator.service';
import { LoginAuthenticatorService } from './services/usuario/login-authenticator.service';
import { MobileService } from './services/mobile/mobile.service';
import { ShinguruGuard } from './services/shinguru/shinguru-guard.service';

import { MinhaContaComponent } from './components/usuario-v2/minha-conta/minha-conta.component';
import { NotificacoesComponent } from './components/usuario-v2/notificacoes/notificacoes.component';
import { ConfigurarNotificacoesComponent } from './components/usuario-v2/configurar-notificacoes/configurar-notificacoes.component';
import { MeusCartoesComponent } from './components/usuario-v2/meus-cartoes/meus-cartoes.component';
import { MeusEnderecosComponent } from './components/usuario-v2/meus-enderecos/meus-enderecos.component';
import { TermosCondicoesComponent } from './components/usuario-v2/termos-condicoes/termos-condicoes.component';
import { PoliticaPrivacidadeComponent } from './components/usuario-v2/politica-privacidade/politica-privacidade.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'tabs/cardapio',
    pathMatch: 'full',
  },
  {
    path: 'cardapio',
    loadChildren: () => import('./pages/cardapio/cardapio.module').then((m) => m.CardapioPageModule),
  },
  {
    path: 'whatsapp/:uuid',
    loadChildren: () => import('./pages/whatsapp/whatsapp.module').then((m) => m.WhatsappPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/onboarding-v2/login/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [AuthenticatorService],
  },
  {
    path: 'login-email',
    loadChildren: () => import('./pages/onboarding-v2/login/login-email/login-email.module').then(m => m.LoginEmailPageModule),
    canActivate: [AuthenticatorService]
  },
  {
    path: 'login-telefone',
    loadChildren: () => import('./pages/onboarding-v2/login/login-telefone/login-telefone.module').then(m => m.LoginTelefonePageModule),
    canActivate: [AuthenticatorService]
  },
  {
    path: 'confirmar-codigo',
    loadChildren: () => import('./pages/onboarding-v2/login/confirmar-codigo/confirmar-codigo.module').then(m => m.ConfirmarCodigoPageModule),
    canActivate: [AuthenticatorService]
  },
  {
    path: 'cadastro-dados',
    loadChildren: () => import('./pages/onboarding-v2/cadastro-dados/cadastro-dados.module').then(m => m.CadastroDadosPageModule),
    canActivate: [AuthenticatorService]
  },
  {
    path: 'receber-endereco',
    loadChildren: () => import('./pages/onboarding-v2/fluxo-endereco/receber-endereco/receber-endereco.module').then(m => m.ReceberEnderecoPageModule)
  },
  {
    path: 'buscar-endereco',
    loadChildren: () => import('./pages/onboarding-v2/fluxo-endereco/buscar-endereco/buscar-endereco.module').then(m => m.BuscarEnderecoPageModule)
  },
  {
    path: 'mapa-confirmacao',
    loadChildren: () => import('./pages/onboarding-v2/fluxo-endereco/mapa-confirmacao/mapa-confirmacao.module').then(m => m.MapaConfirmacaoPageModule)
  },
  {
    path: 'cadastro-endereco',
    loadChildren: () => import('./pages/onboarding-v2/fluxo-endereco/cadastro-endereco/cadastro-endereco.module').then(m => m.CadastroEnderecoPageModule)
  },
  {
    path: 'cadastro-permissoes',
    loadChildren: () => import('./pages/onboarding-v2/cadastro-permissoes/cadastro-permissoes.module').then(m => m.CadastroPermissoesPageModule),
    canActivate: [AuthenticatorService]
  },
  {
    path: 'produto',
    loadChildren: () => import('./modals/produto/produto.module').then((m) => m.ProdutoPageModule),
  },
  {
    path: 'sacola',
    loadChildren: () => import('./modals/carrinho/carrinho.module').then((m) => m.CarrinhoPageModule),
  },
  {
    path: 'finalizar-pedido',
    loadChildren: () => import('./pages/finalizar-pedido/finalizar-pedido.module').then(m => m.FinalizarPedidoPageModule),
    canActivate: [LoginAuthenticatorService]
  },
  {
    path: 'tabs/historico',
    loadChildren: () => import('./pages/historico/historico.module').then(m => m.HistoricoPageModule),
    canActivate: [LoginAuthenticatorService]
  },
  {
    path: 'tabs/restaurantes',
    loadChildren: () => import('./pages/restaurante/restaurantes/restaurantes.module').then((m) => m.RestaurantesPageModule),
  },
  {
    path: 'tabs/buscar-restaurante',
    loadChildren: () => import('./pages/restaurante/buscar-restaurante/buscar-restaurante.module').then(m => m.BuscarRestaurantePageModule)
  },
  {
    path: 'tabs/detalhes-restaurante',
    loadChildren: () => import('./pages/restaurante/detalhes-restaurante/detalhes-restaurante.module').then(m => m.DetalhesRestaurantePageModule)
  },
  {
    path: 'tabs/perfil',
    loadChildren: () => import('./pages/usuario-v2/perfil/perfil.module').then(m => m.PerfilPageModule),
    canActivate: [LoginAuthenticatorService]
  },
  {
    path: 'tabs/minha-conta',
    component: MinhaContaComponent,
    canActivate: [LoginAuthenticatorService, MobileService],
  },
  {
    path: 'tabs/meus-cartoes',
    component: MeusCartoesComponent,
    canActivate: [LoginAuthenticatorService, MobileService],
  },

  {
    path: 'tabs/meus-enderecos',
    component: MeusEnderecosComponent,
    canActivate: [MobileService],
  },
  {
    path: 'tabs/notificacoes',
    component: NotificacoesComponent,
    canActivate: [LoginAuthenticatorService, MobileService],
  },
  {
    path: 'tabs/configurar-notificacoes',
    component: ConfigurarNotificacoesComponent,
    canActivate: [LoginAuthenticatorService, MobileService],
  },
  {
    path: 'tabs/termos-uso',
    component: TermosCondicoesComponent,
  },
  {
    path: 'tabs/politicas-privacidade',
    component: PoliticaPrivacidadeComponent,
  },
  {
    path: 'pagamento-pedido-pix',
    loadChildren: () => import('./pages/pagamento-pedido-pix/pagamento-pedido-pix.module').then((m) => m.PagamentoPedidoPixPageModule),
  },
  {
    path: 'editar-endereco-dados',
    loadChildren: () => import('./pages/editar-endereco-dados/editar-endereco-dados.module').then(m => m.EditarEnderecoDadosPageModule)
  },
  {
    path: 'cupons',
    loadChildren: () => import('./pages/cupons/cupons.module').then(m => m.CuponsPageModule)
  },
  // Shinguru
  {
    path: 'shinguru/bem-vindo',
    loadChildren: () => import('./pages/fidelidade/onboarding/bem-vindo/bem-vindo.module').then(m => m.BemVindoPageModule),
    canActivate: [ShinguruGuard, MobileService],
  },
  {
    path: 'shinguru/convite',
    loadChildren: () => import('./pages/fidelidade/convite/convite.module').then(m => m.ConvitePageModule),
    canActivate: [ShinguruGuard],
  },
  {
    path: 'tabs/shinguru',
    loadChildren: () => import('./pages/fidelidade/shinguru/shinguru.module').then(m => m.ShinguruPageModule),
    canActivate: [ShinguruGuard],
  },
  {
    path: 'shinguru/seus-beneficios',
    loadChildren: () => import('./pages/fidelidade/seus-beneficios/seus-beneficios.module').then(m => m.SeusBeneficiosPageModule),
    canActivate: [ShinguruGuard],
  },
  {
    path: 'shinguru/extrato',
    loadChildren: () => import('./pages/fidelidade/extrato/extrato.module').then(m => m.ExtratoPageModule),
    canActivate: [ShinguruGuard, MobileService],
  },
  {
    path: 'shinguru/detalhes-cashback',
    loadChildren: () => import('./pages/fidelidade/detalhes-cashback/detalhes-cashback.module').then(m => m.DetalhesCashbackPageModule),
    canActivate: [ShinguruGuard],
  },
  {
    path: 'shinguru/duvidas-frequentes',
    loadChildren: () => import('./pages/fidelidade/duvidas-frequentes/duvidas-frequentes.module').then(m => m.DuvidasFrequentesPageModule),
    canActivate: [ShinguruGuard, MobileService],
  },
  {
    path: 'shinguru/fale-conosco',
    loadChildren: () => import('./pages/fidelidade/fale-conosco/fale-conosco.module').then(m => m.FaleConoscoPageModule),
    canActivate: [ShinguruGuard, MobileService],
  },
  {
    path: 'shinguru/termos-condicoes',
    loadChildren: () => import('./pages/fidelidade/termos-condicoes/termos-condicoes.module').then(m => m.TermosCondicoesPageModule),
    canActivate: [ShinguruGuard, MobileService],
  },
  //
  {
    path: '**',
    redirectTo: 'tabs/cardapio',
  },
  {
    path: 'esqueci-senha-um',
    loadChildren: () => import('./pages/onboarding-v2/esqueci-senha/esqueci-senha-um/esqueci-senha-um.module').then(m => m.EsqueciSenhaUmPageModule)
  },
  {
    path: 'esqueci-senha-dois',
    loadChildren: () => import('./pages/onboarding-v2/esqueci-senha/esqueci-senha-dois/esqueci-senha-dois.module').then(m => m.EsqueciSenhaDoisPageModule)
  },
  {
    path: 'esqueci-senha-tres',
    loadChildren: () => import('./pages/onboarding-v2/esqueci-senha/esqueci-senha-tres/esqueci-senha-tres.module').then(m => m.EsqueciSenhaTresPageModule)
  },
  {
    path: 'esqueci-senha-quatro',
    loadChildren: () => import('./pages/onboarding-v2/esqueci-senha/esqueci-senha-quatro/esqueci-senha-quatro.module').then(m => m.EsqueciSenhaQuatroPageModule)
  },
  {
    path: 'adicionar-cartao',
    loadChildren: () => import('./modals/usuario-v2/adicionar-cartao/adicionar-cartao.module').then((m) => m.AdicionarCartaoPageModule),
    canActivate: [LoginAuthenticatorService],
  },
  {
    path: 'sair',
    loadChildren: () => import('./modals/usuario-v2/sair/sair.module').then(m => m.SairPageModule),
  },
  {
    path: 'modal-perguntar-doacao',
    loadChildren: () => import('./modals/modais-instituto-crianca/modal-perguntar-doacao/modal-perguntar-doacao.module').then(m => m.ModalPerguntarDoacaoPageModule)
  },
  {
    path: 'modal-forma-pagamento-doacao',
    loadChildren: () => import('./modals/modais-instituto-crianca/modal-forma-pagamento-doacao/modal-forma-pagamento-doacao.module').then(m => m.ModalFormaPagamentoDoacaoPageModule)
  },
  {
    path: 'modal-pagamento-pix-doacao',
    loadChildren: () => import('./modals/modais-instituto-crianca/modal-pagamento-pix-doacao/modal-pagamento-pix-doacao.module').then(m => m.ModalPagamentoPixDoacaoPageModule)
  },
  {
    path: 'modal-doacao',
    loadChildren: () => import('./modals/modais-instituto-crianca/modal-doacao/modal-doacao.module').then(m => m.ModalDoacaoPageModule)
  },
  {
    path: 'adicionar-cpf',
    loadChildren: () => import('./modals/modais-finalizar-pedido/adicionar-cpf/adicionar-cpf.module').then(m => m.AdicionarCpfPageModule),
  },
  {
    path: 'modal-pagamento-pedido-pix',
    loadChildren: () => import('./modals/modais-pagamento-pedido-pix/modal-pagamento-pedido-pix/modal-pagamento-pedido-pix.module').then((m) => m.ModalPagamentoPedidoPixPageModule),
  },
  {
    path: 'modal-pagamento-pix-falha',
    loadChildren: () => import('./modals/modais-pagamento-pedido-pix/modal-pagamento-pix-falha/modal-pagamento-pix-falha.module').then((m) => m.ModalPagamentoPixFalhaPageModule),
  },
  {
    path: 'modal-endereco-numero',
    loadChildren: () => import('./modals/modal-endereco-numero/modal-endereco-numero.module').then(m => m.ModalEnderecoNumeroPageModule)
  },
  {
    path: 'modal-generica',
    loadChildren: () => import('./modals/modal-generica/modal-generica/modal-generica.module').then((m) => m.ModalGenericaPageModule),
  },
  {
    path: 'modal-generica-input',
    loadChildren: () => import('./modals/modal-generica/modal-generica-input/modal-generica-input.module').then((m) => m.ModalGenericaInputPageModule),
  },
  {
    path: 'modal-listagem-produtos',
    loadChildren: () => import('./modals/shinguru/modal-listagem-produtos/modal-listagem-produtos.module').then(m => m.ModalListagemProdutosPageModule)
  },
  {
    path: 'modal-produto',
    loadChildren: () => import('./modals/shinguru/modal-produto/modal-produto.module').then(m => m.ModalProdutoPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
