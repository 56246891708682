import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IDadosTotalCarrinho } from 'src/app/interfaces/ICarrinho.interface';

@Component({
  selector: 'app-resumo-valores',
  templateUrl: './resumo-valores.component.html',
  styleUrls: ['./resumo-valores.component.scss'],
})
export class ResumoValoresComponent implements OnInit {
  @Input() resumo_valores: IDadosTotalCarrinho;
  public data: IDadosTotalCarrinho;

  constructor(private modalController: ModalController) {}

  ngOnInit() {
    if (this.data !== undefined) {
      this.resumo_valores = this.data;
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }
}
