import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IEndereco } from 'src/app/interfaces/IEndereco.interface';
import { ITracking } from 'src/app/interfaces/IPedido.interface';
import { loader } from 'src/environments/environment';

@Component({
  selector: 'app-entrega-em',
  templateUrl: './entrega-em.component.html',
  styleUrls: ['./entrega-em.component.scss'],
})
export class EntregaEmComponent implements OnInit {
  @ViewChild('mapa') mapaElement: ElementRef;
  private mapa: google.maps.Map;
  @Input() endereco: IEndereco;
  @Input() pedido_mapa: boolean = false;
  @Input() tracking: ITracking;
  public texto: string;

  constructor() {}

  ngOnInit() {
    // if (this.pedido_mapa == false)
    this.carregarMapa();
  }

  private async carregarMapa() {
    const { Map } = await loader.importLibrary('maps');
    const coordenadas: { lat: number; lng: number } | google.maps.LatLng = {
      lat: this.endereco.latitude ?? this.endereco.lat,
      lng: this.endereco.longitude ?? this.endereco.lng,
    };

    this.mapa = new Map(this.mapaElement.nativeElement, {
      center: coordenadas,
      clickableIcons: false,
      disableDefaultUI: true,
      gestureHandling: 'none',
      zoom: 15,
    });

    const marker = new google.maps.Marker({
      icon: '/assets/assets_endereco/marker.svg',
      map: this.mapa,
      position: coordenadas,
    });

    let infoWindowBox: string;
    if (this.verificaStatus() || this.tracking?.status == undefined) {
      infoWindowBox =
        '<div id="google-popup"><span>Seu pedido será entregue aqui.</span></div>';
    } else {
      infoWindowBox =
        '<div id="google-popup"><span>Seu pedido foi entregue aqui.</span></div>';
    }

    const infoWindow = new google.maps.InfoWindow({
      content: infoWindowBox,
      position: coordenadas,
    });

    infoWindow.open({
      anchor: marker,
      map: this.mapa,
    });
  }

  public verificaStatus(): boolean {
    if (
      this.tracking?.status == 'Aguardando pagamento' ||
      this.tracking?.status == 'Pendente' ||
      this.tracking?.status == 'Confirmado' ||
      this.tracking?.status == 'Em preparo' ||
      this.tracking?.status == 'Saiu para entrega'
    ) {
      return true;
    } else {
      return false;
    }
  }
}
