import { Component, OnInit } from '@angular/core';
import { ValidatorFn, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BrMaskModel } from 'br-mask';
import { CarrinhoService } from 'src/app/services/observers/carrinho.service';
import { FormService } from 'src/app/services/form/form.service';
import { UtilsService } from 'src/app/services/utils.service';

export interface IAcao {
  titulo: string;
  paragrafos?: Array<string>;
  inputs: Array<IBoxInput>;
  strong: string;
  strong_cancelar: string;
  acao_secundaria: string;
}

interface IBoxInput {
  ion_label: string;
  ion_input: IInput;
}

interface IInput {
  placeholder: string;
  type: string;
  ngmodel: string;
  controlador?: string;
  validators?: ValidatorFn | ValidatorFn[];
  mask?: BrMaskModel;
}

@Component({
  selector: 'app-modal-generica-input',
  templateUrl: './modal-generica-input.page.html',
  styleUrls: ['./modal-generica-input.page.scss'],
})
export class ModalGenericaInputPage implements OnInit {
  public data: { data: IAcao };
  public form: FormGroup;
  private controladores: { [key: string]: string };

  constructor(
    private carrinhoService: CarrinhoService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.form = this.formBuilder.group({});

    if (this.data.data.inputs) {
      this.data.data.inputs.forEach((input) => {
        if (input.ion_input) {
          this.form.addControl(
            input.ion_input.ngmodel,
            this.formBuilder.control(
              this.form.value[input.ion_input.ngmodel],
              input.ion_input.validators
            )
          );

          this.controladores = {
            [input.ion_input.ngmodel]: input.ion_input.controlador,
          };
        }
      });
    }
  }

  public dismiss(acao: string) {
    if (acao === 'executar') {
      this.data.data.inputs.forEach((input) => {
        if (input.ion_input) {
          if (input.ion_input.ngmodel === 'cpf') {
            this.form
              .get('cpf')
              .setAsyncValidators(this.formService.validaCPF());
          }

          if (input.ion_input.ngmodel === 'valor') {
            if (this.validaValor(this.form.value.valor)) {
              this.modalController.dismiss(acao, this.form.value);
              return;
            }
          }

          if (input.ion_input.validators) {
            if (this.formService.validaForm(this.form, this.controladores)) {
              this.modalController.dismiss(acao, this.form.value);
            }
          }
        }
      });
    } else if (acao === this.data.data.acao_secundaria) {
      this.modalController.dismiss(acao, this.form.value);
    } else {
      this.modalController.dismiss(acao);
    }
  }

  private validaValor(troco: string): boolean {
    let troco_sem_mascara: number = this.utilsService.realToFloat(troco);
    let total: number;
    this.carrinhoService.valorFinalCarrinho$.subscribe((valorFinalCarrinho) => {
      total = valorFinalCarrinho.total as number;
    });

    if (troco_sem_mascara < total) {
      this.utilsService.exibirToast(
        'Valor de troco menor que o valor do pedido!',
        'erro'
      );

      return false;
    } else if (troco_sem_mascara > total + 200) {
      this.utilsService.exibirToast(
        'Valor de troco muito maior que o valor do pedido!',
        'erro'
      );

      return false;
    }

    return true;
  }
}
