import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { TabsService } from 'src/app/pages/tabs/tabs.service';
import { UtilsService } from './../../services/utils.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  @Output() selecionarTab: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private tabService: TabsService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {}

  public tab(tab: string): boolean {
    this.utilsService.navegarPagina('tabs/perfil');
    this.tabService.emitirTabSelecionada(tab);
    return false;
  }
}
