import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { IOpcionaisObrigatorios } from 'src/app/interfaces/ICarrinho.interface';
import { IObservacoes, IProduto } from 'src/app/interfaces/IProduto.interface';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-adicional',
  templateUrl: './adicional.component.html',
  styleUrls: ['./adicional.component.scss'],
})
export class AdicionalComponent implements OnInit {

  public qtde_adicional: number = 0;

  @Output() quantidade: EventEmitter<IOpcionaisObrigatorios> = new EventEmitter<IOpcionaisObrigatorios>();

  @Input() public adicional: IOpcionaisObrigatorios;

  @Input() public qtde_pessoas: number;

  @Input() public regra: number | String;

  constructor(private utilsService: UtilsService) {
  }

  ngOnInit() {
    this.qtde_adicional = Number(this.regra);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['qtde_pessoas']) {
      this.qtde_adicional = Number(this.regra);
    }

    if (changes['regra']) {
      this.qtde_adicional = changes['regra'].currentValue;
    }
  }

  public quantidadeAdicional(acao: string): void {
    switch (acao) {
      case 'adicionar':
        if (this.qtde_adicional + 1 > Number(this.regra)) {
          this.utilsService.exibirToast(
            'A quantidade máxima para essa opção já foi atingida.', 'erro'
          );
        } else {
          this.qtde_adicional = this.qtde_adicional += 1;

          this.adicional.qtd = this.qtde_adicional;

          this.quantidade.emit(this.adicional);
        }
        break;
      case 'remover':
        if (this.qtde_adicional !== 0) this.qtde_adicional = this.qtde_adicional -= 1;

        this.adicional.qtd = this.qtde_adicional;
        this.quantidade.emit(this.adicional);
      default:
        break;
    }
  }

}
