import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { BehaviorSubject } from 'rxjs';

import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  private celularSubject = new BehaviorSubject<boolean>(false);
  public celular$ = this.celularSubject.asObservable();

  constructor(private utilsService: UtilsService, private platform: Platform) {
    this.detectarCelular();
    window.addEventListener('resize', () => this.detectarCelular());
  }

  private detectarCelular(): void {
    this.celularSubject.next(window.innerWidth < 768);
  }

  public async canActivate(): Promise<boolean> {
    await this.platform.ready().then(() => {
      if (this.celularSubject.value === false) {
        this.utilsService.navegarPaginaRoot('/tabs/cardapio');
        return false;
      } else {
        return true;
      }
    });

    return true;
  }
}
