import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { GoogleMaps } from '@ionic-native/google-maps/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ComponentsModule } from '../../../app/src/app/components.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      mode: 'ios',
      hardwareBackButton: true,
      backButtonText: '',
      swipeBackEnabled: true,
    }),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    GoogleMaps,
    Keyboard,
    Diagnostic,
    OneSignal,
    InAppBrowser,
    FormBuilder,
    ComponentsModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
