import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UsuarioService } from './../../../services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

@Component({
  selector: 'app-configurar-notificacoes',
  templateUrl: './configurar-notificacoes.component.html',
  styleUrls: ['./configurar-notificacoes.component.scss'],
})
export class ConfigurarNotificacoesComponent implements OnInit {
  public usuario: IUsuario;

  public form: {
    email: boolean;
    push: boolean;
    sms: boolean;
  };

  public topo: boolean;

  constructor(
    private apiService: ApiService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.usuario = this.utilsService.getStorage('usuario');

    this.form = {
      email: this.usuario?.notificacoesMarketingEmail,
      push: this.usuario?.notificacoesMarketingPush,
      sms: this.usuario?.notificacoesMarketingSms,
    };
  }

  ngOnInit() {
    this.topo = this.utilsService.mostrarTopo(
      '/tabs/perfil',
      '/tabs/configurar-notificacoes'
    );
  }

  async alterarNotificacao(notificacao: boolean, tipo: string) {
    const req = {
      jwt: this.usuario.jwt,
      tipo: tipo,
      status: notificacao ? 0 : 1,
    };

    const res = await this.apiService.put('configurar_notificacoes', req);

    if (res.status === 'success') {
      this.usuario = res.data;
      this.usuarioService.atualizarUsuario(this.usuario);
      this.utilsService.exibirToast(res.message, 'sucesso');
    }
  }

  public navegar(caminho: string) {
    this.utilsService.navegarPagina(caminho);
  }
}
