import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { ISegmentButton } from 'src/app/components/segment/segment.component';
import {
  IBandeiras,
  IFormasPagamento,
  IFormasPgtoDisponiveis,
  IPagamentoSelecionado,
} from 'src/app/interfaces/IPagamento.interface';
import { PedidoService } from './pedido.service';

export const BANDEIRAS: Record<string, IBandeiras> = {
  ALELO: { imagem: 'assets/bandeiras/alelo.webp', bandeira: 'Alelo' },
  AMEX: { imagem: 'assets/bandeiras/amex.webp', bandeira: 'Amex' },
  DINERS: { imagem: 'assets/bandeiras/diners.webp', bandeira: 'Diners' },
  ELO: { imagem: 'assets/bandeiras/elo.webp', bandeira: 'Elo' },
  HIPER: { imagem: 'assets/bandeiras/hiper.webp', bandeira: 'Hiper' },
  HIPERCARD: {
    imagem: 'assets/bandeiras/hipercard.webp',
    bandeira: 'Hipercard',
  },
  MASTERCARD: {
    imagem: 'assets/bandeiras/mastercard.webp',
    bandeira: 'Mastercard',
  },
  SODEXO: { imagem: 'assets/bandeiras/sodexo.webp', bandeira: 'Sodexo' },
  TICKET_RESTAURANTE: {
    imagem: 'assets/bandeiras/ticket.webp',
    bandeira: 'Ticket Restaurante',
  },
  VISA: { imagem: 'assets/bandeiras/visa.webp', bandeira: 'Visa' },
  VISA_REFEICAO: {
    imagem: 'assets/bandeiras/vr.webp',
    bandeira: 'Visa Refeição',
  },
  VR: { imagem: 'assets/bandeiras/vr.webp', bandeira: 'VR' },
};

@Injectable({
  providedIn: 'root',
})
export class FormasPagamentoService {
  private bandeiras = BANDEIRAS;
  private pagamentoSelecionado: IPagamentoSelecionado;

  public segmentSubject = new BehaviorSubject<ISegmentButton>(null);
  public segment$: Observable<any> = this.segmentSubject.asObservable();

  private pgtosDisponiveisSubject = new BehaviorSubject<IFormasPgtoDisponiveis>(
    null
  );
  public pgtosDisponiveis$: Observable<IFormasPgtoDisponiveis> =
    this.pgtosDisponiveisSubject.asObservable();

  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService,
    private pedidoService: PedidoService
  ) {
    this.pedidoService.pagamentoSelecionado$.subscribe(
      (pagamentoSelecionado) => {
        this.pagamentoSelecionado = pagamentoSelecionado;
      }
    );
  }

  public async buscarPagamentos(loja: ILoja): Promise<Array<IFormasPagamento>> {
    await this.utilsService.exibirLoading();

    const req = {
      lojaId: loja.id,
    };

    const res = await this.apiService.get('pagamentos', req);

    await this.utilsService.ocultarLoading();

    if (res.responseCode !== 404) {
      const pagamentos_disponiveis: IFormasPgtoDisponiveis = res.data;

      this.setFormasPagamento(pagamentos_disponiveis);
      this.pgtosDisponiveisSubject.next(pagamentos_disponiveis);

      let formas = [];

      if (pagamentos_disponiveis?.entrega?.cartao) {
        let pagamentos_entrega = pagamentos_disponiveis.entrega.cartao;

        Object.keys(pagamentos_entrega).forEach((key) => {
          const bandeiras_aceitas = {};

          pagamentos_entrega[key].forEach(
            (bandeira: { bandeira: string; nome: string }) => {
              bandeiras_aceitas[bandeira.bandeira.toUpperCase()] =
                this.bandeiras[bandeira.bandeira];
            }
          );

          formas.push({
            forma: key,
            label:
              key === 'DEBIT'
                ? 'Débito'
                : key === 'CREDIT'
                ? 'Crédito'
                : key === 'MEAL_VOUCHER'
                ? 'Vale refeição'
                : '',
            bandeiras: bandeiras_aceitas,
          });
        });
      }

      return formas;
    } else {
      return null;
    }
  }

  private async setFormasPagamento(
    formas: IFormasPgtoDisponiveis
  ): Promise<ISegmentButton> {
    const formasPgtoOnline: string[] = Object.keys(formas.online);
    const formasPgtoEntrega: string[] = Object.keys(formas.entrega);

    let valor_padrao: string =
      this.pagamentoSelecionado !== null
        ? this.pagamentoSelecionado.tipo_forma_pgto
        : 'online';

    let segment: ISegmentButton = {
      valor_padrao: valor_padrao,
      opcoes: [],
    };

    if (formasPgtoOnline.length > 0) {
      formasPgtoOnline.forEach((pagamento) => {
        if (pagamento === 'pix') {
          segment.opcoes.push({ opcao: 'PAGUE VIA PIX', valor: 'pix' });
        }

        if (pagamento === 'cartao' || pagamento === 'pix') {
          const opcao_exists = segment.opcoes.find(
            (opcao) =>
              opcao.opcao === 'PAGUE PELO APP' && opcao.valor === 'online'
          );

          if (!opcao_exists) {
            segment.opcoes.push({
              opcao: 'PAGUE PELO APP',
              valor: 'online',
            });
          }
        }
      });
    }

    if (formasPgtoEntrega.length > 0) {
      segment.opcoes.push({
        opcao: 'PAGUE NA ENTREGA',
        valor: 'entrega',
      });

      if (formasPgtoOnline.length == 0) {
        segment.valor_padrao = 'entrega';
      }
    }

    this.segmentSubject.next(segment);
    return segment;
  }
}
