import { Component, OnInit } from '@angular/core';

import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-filtro-fidelidade',
  templateUrl: './filtro-fidelidade.component.html',
  styleUrls: ['./filtro-fidelidade.component.scss'],
})
export class FiltroFidelidadeComponent implements OnInit {
  public meses!: number;

  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {}

  public selecionarMes(): void {
    this.modalsService.fecharModal({ mes: this.meses });
  }

  public dismiss(): void {
    this.modalsService.fecharModal({ mes: 3 });
  }
}
