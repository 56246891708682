import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ISegmentButton {
  valor_padrao: string;
  opcoes: Opcoes[];
}

interface Opcoes {
  valor: string;
  opcao: string;
}

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
})
export class SegmentComponent implements OnInit {
  @Input() segment_buttons: ISegmentButton;
  @Input() classe: string;
  @Output() alterarOpcao: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  public switch(event: any): void {
    this.alterarOpcao.emit(event);
  }
}
