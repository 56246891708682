import { Injectable } from '@angular/core';
import {
  ICoordenadas,
  IEndereco,
} from 'src/app/interfaces/IEndereco.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from '../api.service';
import { EnderecoService } from '../endereco.service';
import { UtilsService } from '../utils.service';
import { IRestaurante } from 'src/app/interfaces/IRestaurante.interface';
import { REDEID } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestauranteService {
  private restaurantesSubject = new BehaviorSubject<Array<IRestaurante>>(null);
  restaurantes$: Observable<Array<IRestaurante>> =
    this.restaurantesSubject.asObservable();

  public restauranteSelecionadoSubject = new BehaviorSubject<IRestaurante>(
    null
  );
  restauranteSelecionado$: Observable<IRestaurante> =
    this.restauranteSelecionadoSubject.asObservable();

  public endereco_atual: IEndereco;
  public coordenadas: ICoordenadas;

  constructor(
    private apiService: ApiService,
    private enderecoService: EnderecoService,
    private utilsService: UtilsService
  ) {
    this.enderecoService.enderecoList$.subscribe((enderecos) => {
      this.endereco_atual = enderecos[0];
    });
  }

  ionViewWillEnter() {
    if (this.endereco_atual == undefined || this.endereco_atual == null) {
      this.utilsService.getLocationService().then(async (resp) => {
        this.coordenadas = resp;
      });
    }
  }

  async buscarRestaurantes() {
    let params: { lat?: number; lng?: number; marcaId: number } = {
      marcaId: REDEID,
    };

    if (this.endereco_atual !== undefined && this.endereco_atual !== null) {
      params['lat'] =
        this.endereco_atual?.latitude !== undefined
          ? this.endereco_atual.latitude
          : this.coordenadas?.lat;
      params['lng'] =
        this.endereco_atual?.longitude !== undefined
          ? this.endereco_atual.longitude
          : this.coordenadas?.lng;
    }

    const res = await this.apiService.get('restaurantes', params);

    if (res.status === 'success') {
      const restaurantes: Array<IRestaurante> = res.data;

      const restaurantes_notester = restaurantes.filter(
        (restaurante: IRestaurante) => {
          return !restaurante.nome.toLowerCase().includes('teste');
        }
      );

      this.restaurantesSubject.next(
        restaurantes_notester.map((item: IRestaurante) => ({
          tipoCompra: 'DLV',
          ...item,
        }))
      );

      return this.restaurantesSubject.value;
    } else {
      this.restaurantesSubject.next(null);

      return this.restaurantesSubject.value;
    }
  }
}
