import { Component, OnInit } from '@angular/core';

import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-politica-privacidade',
  templateUrl: './politica-privacidade.component.html',
  styleUrls: ['./politica-privacidade.component.scss'],
})
export class PoliticaPrivacidadeComponent implements OnInit {
  public topo: boolean;
  public data: { modal: boolean };

  constructor(
    private modalService: ModalsService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.topo = this.utilsService.mostrarTopo(
      '/tabs/perfil',
      '/tabs/politicas-privacidade'
    );
  }

  public navegar(caminho: string): void {
    this.utilsService.navegarPagina(caminho);
  }

  public dismiss(): void {
    this.modalService.fecharModal();
  }
}
