import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import {
  IEndereco,
  ILocalizacao,
} from 'src/app/interfaces/IEndereco.interface';
import {
  IAcao,
  ModalGenericaPage,
} from 'src/app/modals/modal-generica/modal-generica/modal-generica.page';
import { ApiService } from 'src/app/services/api.service';
import { EnderecoService } from 'src/app/services/endereco.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

@Component({
  selector: 'app-meus-enderecos',
  templateUrl: './meus-enderecos.component.html',
  styleUrls: ['./meus-enderecos.component.scss'],
})
export class MeusEnderecosComponent implements OnInit {
  public enderecos: Array<IEndereco>;
  public usuario: IUsuario;
  public localizacao_atual: ILocalizacao | null = null;
  public carregando: boolean = true;
  public topo: boolean;
  public icon: boolean = false;

  constructor(
    private apiService: ApiService,
    private enderecoService: EnderecoService,
    private location: Location,
    private modalController: ModalController,
    private modalsService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });

    this.enderecoService.enderecoList$.subscribe((enderecos) => {
      this.enderecos = enderecos;
    });
  }

  async ngOnInit() {
    this.localizacaoAtual();

    this.topo = this.utilsService.mostrarTopo(
      '/tabs/perfil',
      '/tabs/meus-enderecos'
    );

    if (this.location.path(false) === '/tabs/cardapio') {
      this.icon = true;
    }
  }

  public async selecionarEndereco(endereco: IEndereco) {
    this.enderecoService.verificarCarrinho(endereco, true);

    this.navegar('/tabs/cardapio');
  }

  public excluirEndereco(endereco: IEndereco) {
    const data: IAcao = {
      titulo: 'Excluir endereço',
      paragrafos: ['Tem certeza que deseja este endereço?'],
      label_acao: 'Excluir endereço',
      acao: 'excluir-endereco',
    };

    this.modalsService.abrirModal(
      'modal-generica',
      'modal-generica',
      ModalGenericaPage,
      {
        data: data,
      },
      (params) => {
        if (params.data === 'executar') {
          this.enderecoService.removerEndereco(endereco);
        }
      }
    );
  }

  public alterarEndereco(endereco: IEndereco) {
    this.utilsService.setStorage('editar-endereco', endereco);
    this.navegar('cadastro-endereco');
  }

  private localizacaoAtual() {
    this.utilsService.getLocationService().then(async (resp) => {
      let coordenadas: { lat: number; lng: number } = resp;

      const req = {
        lat: coordenadas.lat,
        lng: coordenadas.lng,
      };

      const res = await this.apiService.get('geocode', req);

      this.carregando = false;

      if (res.status === 'success') {
        this.localizacao_atual = res.data;
        this.utilsService.setStorage(
          'localizacao-atual',
          this.localizacao_atual
        );
      } else {
        this.utilsService.exibirToast(
          'Ops.. Tivemos um problema em pegar sua localização, por favor tente novamente.', 'erro'
        );
      }
    });
  }

  public dismiss() {
    this.modalController.dismiss();
  }

  public navegar(caminho: string) {
    if (this.location.path(false) === '/tabs/cardapio') {
      this.dismiss();
    }

    this.utilsService.navegarPagina(caminho);

    if (caminho === 'mapa-confirmacao') {
      this.utilsService.setStorage('localizacao-atual', this.localizacao_atual);
    }
  }
}
