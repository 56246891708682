import { Component, Input, OnInit } from '@angular/core';

import { BeneficioResgatavel } from 'src/app/interfaces/Shinguru.interface';

import { ModalsService } from './../../../services/modals/modals.service';

import { BeneficioResgatavelComponent } from 'src/app/modals/fidelidade/beneficio-resgatavel/beneficio-resgatavel.component';

@Component({
  selector: 'app-card-beneficio',
  templateUrl: './card-beneficio.component.html',
  styleUrls: ['./card-beneficio.component.scss'],
})
export class CardBeneficioComponent implements OnInit {
  @Input() beneficio: BeneficioResgatavel;

  constructor(private modalsService: ModalsService) {}

  ngOnInit(): void {}

  public verBeneficio(beneficio: BeneficioResgatavel) {
    this.modalsService.abrirModal(
      'modal-beneficio-resgatavel',
      `modal-beneficio-resgatavel ${beneficio.classe}`,
      BeneficioResgatavelComponent,
      beneficio
    );
  }
}
