import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  IAdicionais,
  ICarrinho,
  IOpcionaisObrigatorios,
} from 'src/app/interfaces/ICarrinho.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';

import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { LojaService } from 'src/app/services/observers/loja.service';
import { CarrinhoService } from 'src/app/services/observers/carrinho.service';
import { ShinguruService } from 'src/app/services/shinguru/shinguru.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CELULAR } from 'src/app/app.component';

@Component({
  selector: 'app-itens-pedido',
  templateUrl: './itens-pedido.component.html',
  styleUrls: ['./itens-pedido.component.scss'],
})
export class ItensPedidoComponent implements OnInit {
  @Input() carrinho: Array<ICarrinho>;
  @Input() remover: boolean = false;
  @Input() origem: string = '';

  public loja$: Observable<ILoja>;

  constructor(
    private analyticsService: AnalyticsService,
    private carrinhoService: CarrinhoService,
    private lojaService: LojaService,
    private modalController: ModalController,
    private utilsService: UtilsService,
    private shinguruService: ShinguruService,
  ) {
    this.loja$ = this.lojaService.loja$;
  }

  ngOnInit() {
  }

  public removerItem(item: ICarrinho) {
    let carrinho: Array<ICarrinho> = this.carrinho;

    carrinho.splice(
      carrinho.findIndex((item_carrinho) => item_carrinho.cod === item.cod),
      1
    );

    const beneficioShinguru = this.utilsService.getStorage('beneficio-shinguru');

    if(beneficioShinguru != null && beneficioShinguru?.tipo == 'produto'){
      const existeBeneficioNoCarrinho = carrinho.some(item => item.beneficio === true);
      if(!existeBeneficioNoCarrinho) this.shinguruService.cancelarReserva(beneficioShinguru);
    }

    this.carrinhoService.atualizarCarrinho(carrinho);

    this.utilsService.exibirToast(
      `Você removeu o item: ${item.nome} do seu carrinho.`,
      'sucesso',
      2000
    );

    if (this.carrinho.length === 0) {
      this.carrinhoService.limparCarrinho();
    }

    this.loja$
      .pipe(
        switchMap((loja) => {
          return of(
            this.analyticsService.item(
              'remove_from_cart',
              item,
              loja,
              this.utilsService.getStorage('canal-compra') == 'whatsapp'
                ? 'CHATBOT'
                : 'PWA'
            )
          );
        })
      )
      .subscribe((result) => {
        this.analyticsService.registrarEvento(result);
      });
  }

  public filtroAdicionais(adicionais: Array<IAdicionais>): Array<IAdicionais> {
    return adicionais.filter((item) => item.qtd > 0);
  }

  public filtroOpcionaisObrigatorios(
    opcionais: Array<IOpcionaisObrigatorios>
  ): Array<IOpcionaisObrigatorios> {
    return opcionais.filter((item) => item.selecionado);
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public navegar(caminho: string): void {
    if (!CELULAR) {
      this.dismiss();
    }

    this.utilsService.navegarPagina(caminho);
  }
}
