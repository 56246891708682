import { Component, Input, OnInit } from '@angular/core';

import { IPedido } from 'src/app/interfaces/IPedido.interface';

import { MobileService } from 'src/app/services/mobile/mobile.service';
import { PedidoService } from 'src/app/services/observers/pedido.service';
import { TabsService } from 'src/app/pages/tabs/tabs.service';
import { UtilsService } from 'src/app/services/utils.service';

interface IEstilos {
  background: string;
  border_color: string;
  string_button: string;
}

@Component({
  selector: 'app-card-pedido-historico',
  templateUrl: './card-pedido-historico.component.html',
  styleUrls: ['./card-pedido-historico.component.scss'],
})
export class CardPedidoHistoricoComponent implements OnInit {
  @Input() pedido: IPedido;
  private celular: boolean;

  public estilo_card: IEstilos;

  constructor(
    private mobileService: MobileService,
    private pedidoService: PedidoService,
    private tabsService: TabsService,
    private utilsService: UtilsService
  ) {
    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;
    });
  }

  ngOnInit() {
    this.estilo_card = this.definirEstiloCard(this.pedido.status);
  }

  definirEstiloCard(status: string): IEstilos {
    let estilos: IEstilos;
    let status_pedido_andamento: Array<string> = [
      'Pendente',
      'Em preparo',
      'Confirmado',
      'Saiu para entrega',
      'Pronto para retirar',
    ];
    let status_pedido_finalizado: Array<string> = ['Concluído', 'Cancelado'];

    if (status_pedido_andamento.includes(status)) {
      estilos = {
        background: '#ffffff',
        border_color: '#1c5e4e',
        string_button: 'Falar com o restaurante',
      };
    } else if (status_pedido_finalizado.includes(status)) {
      estilos = {
        background: '#F9F3E9',
        border_color: '#F9F3E9',
        string_button: 'Repetir pedido',
      };
    }

    return estilos;
  }

  public realizarAcao(acao: string): void {
    if (acao === 'Falar com o restaurante') {
      this.utilsService.whatsApp(this.pedido.telefone1);
    }
  }

  public navegar(caminho: string, pedido: IPedido): void {
    if (pedido) this.utilsService.setStorage('pedido-id', pedido.id);

    this.tabsService.detalhesPedido('tabs/detalhes-pedido', false);

    if (this.celular) this.utilsService.navegarPagina(caminho);
    else this.pedidoService.buscarPedido();
  }
}
