import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilsService } from '../utils.service';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

@Injectable({
  providedIn: 'root',
})
export class UsuarioService {
  private usuarioSubject = new BehaviorSubject<IUsuario>(null);
  usuario$: Observable<IUsuario> = this.usuarioSubject.asObservable();

  constructor(private utils: UtilsService) {
    const usuario = this.utils.getStorage('usuario');

    if (usuario) this.usuarioSubject.next(usuario);
  }

  public atualizarUsuario(usuario: IUsuario): IUsuario {
    this.usuarioSubject.next(usuario);

    if (usuario !== null) this.utils.setStorage('usuario', usuario);

    return usuario;
  }
}
