import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { ApiService } from '../api.service';
import { CardapioService } from './cardapio.service';
import { UtilsService } from '../utils.service';
import { IEndereco } from 'src/app/interfaces/IEndereco.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IProduto } from 'src/app/interfaces/IProduto.interface';
import { REDEID } from 'src/environments/environment';
import { IBanner } from 'src/app/interfaces/IBanner.interface';

interface IReq {
  endereco?: string;
  numero?: string;
  bairro?: string;
  cidade?: string;
  estado?: string;
  lat?: number | string;
  lng?: number | string;
  marcaId: number;
  modalidadeId: string;
}

@Injectable({
  providedIn: 'root',
})
export class LojaService {
  public lojaSubject = new BehaviorSubject<ILoja>(null);
  loja$: Observable<ILoja> = this.lojaSubject.asObservable();

  public bannersSubject = new BehaviorSubject<Array<IBanner>>(null);
  banners$: Observable<Array<IBanner>> = this.bannersSubject.asObservable();

  private banners_padrao: Array<IBanner> = [
    {
      id: '',
      produto_id: '',
      nome: '',
      imagem: '/assets/background/loja-guru.webp',
      tipo: '',
      categoria_id: '',
      link: '',
    },
  ];

  private sugestivosSubject = new BehaviorSubject<Array<IProduto>>(null);
  sugestivos$: Observable<Array<IProduto>> =
    this.sugestivosSubject.asObservable();

  private kitsSubject = new BehaviorSubject<Array<IProduto>>(null);
  kits$: Observable<Array<IProduto>> =
    this.kitsSubject.asObservable();

  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private cardapioService: CardapioService,
    private utils: UtilsService
  ) {
    const loja = this.utils.getStorage('loja');

    if (loja) this.lojaSubject.next(loja);
  }

  public atualizarLoja(loja: ILoja) {
    this.lojaSubject.next(loja);

    if (loja !== null && loja.id !== 0) {
      this.utils.setStorage('loja', loja);
      this.sugestivos(loja);
    }

    this.buscarBanners(loja);
  }

  public async buscarLoja(endereco: IEndereco) {
    let req: IReq = {
      endereco: endereco.logradouro,
      numero: endereco.numero,
      bairro: endereco.bairro,
      cidade: endereco.cidade,
      estado: endereco.estado,
      lat: endereco.lat ? endereco.lat : endereco.latitude,
      lng: endereco.lng ? endereco.lng : endereco.longitude,
      modalidadeId: 'DLV',
      marcaId: REDEID,
    };

    const res = await this.apiService.get('lojas', req);

    let loja: ILoja | Array<any>;

    if (res.data && res.data.length > 0) {
      if (Array.isArray(res.data[0].loja)) {
        loja = [];
      } else {
        loja = res.data[0].loja as ILoja;
      }
    }

    if (
      res.status === 'success' &&
      loja !== null &&
      loja !== undefined &&
      ((Array.isArray(loja) && loja.length !== 0) ||
        (!Array.isArray(loja) && Object.keys(loja).length !== 0))
    ) {
      if ((loja as ILoja).id == 0) {
        this.atualizarLoja(null);
        this.cardapioService.atualizarCardapio(null);
        return;
      }

      this.atualizarLoja(loja as ILoja);
      this.cardapioService.buscarCardapio(loja as ILoja);
    } else {
      this.atualizarLoja(null);
      this.cardapioService.atualizarCardapio(null);

      const event = this.analyticsService.addressNotAnswered(endereco.cep);
      this.analyticsService.registrarEvento(event);
    }
  }


  public async buscarKits(endereco: IEndereco) {
    let req: IReq = {
      endereco: endereco.logradouro,
      numero: endereco.numero,
      bairro: endereco.bairro,
      cidade: endereco.cidade,
      estado: endereco.estado,
      lat: endereco.lat ? endereco.lat : endereco.latitude,
      lng: endereco.lng ? endereco.lng : endereco.longitude,
      modalidadeId: 'DLV',
      marcaId: REDEID,
    };

    const res = await this.apiService.get('buscar_kit_delivery', req);

    if (res.status === 'success') {
      return res.data;
    }
  }

  public async sugestivos(loja: ILoja) {
    const req = {
      lojaId: loja.id,
      marcaId: REDEID,
      modalidadeId: 'DLV',
    };

    const res = await this.apiService.get('vendas_sugestivas', req);

    if (res.status === 'success') {
      this.sugestivosSubject.next(res.data);
    }
  }

  public async buscarBanners(loja: ILoja): Promise<IBanner[]> | null {
    const req: any = {
      marcaId: REDEID,
    };

    if (loja != null) {
      req.lojaId = loja.id;
    }

    const res = await this.apiService.get('banners', req);

    let banners: IBanner[] = [];
    let disparar_evento: boolean = false;

    if (res.status === 'success') {
      if (res.data.length > 0) {
        const resp = res.data;

        resp.map((banner: IBanner) => {
          if (banner.plataforma == 'Todas' || banner.plataforma == 'PWA') {
            banners.push(banner);
            disparar_evento = true;
          }
        });

        if (banners.length <= 0) {
          banners = this.banners_padrao;
        }

        this.bannersSubject.next(banners);

        if (disparar_evento) {
          const event = this.analyticsService.promotion(
            'view_promotion',
            null,
            banners
          );

          this.analyticsService.registrarEvento(event);
        }

        return banners;
      } else {
        banners = this.banners_padrao;
        this.bannersSubject.next(banners);

        return null;
      }
    } else {
      banners = this.banners_padrao;
      this.bannersSubject.next(banners);

      return null;
    }
  }
}
