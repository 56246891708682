import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Platform } from '@ionic/angular';

import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

import { UsuarioService } from './usuario.service';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root',
})
export class LoginAuthenticatorService implements CanActivate {
  private usuario: IUsuario;

  constructor(
    private usuarioService: UsuarioService,
    private platform: Platform,
    private utilsService: UtilsService
  ) {}

  public async canActivate(): Promise<boolean> {
    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });

    await this.platform.ready().then(() => {
      if (this.usuario == null) {
        this.utilsService.exibirToast('Faça login para continuar!!!', 'erro');
        this.utilsService.navegarPaginaRoot('login');
        return false;
      } else {
        return true;
      }
    });

    return true;
  }
}
