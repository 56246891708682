import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { LojaService } from './../../../services/observers/loja.service';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IProduto } from 'src/app/interfaces/IProduto.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

@Component({
  selector: 'app-card-produto',
  templateUrl: './card-produto.component.html',
  styleUrls: ['./card-produto.component.scss'],
})
export class CardProdutoComponent implements OnInit {
  @Output() adicionarSacola: EventEmitter<void> = new EventEmitter<void>();
  @Output() navegar: EventEmitter<void> = new EventEmitter<void>();
  @Input() produto: IProduto;
  public loja: ILoja;
  public usuario: IUsuario;

  constructor(
    private usuarioService: UsuarioService,
    private lojaService: LojaService
  ) {
    this.lojaService.loja$.subscribe((loja) => {
      this.loja = loja;
    });

    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });
  }

  ngOnInit() {}

  public irPara(): void {
    this.navegar.emit();
  }

  public sacola(): void {
    this.adicionarSacola.emit();
  }
}
