import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

import { UtilsService } from 'src/app/services/utils.service';

interface DetalhesDoacao {
  detalhes: string;
  doacao: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class TabsService {
  private usuario: IUsuario = this.utilsService.getStorage('usuario');

  private selecionarTabSubject = new BehaviorSubject<string>(
    this.usuario ? 'minha-conta' : 'termos'
  );
  public selecionarTab$ = this.selecionarTabSubject.asObservable();

  private detalhesPedidoSubject = new BehaviorSubject<DetalhesDoacao>({
    detalhes: '',
    doacao: false,
  });
  public detalhesPedido$: Observable<DetalhesDoacao> =
    this.detalhesPedidoSubject.asObservable();

  constructor(private utilsService: UtilsService) {}

  public emitirTabSelecionada(tab: string): void {
    this.selecionarTabSubject.next(tab);
  }

  public detalhesPedido(detalhes: string, doacao: boolean = false): void {
    this.detalhesPedidoSubject.next({ detalhes: detalhes, doacao: doacao });
  }
}
