import { Beneficio } from '../interfaces/Shinguru.interface';

export const areia: Beneficio[] = [
  {
    descricao:
      'Delicie-se com uma sobremesa de sua escolha no dia do seu aniversário.',
    nome: 'Presente de Aniversário',
  },
  {
    descricao: 'Você tem um cafézinho quentinho te esperando uma vez ao mês.',
    nome: 'Café Expresso',
  },
];

export const onda: Beneficio[] = [
  {
    descricao:
      'Delicie-se com uma sobremesa de sua escolha no dia do seu aniversário.',
    nome: 'Presente de Aniversário',
  },
  {
    descricao: 'Você tem um cafézinho quentinho te esperando uma vez ao mês.',
    nome: 'Café Expresso',
  },
  {
    descricao:
      'Aproveite um cupom de 10% OFF para fazer seu pedido no delivery.',
    nome: 'Cupom no Delivery',
  },
  {
    descricao:
      'Resgate nossa deliciosa Mini Pipoca de Camarão para começar bem sua refeição.',
    nome: 'Entrada especial',
  },
];

export const coral: Beneficio[] = [
  {
    descricao:
      'Delicie-se com uma sobremesa de sua escolha no dia do seu aniversário.',
    nome: 'Presente de Aniversário',
  },
  {
    descricao: 'Você tem um cafézinho quentinho te esperando uma vez ao mês.',
    nome: 'Café Expresso',
  },
  {
    descricao:
      'Aproveite um cupom de 10% OFF para fazer seu pedido no delivery.',
    nome: 'Cupom no Delivery',
  },
  {
    descricao:
      'Resgate nossa deliciosa Mini Pipoca de Camarão para começar bem sua refeição.',
    nome: 'Entrada especial',
  },
  {
    descricao:
      'Todos o amam o happy do Gurumê, aproveite ele por mais 30 minutos!',
    nome: 'Happy Hour estendido',
  },
  {
    descricao:
      'Receba essa experiência única que só o nosso Kit Moscow Mule pode oferecer.',
    nome: 'Kit Moscow Mule',
  },
  {
    descricao: 'Experimente o nosso saboroso sake para brindar. Kampai!',
    nome: 'Degustação de sake ',
  },
];

export const perola: Beneficio[] = [
  {
    descricao:
      'Delicie-se com uma sobremesa de sua escolha no dia do seu aniversário.',
    nome: 'Presente de Aniversário',
  },
  {
    descricao: 'Você tem um cafézinho quentinho te esperando uma vez ao mês.',
    nome: 'Café Expresso',
  },
  {
    descricao:
      'Aproveite um cupom de 10% OFF para fazer seu pedido no delivery.',
    nome: 'Cupom no Delivery',
  },
  {
    descricao:
      'Resgate nossa deliciosa Mini Pipoca de Camarão para começar bem sua refeição.',
    nome: 'Entrada especial',
  },
  {
    descricao:
      'Todos o amam o happy do Gurumê, aproveite ele por mais 30 minutos!',
    nome: 'Happy Hour estendido',
  },
  {
    descricao:
      'Receba essa experiência única que só o nosso Kit Moscow Mule pode oferecer.',
    nome: 'Kit Moscow Mule',
  },
  {
    descricao: 'Experimente o nosso saboroso sake para brindar. Kampai!',
    nome: 'Degustação de sake',
  },
  {
    descricao: 'Aproveite a experiência do menu degustação por nossa conta.',
    nome: 'Menu degustação',
  },
  {
    descricao: 'Deguste nosso refrescante drink do dia e se surpreenda.',
    nome: 'Drink de espera',
  },
  {
    descricao:
      'Produtos selecionados para você pedir enquando aguarda na fila.',
    nome: 'Entrada de espera',
  },
  {
    descricao:
      'Mais um benefício por nossa conta pra você desfrutar de uma super experiência.',
    nome: 'Isenção de valet ou estacionamento',
  },
];
