import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Platform } from '@ionic/angular';

import { UsuarioService } from './usuario.service';
import { UtilsService } from '../utils.service';

import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatorService implements CanActivate {
  private usuario: IUsuario;

  constructor(
    private usuarioService: UsuarioService,
    private utils: UtilsService,
    private platform: Platform
  ) {}

  public async canActivate(): Promise<boolean> {
    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });

    await this.platform.ready().then(() => {
      if (this.usuario !== null) {
        this.utils.navegarPaginaRoot('tabs/cardapio');
        return false;
      } else {
        return true;
      }
    });

    return true;
  }
}
