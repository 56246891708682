import { Component, Input, OnInit } from '@angular/core';
import { MobileService } from './../../../services/mobile/mobile.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { RestauranteService } from 'src/app/services/observers/restaurante.service';
import { UtilsService } from 'src/app/services/utils.service';
import { IRestaurante } from 'src/app/interfaces/IRestaurante.interface';
import { DetalhesRestaurantePage } from 'src/app/pages/restaurante/detalhes-restaurante/detalhes-restaurante.page';

@Component({
  selector: 'app-card-restaurante',
  templateUrl: './card-restaurante.component.html',
  styleUrls: ['./card-restaurante.component.scss'],
})
export class CardRestauranteComponent implements OnInit {
  @Input() restaurante: IRestaurante;
  public celular: boolean;

  constructor(
    private mobileService: MobileService,
    private modalsService: ModalsService,
    private restauranteService: RestauranteService,
    private utilsService: UtilsService
  ) {
    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;
    });
  }

  ngOnInit() {}

  navegar(caminho: string, restaurante: IRestaurante = null): void {
    if (this.celular) this.utilsService.navegarPagina(caminho);
    else
      this.modalsService.abrirModal(
        'modal-restaurante',
        'modal-restaurante',
        DetalhesRestaurantePage
      );

    if (caminho === 'tabs/detalhes-restaurante' && restaurante !== null)
      this.restauranteService.restauranteSelecionadoSubject.next(restaurante);
  }
}
