import { Component, OnInit } from '@angular/core';

import { ModalsService } from 'src/app/services/modals/modals.service';

@Component({
  selector: 'app-modal-doacao',
  templateUrl: './modal-doacao.page.html',
  styleUrls: ['./modal-doacao.page.scss'],
})
export class ModalDoacaoPage implements OnInit {
  constructor(private modalsService: ModalsService) {
    setTimeout(() => {
      this.modalsService.fecharModal();
    }, 6000);
  }

  ngOnInit(): void {}
}
