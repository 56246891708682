import { AnalyticsService } from './../../services/analytics/analytics.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IBanner } from 'src/app/interfaces/IBanner.interface';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent implements OnInit {
  @Input() banners: Array<IBanner>;
  @Input() classe: string = '';

  @Output() clickBanner = new EventEmitter<number>();

  public bannersOpt = {
    autoplay: true,
    initialSlide: 0,
  };

  constructor(
    private analyticsService: AnalyticsService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {}

  public classBanner(banner: IBanner): string {
    if (banner.tipo == 'Categoria' || banner.tipo == 'Link') {
      return 'pointer';
    }

    return '';
  }

  public handleClickBanner(banner: IBanner): void {
    if (banner && (banner.link || banner.categoria_id)) {
      const evento = this.analyticsService.promotion(
        'select_promotion',
        banner
      );
      this.analyticsService.registrarEvento(evento);

      if (banner.tipo == 'Categoria' && banner.categoria_id) {
        this.clickBanner.emit(parseInt(banner.categoria_id));
      }

      if (banner.tipo == 'Link' && banner.link) {
        this.utilsService.openLink(banner.link, true);
      }
    }
  }
}
