import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-notificacoes',
  templateUrl: './notificacoes.component.html',
  styleUrls: ['./notificacoes.component.scss'],
})
export class NotificacoesComponent implements OnInit {
  public notificacoes: Array<any> = [];
  public topo: boolean;

  constructor(
    private apiService: ApiService,
    private utilsService: UtilsService
  ) {}

  ngOnInit() {
    this.topo = this.utilsService.mostrarTopo(
      '/tabs/perfil',
      '/tabs/notificacoes'
    );

    this.buscarNotificacoes();
  }

  async buscarNotificacoes() {
    const res = await this.apiService.get('notificacoes');

    if (res.status === 'success') this.notificacoes = res.data;
    else this.notificacoes = null;
  }

  navegar(caminho: string) {
    this.utilsService.navegarPagina(caminho);
  }
}
