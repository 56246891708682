import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { FormService } from './../../../services/form/form.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MobileService } from './../../../services/mobile/mobile.service';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

@Component({
  selector: 'app-adicionar-cartao',
  templateUrl: './adicionar-cartao.page.html',
  styleUrls: ['./adicionar-cartao.page.scss'],
})
export class AdicionarCartaoPage implements OnInit {
  public data: { metodo: string };

  public form: FormGroup;
  public usuario: IUsuario;
  public carregando: boolean = false;
  public CELULAR: boolean;

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService,
    private modalController: ModalController,
    private mobileService: MobileService
  ) {
    this.form = this.formBuilder.group({
      apelido: ['', Validators.required],
      numero: ['', Validators.required],
      validade: ['', [Validators.required, this.formService.validaValidade()]],
      cvv: ['', Validators.required],
      titular: ['', Validators.required],
      cpf: ['', Validators.required, this.formService.validaCPF()],
    });

    this.mobileService.celular$.subscribe((celular) => {
      this.CELULAR = celular;
    });

    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });
  }

  ngOnInit(): void {}

  public validaForm(): void {
    if (this.usuario.cartoes !== null && this.usuario.cartoes.length === 5) {
      this.utilsService.exibirToast(
        'Você só pode cadastrar até 5 cartões!',
        'erro'
      );
      return;
    }

    if (
      this.formService.validaForm(this.form, {
        apelido: 'apelido',
        numero: 'número',
        validade: 'validade',
        cvv: 'CVV',
        titular: 'titular',
        cpf: 'CPF',
      })
    )
      this.cadastrar();
  }

  private async cadastrar() {
    this.carregando = true;

    const req = {
      ...this.form.value,
      cliente_id: this.usuario.jwt,
      metodo: this.data ? this.data.metodo : 'mundipagg',
    };

    let endpoint: string = 'cartao';

    if (this.data && this.data.metodo) endpoint = 'cartao_cielo';

    const res = await this.apiService.post(endpoint, req);

    this.carregando = false;

    if (res.status === 'success') {
      let cartoes = this.usuario.cartoes;

      if (cartoes === null) cartoes = [];

      cartoes.unshift(res.data);

      this.usuario.cartoes = cartoes;
      this.usuarioService.atualizarUsuario(this.usuario);
      this.utilsService.exibirToast(res.message, 'sucesso');
      this.dismiss();
    } else {
      this.utilsService.exibirToast(res.message, 'erro');
    }
  }

  public uppercase(tipo: string): void {
    let control = this.form.get(tipo);

    if (control) control.setValue(control.value.toUpperCase());
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
