import { Injectable } from '@angular/core';
import { CanActivate, UrlTree } from '@angular/router';

import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { Nivel } from 'src/app/interfaces/Shinguru.interface';

import { LojaService } from '../observers/loja.service';
import { ShinguruService } from './shinguru.service';
import { UsuarioService } from '../usuario/usuario.service';
import { UtilsService } from './../utils.service';

@Injectable({
  providedIn: 'root',
})
export class ShinguruGuard implements CanActivate {
  constructor(
    private lojaService: LojaService,
    private shinguruService: ShinguruService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {}

  public canActivate(): Observable<boolean | UrlTree> {
    return combineLatest([
      this.lojaService.loja$,
      this.usuarioService.usuario$,
      this.shinguruService.saldoFidelidade$,
    ]).pipe(
      map(([loja, usuario, dados_fidelidade]) => {
        if (this.shinguruLink(loja, usuario, dados_fidelidade)) {
          return true;
        }

        // O Tempo de requisição lenta acaba indo para este redirect, mesmo possuindo fidelidade
        //  else {
        //   this.utilsService.navegarPagina('/tabs/cardapio');
        //   return false;
        // }
      })
    );
  }

  private shinguruLink(
    loja: ILoja,
    usuario: IUsuario,
    fidelidade: Nivel
  ): boolean {
    return (
      usuario &&
      fidelidade &&
      fidelidade.nivel === 1 &&
      loja &&
      loja.habilitadoShinguru
    );
  }
}
