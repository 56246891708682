import { Component, Input, OnInit } from '@angular/core';

import { Nivel } from 'src/app/interfaces/Shinguru.interface';

@Component({
  selector: 'app-beneficios',
  templateUrl: './beneficios.component.html',
  styleUrls: ['./beneficios.component.scss'],
})
export class BeneficiosComponent implements OnInit {
  @Input() dados_nivel: Nivel;
  @Input() classe: string = '';

  constructor() {}

  ngOnInit(): void {}
}
