import { ModalController } from '@ionic/angular';
import { Component, OnInit } from '@angular/core';

export interface IAcao {
  titulo: string;
  paragrafos: Array<string>;
  paragrafo_secundario?: string;
  strong?: string;
  label_acao: string;
  acao: string;
}

@Component({
  selector: 'app-modal-generica',
  templateUrl: './modal-generica.page.html',
  styleUrls: ['./modal-generica.page.scss'],
})
export class ModalGenericaPage implements OnInit {
  public data: { data: IAcao };

  constructor(private modalController: ModalController) {}

  ngOnInit() {}

  public dismiss(acao: string) {
    this.modalController.dismiss(acao);
  }
}
