import { Injectable } from '@angular/core';
import {
  AlertController,
  LoadingController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { Location } from '@angular/common';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  windowWidth: number;

  constructor(
    private alertController: AlertController,
    private analyticsService: AnalyticsService,
    private iab: InAppBrowser,
    private navController: NavController,
    private loadingController: LoadingController,
    private location: Location,
    private toastController: ToastController
  ) {}

  public getLocationService(): Promise<{ lng: number; lat: number }> {
    return new Promise((resolve) => {
      navigator.geolocation.getCurrentPosition((resp) => {
        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      });
    });
  }

  public navegarPagina(pagina: string, animacao: boolean = false): void {
    this.navController.navigateForward(pagina, { animated: animacao });
  }

  public navegarPaginaRoot(pagina: string): void {
    this.navController.navigateRoot(pagina);
  }

  public setStorage(key: string, valor: any): any {
    localStorage.setItem(`gurume.${key}`, JSON.stringify(valor));
  }

  public getStorage(key: string): any {
    if (
      (localStorage.getItem(`gurume.${key}`) === undefined ||
        localStorage.getItem(`gurume.${key}`) === 'undefined') &&
      key == 'enderecos'
    ) {
      localStorage.clear();
    }

    return JSON.parse(localStorage.getItem(`gurume.${key}`));
  }

  public removeStorage(key: string): void {
    localStorage.removeItem(`gurume.${key}`);
  }

  public checkStorage(key: string) {
    return localStorage.getItem(`gurume.${key}`) == null ? false : true;
  }

  public async exibirLoading(): Promise<any> {
    const loading = await this.loadingController.create({
      message: '<ion-spinner name="crescent"></ion-spinner>',
      spinner: null,
      cssClass: 'loading',
    });

    return await loading.present();
  }

  public async ocultarLoading(): Promise<any> {
    try {
      return await this.loadingController.dismiss();
    } catch (error) {
      return false;
    }
  }

  public async exibirAlert(header: string, message: string): Promise<any> {
    const alert = await this.alertController.create({
      header: header,
      message: message,
      buttons: [
        {
          text: 'OK',
          role: 'cancel',
        },
      ],
    });

    return await alert.present();
  }

  public async exibirToast(
    mensagem: string,
    status: string = 'neutro',
    duration: number = 1500
  ): Promise<any> {
    const toast = await this.toastController.create({
      position: 'top',
      message: mensagem,
      buttons: [
        {
          icon: status === 'erro' ? 'close-circle-sharp' : '',
        },
      ],
      cssClass: 'toast-' + status,
      duration: duration,
    });

    if (status === 'erro') {
      const evento = this.analyticsService.showMessageError(mensagem);
      this.analyticsService.registrarEvento(evento);
    }

    return await toast.present();
  }

  public floatToReal(valor: number) {
    return valor.toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  public realToFloat(valor: string) {
    return Number.parseFloat(
      valor.replace('R$ ', '').replace('.', '').replace(',', '.')
    );
  }

  public async copyToClipboard(text: string): Promise<boolean> {
    try {
      await navigator.clipboard.writeText(text);
      this.exibirToast('Texto copiado para a área de transferência!', 'sucesso');
      return true;
    } catch (error) {
      this.exibirToast(
        'Erro ao copiar para a área de transferência: ' + error,
        'erro'
      );
      return false;
    }
  }

  public validUri(text: string): string {
    return text
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[\W_]+/g, '-')
      .toLowerCase()
      .replace(/^-+|-+$/g, '');
  }

  public capitalizeWords(string: string): string {
    return string.replace(/\b\w/g, (match: string) => match.toUpperCase());
  }

  public openLink(link: string, isALink: boolean = false): void {
    const links = {
      facebook: 'https://pt-br.facebook.com/gurumeoficial/',
      instagram: 'https://www.instagram.com/gurume_oficial/',
      linkedin: 'https://www.linkedin.com/company/grupotrigo/',
      twitter: '',
      playstore: '',
      appstore: '',
    };

    const url = links[link];
    if (url) {
      this.iab.create(url, '_system').show();
    } else if (isALink) {
      this.iab.create(link, '_system').show();
    }
  }

  public whatsApp(telefone_param: string): string {
    const telefone: string = telefone_param;
    const telefone_sem_mascara = telefone.replace(/\D/g, '');

    this.openLink(`https://wa.me/55${telefone_sem_mascara}`, true);

    return telefone_sem_mascara;
  }

  public scroll(elemento: string = '', offsetTop: number = 0) {
    document.getElementById('content').scroll({
      top:
        elemento !== ''
          ? document.getElementById(elemento).offsetTop - offsetTop
          : offsetTop,
      left: 0,
      behavior: 'smooth',
    });
  }

  public mostrarTopo(urlWeb: string, urlMobile: string): boolean {
    let uri: string = this.location.path(false);

    if (uri == urlWeb) return false;

    if (uri == urlMobile) return true;
  }
  
  public applyMascaraCEP(cep: string){
    cep = cep.replace(/\D/g, '');
    cep = cep.replace(/^(\d{5})(\d{3})$/, '$1-$2');
    
    return cep;
  }

  public checkValueCep(valor: string){
    return /^\d{8}$|^\d{5}-\d{3}$/.test(valor);
  }
}
