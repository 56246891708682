import { Component, OnInit } from '@angular/core';

import { ApiResponse } from 'src/app/interfaces/api';
import { ICartao, IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { IEndereco } from 'src/app/interfaces/IEndereco.interface';
import { IPagamentoSelecionado } from 'src/app/interfaces/IPagamento.interface';

import { ApiService } from 'src/app/services/api.service';
import { EnderecoService } from 'src/app/services/endereco.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';

import { AdicionarCartaoPage } from '../../usuario-v2/adicionar-cartao/adicionar-cartao.page';
import { ModalPerguntarDoacaoPage } from '../modal-perguntar-doacao/modal-perguntar-doacao.page';
import { ModalPagamentoPixDoacaoPage } from '../modal-pagamento-pix-doacao/modal-pagamento-pix-doacao.page';
import { ModalDoacaoPage } from '../modal-doacao/modal-doacao.page';

import { SOFTWARE_VERSION } from 'src/environments/environment';

@Component({
  selector: 'app-modal-forma-pagamento-doacao',
  templateUrl: './modal-forma-pagamento-doacao.page.html',
  styleUrls: ['./modal-forma-pagamento-doacao.page.scss'],
})
export class ModalFormaPagamentoDoacaoPage implements OnInit {
  public data!: { data: number };
  public cartoes: ICartao[] = [];
  public cartao_selecionado: ICartao | null = null;
  public pagamento_selecionado: IPagamentoSelecionado | null = null;

  public carregando: boolean = false;

  private usuario: IUsuario;
  private endereco: IEndereco;

  constructor(
    private apiService: ApiService,
    private enderecoService: EnderecoService,
    private modalsService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.enderecoService.enderecoList$.subscribe(
      (enderecos) => (this.endereco = enderecos[0])
    );

    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;

      let cartoes = usuario?.cartoes;

      if (cartoes?.length === 0 || cartoes == null || cartoes == undefined) {
        this.cartoes = null;
      } else {
        let cartoes = usuario.cartoes.filter(
          (cartao) => cartao.metodo === 'braspag'
        );

        this.cartoes = cartoes;
      }
    });
  }

  ngOnInit(): void {}

  public selecionarForma(cartao: ICartao | null, pix: boolean = false): void {
    if (pix === false) {
      this.cartao_selecionado = cartao;
      this.pagamento_selecionado = null;
    } else {
      this.cartao_selecionado = null;

      this.pagamento_selecionado = {
        tipo_forma: 'PIX',
        tipo_forma_pgto: 'online',
        forma: 'PIX',
        label: 'PIX',
        bandeira: null,
        nome: '',
        imagem: '',
        cartao: null,
        valor: this.data.data,
      };

      this.utilsService.setStorage(
        'forma-pagamento-doacao',
        this.pagamento_selecionado
      );
    }
  }

  public enviarDoacao(): void {
    const pagamento: IPagamentoSelecionado = this.utilsService.getStorage(
      'forma-pagamento-doacao'
    );

    if (pagamento) {
      if (pagamento.forma === 'PIX') {
        this.carregando = true;

        this.doacao('pix', pagamento, true).then((res) => {
          this.carregando = false;

          let status: string = '';

          if (res.status == 'success') {
            status = 'sucesso';

            this.modalsService
              .abrirModal(
                'modal-pagamento-doacao-pix',
                'modal-doacao',
                ModalPagamentoPixDoacaoPage,
                { valor: this.data.data, pixId: res.data.pixId }
              )
              .then((resp) => {
                if (resp && resp.data === 'conluir-fluxo') this.dismiss(false);
              });
          } else status = 'erro';

          this.utilsService.exibirToast(res.message, status);
        });
      } else {
        this.carregando = true;

        this.doacao('doacao', pagamento).then((res) => {
          this.carregando = false;

          let status: string = '';

          if (res.status == 'success') {
            status = 'sucesso';

            this.modalsService.abrirModal(
              'modal-pagamento-doacao-pix',
              'modal-doacao',
              ModalDoacaoPage
            ).then(() => {
              this.dismiss(false);
            });
          } else status = 'erro';

          this.utilsService.exibirToast(res.message, status);
        });
      }
    }
  }

  private async doacao(
    endpoint: string,
    pgtoSelecionado: IPagamentoSelecionado,
    doacao: boolean = false
  ): Promise<ApiResponse> {
    const fingerPrintId = document
      .getElementById('metrix_body')
      .getAttribute('src')
      .split('braspag_grupotrigo_split')[1];

    let req = {
      jwt: this.usuario.jwt,
      tipoPagamento: pgtoSelecionado.forma,
      formaPagamento: pgtoSelecionado.tipo_forma_pgto,
      bandeira: pgtoSelecionado.bandeira,
      total: this.data.data,
      nome: this.usuario.nome,
      cpf: this.usuario.cpf,
      email: this.usuario.email,
      celular: this.usuario.celular,
      endereco: {
        cep: this.endereco.cep,
        logradouro: this.endereco.logradouro,
        numero: this.endereco.numero,
        complemento: this.endereco.complemento,
        pontoReferencia: this.endereco.ponto_referencia,
        bairro: this.endereco.bairro,
        cidade: this.endereco.cidade,
        estado: this.endereco.estado,
        lat: this.endereco.lat ? this.endereco.lat : this.endereco.latitude,
        lng: this.endereco.lng ? this.endereco.lng : this.endereco.longitude,
      },
      cashback: 0,
      origin:
        this.utilsService.getStorage('canal-compra') == 'whatsapp'
          ? 'CHATBOT'
          : 'PWA',
      version: SOFTWARE_VERSION,
      fingerPrintId: fingerPrintId,
    };

    if (endpoint === 'doacao') req['cartaoId'] = pgtoSelecionado.cartao.id;

    if (doacao) req['doacao'] = 1;

    const res = await this.apiService.post(endpoint, req);

    return res;
  }

  public adicionarCartao(): void {
    this.modalsService.abrirModal(
      'modal-adicionar-cartao',
      'modal-adicionar-cartao',
      AdicionarCartaoPage,
      { metodo: 'braspag' }
    );
  }

  public dismiss(abrirModal: boolean = true): void {
    this.utilsService.removeStorage('forma-pagamento-doacao');
    this.modalsService.fecharModal();

    if (abrirModal) {
      this.modalsService.abrirModal(
        'modal-perguntar-doacao',
        'modal-doacao',
        ModalPerguntarDoacaoPage,
        {},
        (params: any) => {
          if (params.data.data === 'executar' && params.data.valor) {
            this.doarInstitutoCrianca(params.data.valor);
          }
        }
      );
    }
  }

  private doarInstitutoCrianca(valor: number): void {
    this.modalsService.abrirModal(
      'modal-pagamento-doacao',
      'modal-doacao',
      ModalFormaPagamentoDoacaoPage,
      { data: valor }
    );
  }
}
