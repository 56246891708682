import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IEndereco } from 'src/app/interfaces/IEndereco.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { EnderecoService } from 'src/app/services/endereco.service';
import { CarrinhoService } from 'src/app/services/observers/carrinho.service';
import { LojaService } from './../../services/observers/loja.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';

declare let dataLayer: any;
@Component({
  selector: 'app-produto',
  templateUrl: './produto.page.html',
  styleUrls: ['./produto.page.scss'],
})
export class ProdutoPage implements OnInit {
  public produto: any;
  public quantidade_esperada_atingida: boolean = false;
  public usuario: IUsuario;
  public endereco: IEndereco;

  public counter: string = '0/100';
  public observacao: string;
  public selecionado_observacao: boolean;

  public topo: boolean;
  private celular: boolean;

  private loja: ILoja;

  constructor(
    private analyticsService: AnalyticsService,
    private carrinhoService: CarrinhoService,
    private enderecoService: EnderecoService,
    private usuarioService: UsuarioService,
    private utils: UtilsService,
    private lojaService: LojaService,
    private mobileService: MobileService,
    private modalController: ModalController
  ) {
    this.mobileService.celular$.subscribe(
      (celular) => (this.celular = celular)
    );

    this.enderecoService.enderecoList$.subscribe((enderecos) => {
      this.endereco = enderecos[0];
    });

    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });

    this.lojaService.loja$.subscribe((loja) => {
      this.loja = loja;
    });
  }

  ngOnInit() {
    this.topo = this.utils.mostrarTopo('/tabs/cardapio', '/produto');

    this.produto = this.utils.getStorage('produto');

    this.produto.qtd = 1;
    this.produto.preco.unitario =
      this.produto.preco_obs === 1 ? 0 : this.produto.preco.valor;

    const event = this.analyticsService.item(
      'view_item',
      this.produto,
      this.loja,
      this.utils.getStorage('canal-compra') == 'whatsapp'
        ? 'CHATBOT'
        : 'PWA'
    );

    dataLayer.push({ ecommerce: null });
    dataLayer.push(event);
  }

  ngOnDestroy(): void {
    this.utils.removeStorage('produto');
  }

  public alteraProduto(tipo: string): void {
    if (tipo === 'add') {
      this.produto.qtd++;
    } else {
      if (this.produto.qtd === 1) {
        return;
      }

      this.produto.qtd--;
    }

    let totalAdicionais = 0;

    if (this.produto.observacoes) {
      this.produto.observacoes.forEach((observacoes: any) => {
        if (observacoes.tipo_opcional == 'Adicional') {
          observacoes.adicionais.forEach((item) => {
            totalAdicionais += item.qtd * item.preco.valor;
          });
        }
      });
    }

    this.produto.preco.valor =
      this.produto.qtd * this.produto.preco.unitario +
      this.produto.qtd * totalAdicionais;
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  public alteraAdicional(tipo: string, i: number, index: number): void {
    let observacoes = this.produto.observacoes[index];
    let qtdSelecionada = 0;

    if (tipo === 'add') {

      observacoes.adicionais.forEach((adicional) => {
        qtdSelecionada += adicional.qtd;
      });

      if (observacoes.regra == qtdSelecionada + 1) {
        this.quantidade_esperada_atingida = true;
      } else {
        this.quantidade_esperada_atingida = false;
      }

      if (observacoes.regra < qtdSelecionada + 1) {

        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida.', 'erro'
        );

        return;
      }

      this.produto.observacoes[index].adicionais[i].qtd++;

    } else {

      if (observacoes.regra == qtdSelecionada + 1) {
        this.quantidade_esperada_atingida = true;
      } else {
        this.quantidade_esperada_atingida = false;
      }

      if (this.produto.observacoes[index].adicionais[i].qtd === 0) {
        return;
      }


      this.produto.observacoes[index].adicionais[i].qtd--;
    }

    let totalAdicionais = 0;

    if (this.produto.observacoes) {
      this.produto.observacoes.forEach((element) => {
        element.adicionais.forEach((item: any) => {
          totalAdicionais += item.qtd * item.preco.valor;
        });
      });
    }

    this.produto.preco.valor =
      this.produto.qtd * this.produto.preco.unitario +
      this.produto.qtd * totalAdicionais;
    this.produto.preco.texto = this.utils.floatToReal(this.produto.preco.valor);
  }

  public selecionarObservacao(item, indexOpc, indexObs, tipo): void {
    let regra = this.produto.observacoes[indexObs].regra;
    let itensObservacoes;

    switch (tipo) {
      case 'obrigatorio':
        itensObservacoes = this.produto.observacoes[indexObs].obrigatorios;
        break;

      case 'opcional':
        itensObservacoes = this.produto.observacoes[indexObs].opcionais;
        break;
      default:
        break;
    }

    let qtdSelecionado = 0;
    itensObservacoes.forEach((element) => {
      if (element.selecionado) {
        qtdSelecionado++;
      }
    });

    setTimeout(() => {
      if (qtdSelecionado > regra && regra > 1) {
        item.selecionado = false;
        this.utils.exibirToast(
          'A quantidade máxima para essa opção já foi atingida', 'erro'
        );
      } else {
        if (this.produto.observacoes[indexObs].tipo_preco == 'preco') {
          if (tipo == 'obrigatorio' && regra == 1 && qtdSelecionado >= regra) {
            itensObservacoes.forEach((element, i) => {
              if (i != indexOpc) {
                if (element.selecionado) {
                  this.produto.preco.unitario = this.produto.preco.valor -=
                    element.preco.valor;
                  element.selecionado = false;
                }
              }
            });
            this.produto.preco.unitario = this.produto.preco.valor +=
              item.preco.valor;
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
          } else if (item.selecionado) {
            this.produto.preco.valor = 0;
            this.produto.preco.unitario = this.produto.preco.valor +=
              item.preco.valor;
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
          } else {
            this.produto.preco.unitario = this.produto.preco.valor -=
              item.preco.valor;
            this.produto.preco.texto = this.utils.floatToReal(
              this.produto.preco.valor
            );
          }
        }
      }
    }, 200);
  }

  public adicionarItem(navegar: boolean = true): void {
    this.produto.observacao = this.observacao;
    this.carrinhoService.adicionarItem(this.produto, navegar).then((response) => {
      if (response !== false) {
        this.navegar('tabs/cardapio');
      }
    });
  }

  public counterObservacao(event: any): void {
    this.counter = `${event.detail.value.length}/100`;
  }

  public ionFocus(): void {
    if (this.celular) {
      this.selecionado_observacao = true;
      const element = document.getElementById('input');
      element.classList.add('pd-20');
    }
  }
  public ionBlur(): void {
    if (this.celular) {
      this.selecionado_observacao = false;
      const element = document.getElementById('input');
      element.classList.remove('pd-20');
    }
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public tabelaNutricional(): void {
    this.utils.openLink(
      'https://gurume.alphacode.com.br/img/Tabela_Nutricional_Gurume_v2.pdf',
      true
    );
  }

  public async navegar(caminho: string): Promise<void> {
    const modal = await this.modalController.getTop();
    if (caminho == 'login' || caminho == 'buscar-endereco') {
      if (modal) {
        this.dismiss();
      }
    }

    this.utils.navegarPagina(caminho);
  }
}
