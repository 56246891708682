import { Component, Input, OnInit } from '@angular/core';
import { ITracking } from 'src/app/interfaces/IPedido.interface';

@Component({
  selector: 'app-tracking-pedido',
  templateUrl: './tracking-pedido.component.html',
  styleUrls: ['./tracking-pedido.component.scss'],
})
export class TrackingPedidoComponent implements OnInit {
  @Input() tracking: Array<ITracking>;
  @Input() aberto: boolean;

  public novo_tracking: Array<ITracking> = [];

  constructor() {}

  ngOnInit() {
    this.tracking.forEach((tracking) => {
      let status: string;

      switch (tracking.status) {
        case 'Aguardando pagamento':
        case 'Pendente':
        case 'Confirmado':
          status = 'Pedido realizado';
          break;
        case 'Em preparo':
          status = 'Pedido em preparo';
          break;
        case 'Saiu para entrega':
          status = 'Pedido saiu para entrega';
          break;
        case 'Pronto para retirar':
          status = 'Pedido pronto para retirar';
          break;
        case 'Concluído':
          status = 'Pedido concluído';
          break;
        case 'Cancelado':
          status = 'Pedido cancelado';
          break;
        default:
          break;
      }

      this.novo_tracking.push({ status: status, hora: tracking.hora });
    });

    const setStatus = new Set();

    this.novo_tracking = this.novo_tracking.filter((tracking) => {
      const statusDuplicado = setStatus.has(tracking.status);
      setStatus.add(tracking.status);
      return !statusDuplicado;
    });
  }
}
