import { Component, Input, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { PedidoService } from 'src/app/services/observers/pedido.service';
import { UtilsService } from 'src/app/services/utils.service';
import {
  IBandeiras,
  IFormasPagamento,
  IFormasPgtoDisponiveis,
  IPagamentoSelecionado,
} from 'src/app/interfaces/IPagamento.interface';
import {
  IAcao,
  ModalGenericaInputPage,
} from 'src/app/modals/modal-generica/modal-generica-input/modal-generica-input.page';
import { CELULAR } from 'src/app/app.component';

@Component({
  selector: 'app-selecionar-pagamento',
  templateUrl: './selecionar-pagamento.component.html',
  styleUrls: ['./selecionar-pagamento.component.scss'],
})
export class SelecionarPagamentoComponent implements OnInit {
  public celular: boolean = CELULAR;
  public formas: Array<IFormasPagamento> = [];
  public pagamentos_disponiveis: IFormasPgtoDisponiveis;

  private data: {
    data: {
      formas: Array<IFormasPagamento>;
      pagamentos_disponiveis: IFormasPgtoDisponiveis;
    };
    isMobile: boolean;
  };

  public forma_pagamento: IPagamentoSelecionado = null;

  constructor(
    private modalController: ModalController,
    private modalService: ModalsService,
    private pedidoService: PedidoService,
    private utilsService: UtilsService
  ) {
    this.pedidoService.pagamentoSelecionado$.subscribe((pgtoSelecionado) => {
      this.forma_pagamento = pgtoSelecionado;
    });
  }

  async ngOnInit() {
    this.formas = this.data.data.formas;
    this.pagamentos_disponiveis = this.data.data.pagamentos_disponiveis;
  }

  public selecionarForma(
    forma: IFormasPagamento | string,
    bandeira: KeyValue<string, IBandeiras> = null
  ) {
    if (typeof forma !== 'string') {
      const bandeira_key = String(bandeira.key);

      const data: IPagamentoSelecionado = {
        tipo_forma: 'na entrega',
        tipo_forma_pgto: 'entrega',
        forma: forma.forma,
        label: forma.label,
        bandeira: bandeira_key,
        nome: bandeira.value.bandeira,
        imagem: bandeira.value.imagem,
        cartao: null,
        valor: 0,
      };

      this.modalController.dismiss(data);
      this.pedidoService.formaPagamento(data);
    }

    if (typeof forma == 'string' && forma == 'dinheiro') {
      const data: IAcao = {
        titulo: 'Troco pra quanto?',
        paragrafos: [
          'Digite quanto vai pagar em dinheiro pra que o entregador leve o troco.',
        ],
        inputs: [
          {
            ion_label: 'Valor',
            ion_input: {
              placeholder: 'R$ 00,00',
              type: 'text',
              ngmodel: 'valor',
              controlador: 'valor',
              mask: {
                type: 'num',
                money: true,
                thousand: '.',
                decimalCaracter: ',',
                decimal: 2,
              },
            },
          },
        ],
        strong: 'Confirmar',
        strong_cancelar: 'Não quero troco',
        acao_secundaria: 'sem_troco',
      };

      this.modalService.abrirModal(
        'modal-generica',
        'modal-generica',
        ModalGenericaInputPage,
        { data: data },
        (params: any) => {
          let data: IPagamentoSelecionado = {
            tipo_forma: 'na entrega',
            tipo_forma_pgto: 'entrega',
            forma: 'CASH',
            label: 'Dinheiro',
            bandeira: '',
            nome: '',
            imagem: '/assets/icon/pagamento_dinheiro.svg',
            cartao: null,
            valor: 0,
          };

          if (params.data === 'executar' || params.data === 'sem_troco') {
            if (params.data === 'executar' && params.role.valor !== '') {
              data = {
                ...data,
                valor: this.utilsService.realToFloat(params.role.valor),
              };
            }

            const forma_pagamento =
              this.utilsService.getStorage('forma-pagamento');

            if (data === undefined) {
              this.pedidoService.formaPagamento(forma_pagamento);
              this.dismiss();
            } else {
              this.pedidoService.formaPagamento(data);
              this.dismiss();
            }
          }
        }
      );
    }
  }

  public dismiss(): void {
    this.modalService.fecharModal();
  }
}
