import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { Nivel } from 'src/app/interfaces/Shinguru.interface';

import { ShinguruService } from 'src/app/services/shinguru/shinguru.service';
import { ModalsService } from './../../../services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

import { BeneficiosNivelComponent } from 'src/app/modals/fidelidade/beneficios-nivel/beneficios-nivel.component';

@Component({
  selector: 'app-nivel-atual',
  templateUrl: './nivel-atual.component.html',
  styleUrls: ['./nivel-atual.component.scss'],
})
export class NivelAtualComponent implements OnChanges {
  @Input() nivel_atual: Nivel;
  public dados_nivel: Nivel;

  public width: number = 0;

  constructor(
    private modalsService: ModalsService,
    private shinguruService: ShinguruService,
    private utilsService: UtilsService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.nivel_atual) {
      this.width = this.shinguruService.progresso(
        this.nivel_atual.pontos_cliente,
        this.nivel_atual.max_pontos
      );
      this.dados_nivel = this.shinguruService.setDados(this.nivel_atual?.nome);
    }
  }

  public beneficios(nivel: string): void {
    this.modalsService.abrirModal(
      'modal-beneficios',
      `modal-beneficios ${this.utilsService.validUri(nivel)}`,
      BeneficiosNivelComponent,
      this.shinguruService.setDados(nivel)
    );
  }
}
