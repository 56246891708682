import { Component, OnInit, ViewChild } from '@angular/core';

import { IonSlides } from '@ionic/angular';

import { IBeneficio } from 'src/app/interfaces/IBeneficio.interface';
import { IProduto } from 'src/app/interfaces/IProduto.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';

import { CarrinhoService } from 'src/app/services/observers/carrinho.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { LojaService } from 'src/app/services/observers/loja.service';
import { UtilsService } from 'src/app/services/utils.service';

import { ModalProdutoPage } from 'src/app/modals/shinguru/modal-produto/modal-produto.page';
@Component({
  selector: 'app-modal-listagem-produtos',
  templateUrl: './modal-listagem-produtos.page.html',
  styleUrls: ['./modal-listagem-produtos.page.scss'],
})
export class ModalListagemProdutosPage implements OnInit {

  @ViewChild('slideCategoria') slideCategoria: IonSlides;

  public data: IBeneficio;
  public filtro: string = '';
  public celular: boolean;
  private carrinho: any = [];
  private loja: ILoja;
  private validacao_categoria: any = [];

  constructor(
      private carrinhoService: CarrinhoService,
      private lojaService: LojaService,
      private mobileService: MobileService,
      private modalService: ModalsService,
      private utilsService: UtilsService,
  ) {
    this.lojaService.loja$.subscribe((loja) => {
      this.loja = loja;
    });
  }

  ngOnInit(): void {
    this.subscribers();
  }

  ngOnDestroy(): void {}

  ionViewWillEnter(): void {
    for (const categoria of this.data?.categorias) {
        this.validacao_categoria.push({
          categoria: categoria.nome,
          atende: false
        });
    }
  }

  ionViewWillLeave(): void {
    this.carrinho = [];
    this.validacao_categoria = [];
  }

  private subscribers(): void {
    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;
    });
  }

  public async adicionarItem(produto: IProduto = null): Promise<void> {

    for (const categoria of this.validacao_categoria) {
      if (categoria?.atende && categoria?.categoria === produto.categoria) {
        this.utilsService.exibirToast('Ops... você já atingiu a quantidade maxima de produtos para essa categoria.', 'erro');
        return;
      }
    }

    produto.qtd = 1;
    this.carrinho.push(produto);

    if (this.utilsService.getStorage('carrinho-loja-id') != null && this.loja.id !== this.utilsService.getStorage('carrinho-loja-id')) {
      this.utilsService.exibirToast('Parece que seu carrinho está salvo com outra loja.','erro');
      this.carrinho = [];
      return;
    }

    this.utilsService.exibirToast('Produto adicionado com sucesso!', 'sucesso');

    this.validacao_categoria = this.data.categorias?.reduce((acc, categoria) => {
      const quantidade = this.carrinho.reduce((acc, item) => {
        if (item.categoria === categoria.nome) acc++;
        return acc
      }, 0);

      acc.push({
        categoria: categoria.nome,
        atende: quantidade >= categoria.min && quantidade <= categoria.max
      })

      return acc;
    }, []);

    for (const categoria of this.validacao_categoria) {
      if (!categoria.atende) {
        this.utilsService.exibirToast(`Você deve adicionar produtos da categoria ${categoria.categoria}.`, 'erro');
        return;
      }
    }

    for (const produto of this.carrinho) this.carrinhoService.adicionarItem(produto, false, true, false);

    if (await this.modalService.existeModal()) this.modalService.fecharModal({status: 'success',});

  }

  public irPara(
    caminho: string,
    produto: IProduto = null,
    evento: string = ''
  ): void {

    if (produto !== null) {
      this.utilsService.setStorage('produto', produto);

      if (evento === 'adicionar-sacola') {
        if (produto.observacoes) {
          this.modalService.abrirModal(
            `modal-produto`,
            `modal-produto`,
            ModalProdutoPage
          );

          return;
        }
      }
    }

      if (produto !== null) {
        if (evento == '') {
          this.modalService.abrirModal(
            `modal-produto`,
            `modal-produto`,
            ModalProdutoPage
          ).then((res) => {
            if(res?.data?.produto) this.adicionarItem(res.data.produto);
          });
        } else {
          produto.qtd = 1;
        }
      } else {
        this.utilsService.navegarPagina(caminho);
      }

  }

  public dismiss(): void {
    this.modalService.fecharModal();
  }


}
