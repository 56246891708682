import { Component, OnInit } from '@angular/core';

import { Nivel } from 'src/app/interfaces/Shinguru.interface';
import { MobileService } from 'src/app/services/mobile/mobile.service';

import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-beneficios-nivel',
  templateUrl: './beneficios-nivel.component.html',
  styleUrls: ['./beneficios-nivel.component.scss'],
})
export class BeneficiosNivelComponent implements OnInit {
  public data: Nivel;
  public celular: boolean;

  constructor(
    private mobileService: MobileService,
    private modalsService: ModalsService,
    private utilsService: UtilsService
  ) {
    this.mobileService.celular$.subscribe(
      (celular) => (this.celular = celular)
    );
  }

  ngOnInit(): void {}

  public dismiss(): void {
    this.modalsService.fecharModal();
  }

  public navegar(caminho: string): void {
    if (caminho === '/shinguru/detalhes-cashback') this.dismiss();
    this.utilsService.navegarPagina(caminho);
  }
}
