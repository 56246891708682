import { Loader } from "@googlemaps/js-api-loader"

export const environment = {
  production: false,
  clientId:
    '776044976683-vobndif6i2lrhiilgkppn42vqrbnt3fg.apps.googleusercontent.com',
  firebase: {
    apiKey: 'AIzaSyDhzIkHMQv-MbFhyOtbQr5AnR7Wp4y8f-E',
    authDomain: 'trigo-358311.firebaseapp.com',
    projectId: 'trigo-358311',
    storageBucket: 'trigo-358311.appspot.com',
    messagingSenderId: '776044976683',
    appId: '1:776044976683:web:24ed82684d17a0060fa86e',
    measurementId: 'G-0GVGSYEHGX',
  },
};

export const loader = new Loader({
  apiKey: 'AIzaSyD9AkDWwXaHyOriXbO1KaJgYyYHb-SrpEc',
  version: 'weekly',
  language: 'pt-BR',
  libraries: ['places'],
});

// export const API_URL: string = 'http://gurume.local/api/';
// export const API_URL: string = "http://gurume.mac/api/";
// export const API_URL: string = 'https://gurume.alphacode.com.br/api/';
export const API_URL: string = "https://gurume.alphacode.mobi/api/";
// export const API_URL: string = 'https://gurume.ws01.mobi/api/';

export const PASSPHRASE_AUTH =
  '844047304b5edc5d041114ddc5a39742713c25a47ea38c70bb59b42f94ba90b9';

export const ENCRYPT = false;

export const SOFTWARE_VERSION = '1.2.9';

export const ENCRYPT_KEY = 'yjQM2PMsmAyKKBgajYmLrPWO4KA0PedMclFoTIxpBLniWUpnRqm0i9tOXqOc4148';

export const REDEID = 4;

