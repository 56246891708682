import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './../api.service';
import { UsuarioService } from '../usuario/usuario.service';
import { ICardapio } from 'src/app/interfaces/ICardapio.interface';
import { REDEID } from 'src/environments/environment';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';

interface IReq {
  jwt?: string;
  lojaId?: string | number;
  marcaId: number;
  modalidadeId: string;
}

@Injectable({
  providedIn: 'root',
})
export class CardapioService {
  private cardapioSubject = new BehaviorSubject<Array<ICardapio>>(null);
  cardapio$: Observable<Array<ICardapio>> = this.cardapioSubject.asObservable();

  private carregandoSubject = new BehaviorSubject<boolean>(false);
  carregando$: Observable<boolean> = this.carregandoSubject.asObservable();

  private dados_usuario: IUsuario;

  constructor(
    private apiService: ApiService,
    private usuarioService: UsuarioService
  ) {
    this.usuarioService.usuario$.subscribe((usuario) => {
      this.dados_usuario = usuario;
    });
  }

  public atualizarCardapio(cardapio: Array<ICardapio>) {
    this.cardapioSubject.next(cardapio);
  }

  public async buscarCardapio(loja: ILoja) {
    this.carregandoSubject.next(true);

    let req: IReq = {
      jwt: this.dados_usuario?.jwt,
      ...(loja !== null && { lojaId: loja.id }),
      marcaId: REDEID,
      modalidadeId: 'DLV',
    };

    const res = await this.apiService.get('cardapio', req);

    let cardapio: Array<ICardapio> = res.data.cardapio;

    if (
      res.status === 'success' &&
      cardapio !== null &&
      cardapio !== undefined &&
      cardapio.length !== 0 &&
      Object.keys(cardapio).length !== 0
    )
      this.atualizarCardapio(cardapio);
    else this.atualizarCardapio(null);

    this.carregandoSubject.next(false);
  }
}
