import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { BrMaskerModule } from 'br-mask';

import { TopoComponent } from './components/topo/topo.component';
import { FooterComponent } from './components/footer/footer.component';

import { LocalizacaoAtualComponent } from './components/localizacao-atual/localizacao-atual.component';

import { MinhaContaComponent } from './components/usuario-v2/minha-conta/minha-conta.component';
import { MeusCartoesComponent } from './components/usuario-v2/meus-cartoes/meus-cartoes.component';
import { MeusEnderecosComponent } from './components/usuario-v2/meus-enderecos/meus-enderecos.component';
import { ConfigurarNotificacoesComponent } from './components/usuario-v2/configurar-notificacoes/configurar-notificacoes.component';
import { NotificacoesComponent } from './components/usuario-v2/notificacoes/notificacoes.component';
import { TermosCondicoesComponent } from './components/usuario-v2/termos-condicoes/termos-condicoes.component';
import { PoliticaPrivacidadeComponent } from './components/usuario-v2/politica-privacidade/politica-privacidade.component';

import { AdicionalComponent } from './components/pedido/adicional/adicional.component';
import { TrackingPedidoComponent } from './components/pedido/tracking-pedido/tracking-pedido.component';
import { SelecionarPagamentoComponent } from './components/pedido/selecionar-pagamento/selecionar-pagamento.component';
import { DetalhesPedidoComponent } from './components/historico/detalhes-pedido/detalhes-pedido.component';
import { EntregaEmComponent } from './components/pedido/entrega-em/entrega-em.component';
import { ItensPedidoComponent } from './components/pedido/itens-pedido/itens-pedido.component';
import { ResumoValoresComponent } from './components/pedido/resumo-valores/resumo-valores.component';
import { PixProgressoComponent } from './components/pix-progresso/pix-progresso.component';

import { SegmentComponent } from './components/segment/segment.component';
import { BannersComponent } from './components/banners/banners.component';

import { CardProdutoComponent } from './components/cards/card-produto/card-produto.component';
import { CardCartaoComponent } from './components/cards/card-cartao/card-cartao.component';
import { CardPedidoHistoricoComponent } from './components/historico/card-pedido-historico/card-pedido-historico.component';
import { CardRestauranteComponent } from './components/cards/card-restaurante/card-restaurante.component';

import { NivelAtualComponent } from './components/fidelidade/nivel-atual/nivel-atual.component';
import { BeneficiosNivelComponent } from './modals/fidelidade/beneficios-nivel/beneficios-nivel.component';
import { BeneficiosComponent } from './components/fidelidade/beneficios/beneficios.component';
import { CardBeneficioComponent } from './components/cards/card-beneficio/card-beneficio.component';
import { PontosExpirarComponent } from './components/fidelidade/pontos-expirar/pontos-expirar.component';
import { FiltroFidelidadeComponent } from './modals/fidelidade/filtro-fidelidade/filtro-fidelidade.component';

@NgModule({
  declarations: [
    TopoComponent,
    FooterComponent,
    LocalizacaoAtualComponent,
    MinhaContaComponent,
    MeusCartoesComponent,
    MeusEnderecosComponent,
    ConfigurarNotificacoesComponent,
    NotificacoesComponent,
    TermosCondicoesComponent,
    PoliticaPrivacidadeComponent,
    AdicionalComponent,
    TrackingPedidoComponent,
    SelecionarPagamentoComponent,
    DetalhesPedidoComponent,
    EntregaEmComponent,
    ItensPedidoComponent,
    ResumoValoresComponent,
    PixProgressoComponent,
    SegmentComponent,
    BannersComponent,
    CardProdutoComponent,
    CardCartaoComponent,
    CardPedidoHistoricoComponent,
    CardRestauranteComponent,
    NivelAtualComponent,
    BeneficiosNivelComponent,
    BeneficiosComponent,
    CardBeneficioComponent,
    FiltroFidelidadeComponent,
    PontosExpirarComponent,
  ],
  imports: [
    BrMaskerModule,
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    TopoComponent,
    FooterComponent,
    LocalizacaoAtualComponent,
    MinhaContaComponent,
    MeusCartoesComponent,
    MeusEnderecosComponent,
    ConfigurarNotificacoesComponent,
    NotificacoesComponent,
    TermosCondicoesComponent,
    PoliticaPrivacidadeComponent,
    AdicionalComponent,
    TrackingPedidoComponent,
    SelecionarPagamentoComponent,
    DetalhesPedidoComponent,
    EntregaEmComponent,
    ItensPedidoComponent,
    ResumoValoresComponent,
    PixProgressoComponent,
    SegmentComponent,
    BannersComponent,
    CardProdutoComponent,
    CardCartaoComponent,
    CardPedidoHistoricoComponent,
    CardRestauranteComponent,
    NivelAtualComponent,
    BeneficiosNivelComponent,
    BeneficiosComponent,
    CardBeneficioComponent,
    FiltroFidelidadeComponent,
    PontosExpirarComponent,
  ],
})
export class ComponentsModule {}
