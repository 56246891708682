import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { RestauranteService } from 'src/app/services/observers/restaurante.service';
import { UtilsService } from 'src/app/services/utils.service';
import { IHorario, IRestaurante } from 'src/app/interfaces/IRestaurante.interface';

@Component({
  selector: 'app-detalhes-restaurante',
  templateUrl: './detalhes-restaurante.page.html',
  styleUrls: ['./detalhes-restaurante.page.scss'],
})
export class DetalhesRestaurantePage implements OnInit {
  public type: string = "delivery"
  public restaurante: IRestaurante;
  public todos_horarios: boolean = false;

  public horarios: IHorario;
  public dias: Array<string>;

  public topo: boolean;
  public celular: boolean;

  constructor(
    private mobileService: MobileService,
    private modalController: ModalController,
    private restauranteService: RestauranteService,
    private utilsService: UtilsService
  ) {
    this.restauranteService.restauranteSelecionado$.subscribe((restaurante) => {
      this.restaurante = restaurante;
    });

    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;

      if (!this.celular) {
        this.todos_horarios = true;
      }
    });
  }

  ngOnInit() { }

  ionViewWillEnter() {
    this.topo = this.utilsService.mostrarTopo(
      '/tabs/restaurantes',
      '/tabs/detalhes-restaurante'
    );

    if (this.restaurante == null) this.navegar('tabs/restaurantes');

    let horariosAux = {};

    horariosAux[this.restaurante?.nome] = {};

    this.restaurante?.modalidades?.map((modalidade) => {
      this.dias = Object.keys(modalidade?.horarios);

      horariosAux[this.restaurante?.nome][modalidade?.codigo] = modalidade?.horarios;

    });

    this.horarios = horariosAux;
  }

  dismiss() {
    this.modalController.dismiss();
  }

  whatsApp() {
    this.utilsService.whatsApp(this.restaurante.telefone);
  }

  navegar(caminho: string): void {
    if (caminho === 'tabs/reservas' && !this.celular) {
      this.dismiss();
    }

    this.utilsService.navegarPagina(caminho);
  }

  public borderDelivery () {
    if (this.type === 'delivery') {
      return 'border_checked'
    }
  }

  public borderRestaurante () {
    if (this.type === 'restaurante') {
      return 'border_checked'
    }
  }

}
