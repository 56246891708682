import { ApiService } from 'src/app/services/api.service';
import { LojaService } from 'src/app/services/observers/loja.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CarrinhoService } from './observers/carrinho.service';
import { ModalsService } from './modals/modals.service';
import { UtilsService } from './utils.service';
import { IEndereco } from '../interfaces/IEndereco.interface';
import { ICarrinho } from '../interfaces/ICarrinho.interface';
import { ModalGenericaPage } from '../modals/modal-generica/modal-generica/modal-generica.page';
import { UsuarioService } from './usuario/usuario.service';
import { IUsuario } from '../interfaces/IUsuario.interface';

@Injectable({
  providedIn: 'root',
})
export class EnderecoService {
  private enderecoListSubject: BehaviorSubject<IEndereco[]> =
    new BehaviorSubject([]);
  enderecoList$: Observable<IEndereco[]> =
    this.enderecoListSubject.asObservable();

  private carrinho: Array<ICarrinho>;
  private usuario: IUsuario;

  constructor(
    private apiService: ApiService,
    private carrinhoService: CarrinhoService,
    private lojaService: LojaService,
    private modalService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    const enderecos = this.utilsService.getStorage('enderecos');
    if (enderecos) {
      this.enderecoListSubject.next(enderecos);
    }

    this.carrinhoService.carrinho$.subscribe((carrinho) => {
      this.carrinho = carrinho;
    });

    this.usuarioService.usuario$.subscribe((usuario) => {
      this.usuario = usuario;
    });
  }

  public atualizarListaEnderecos(novaLista: IEndereco[]): void {
    this.utilsService.setStorage('enderecos', novaLista);

    this.enderecoListSubject.next(novaLista);
  }

  public selecionarEndereco(
    endereco: IEndereco,
    atualizarPrincipal: boolean = false,
    buscarLoja: boolean = true
  ): void {
    let enderecos: Array<IEndereco> = this.utilsService.getStorage('enderecos');

    let search = enderecos.findIndex(function (element: any) {
      if (element.id) {
        return element.id == endereco.id;
      } else {
        return (
          element.bairro == endereco.bairro &&
          element.cep == endereco.cep &&
          element.cidade == endereco.cidade &&
          element.estado == endereco.estado &&
          element.lat == endereco.lat &&
          element.lng == endereco.lng &&
          element.logradouro == endereco.logradouro &&
          element.numero == endereco.numero
        );
      }
    });

    enderecos.splice(search, 1);

    enderecos.unshift(endereco);

    enderecos.map((endereco: IEndereco) => {
      endereco['principal'] = false;
    });
    endereco['principal'] = true;

    if (endereco && endereco.id && atualizarPrincipal) {
      this.atualizarPrincipal(endereco);
    }

    this.utilsService.setStorage('enderecos', enderecos);
    this.enderecoListSubject.next(enderecos);

    if (buscarLoja) this.lojaService.buscarLoja(endereco);
  }

  public async removerEndereco(endereco: IEndereco): Promise<void> {
    if (this.usuario !== null) {
      this.deleteEndereco(endereco);
    } else {
      let enderecos: Array<IEndereco>;
      const param_endereco: IEndereco = endereco;

      this.enderecoList$.subscribe((lista_enderecos) => {
        enderecos = lista_enderecos;
      });

      const paraRemover = enderecos.findIndex(
        (endereco) => endereco === param_endereco
      );

      enderecos.splice(paraRemover, 1);

      this.utilsService.setStorage('enderecos', enderecos);
    }
  }

  private async deleteEndereco(
    endereco: IEndereco
  ): Promise<Array<IEndereco>> | null {
    let enderecos: Array<IEndereco>;

    this.enderecoList$.subscribe((lista_enderecos) => {
      enderecos = lista_enderecos;
    });

    const nova_lista: Array<IEndereco> = enderecos.filter(
      (endereco_filtrado) => endereco_filtrado.id !== endereco.id
    );

    const req = {
      enderecoId: endereco.id,
    };

    const res = await this.apiService.delete('endereco_pedido', req);

    if (res.status == 'success') {
      this.utilsService.exibirToast(res.message, 'sucesso');
      this.atualizarListaEnderecos(nova_lista);

      return nova_lista;
    } else {
      this.utilsService.exibirToast(res.message, 'erro');
      return null;
    }
  }

  public limparDadosArmazenados(): void {
    this.enderecoListSubject.next([]);
  }

  public async verificarCarrinho(
    endereco: IEndereco,
    atualizarPrincipal: boolean = false
  ) {
    if (this.carrinho !== null) {
      const data = {
        titulo: 'Trocar de endereço',
        paragrafos: [
          'Você tem certeza que quer trocar de endereço?',
          'Você possui um carrinho com outra loja, se trocar de endereço perderá seu carrinho atual.',
        ],
        label_acao: 'Trocar de endereço',
        acao: 'alterar-endereco',
      };

      await this.modalService.abrirModal(
        'modal-generica',
        'modal-generica',
        ModalGenericaPage,
        { data: data },
        (params: any) => {
          if (params.data === 'executar') {
            this.selecionarEndereco(endereco, atualizarPrincipal);
            this.carrinhoService.limparCarrinho();
          }
        }
      );
    } else {
      this.selecionarEndereco(endereco, atualizarPrincipal);
    }
  }

  private async atualizarPrincipal(endereco: IEndereco): Promise<void> {
    let req = {
      ...endereco,
      selecionar: true,
    };

    await this.apiService.put('endereco_pedido', req);
  }
}
