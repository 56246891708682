import { ModalsService } from 'src/app/services/modals/modals.service';
import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsService } from '../analytics/analytics.service';
import { ApiService } from '../api.service';
import { LojaService } from './loja.service';
import { UtilsService } from '../utils.service';
import {
  IAdicionais,
  ICarrinho,
  IDadosCarrinho,
  IDadosTotalCarrinho,
  IDesconto,
  IOpcionaisObrigatorios,
} from 'src/app/interfaces/ICarrinho.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IProduto } from 'src/app/interfaces/IProduto.interface';
import { CELULAR } from 'src/app/app.component';
import { ProdutoPage } from 'src/app/modals/produto/produto.page';
import { ShinguruService } from '../shinguru/shinguru.service';

@Injectable({
  providedIn: 'root',
})
export class CarrinhoService {
  private loja: ILoja;

  public carrinhoSubject = new BehaviorSubject<Array<ICarrinho>>(null);
  carrinho$: Observable<Array<ICarrinho>> = this.carrinhoSubject.asObservable();

  public qtdePessoasSubject = new BehaviorSubject<number>(0);
  qtdePessoas$: Observable<number> = this.qtdePessoasSubject.asObservable();

  private dadosCarrinhoSubject = new BehaviorSubject<IDadosCarrinho>({
    total: 0,
    qtd: 0,
  });
  dadosCarrinho$: Observable<IDadosCarrinho> =
    this.dadosCarrinhoSubject.asObservable();

  private valorFinalCarrinhoSubject = new BehaviorSubject<IDadosTotalCarrinho>({
    valor_itens: 0,
    valor_frete: 0,
    valor_desconto_porc: 0,
    frete: 0,
    desconto: null,
    total: 0,
  });
  valorFinalCarrinho$: Observable<IDadosTotalCarrinho> =
    this.valorFinalCarrinhoSubject.asObservable();

  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private location: Location,
    private lojaService: LojaService,
    private utilsService: UtilsService,
    private shinguruService: ShinguruService,
    private modalController: ModalController,
    private modalsService: ModalsService
  ) {
    const carrinho: Array<ICarrinho> = this.utilsService.getStorage('carrinho');
    const qtde_pessoas: number = this.utilsService.getStorage('qtde-pessoas');

    this.lojaService.loja$.subscribe((loja) => {
      this.loja = loja;
    });

    if (carrinho) {
      this.carrinhoSubject.next(carrinho);
      this.atualizarCarrinho(carrinho);
    }

    if (qtde_pessoas) {
      this.qtdePessoasSubject.next(qtde_pessoas);
    }
  }

  public atualizarCarrinho(carrinho: Array<ICarrinho>) {
    this.carrinhoSubject.next(carrinho);

    if (carrinho !== null) this.utilsService.setStorage('carrinho', carrinho);

    this.calcular(carrinho);

    let desconto: IDesconto;
    this.valorFinalCarrinhoSubject.subscribe(() => {
      desconto = this.utilsService.getStorage('desconto');
    });

    if (desconto) {
      this.buscarDesconto(desconto.cupom, carrinho);
    }

    this.calcularTotalFinal(carrinho, this.loja, desconto);
  }

  public async limparCarrinho() {
    this.carrinhoSubject.next(null);
    this.qtdePessoasSubject.next(0);
    this.dadosCarrinhoSubject.next({
      total: 0,
      qtd: 0,
    });

    this.valorFinalCarrinhoSubject.next({
      valor_itens: 0,
      valor_frete: 0,
      valor_desconto_porc: 0,
      frete: 0,
      desconto: null,
      total: 0,
    });

    this.utilsService.removeStorage('carrinho');
    this.utilsService.removeStorage('desconto');

    if(this.utilsService.getStorage('beneficio-shinguru') != null){
      this.shinguruService.cancelarReserva(this.utilsService.getStorage('beneficio-shinguru'));
    }

    if (this.location.path(false) === '/tabs/cardapio') {
      if (await this.modalController.getTop()) {
        this.modalController.dismiss();
      }
    }

    this.utilsService.removeStorage('carrinho-loja-id');
    this.utilsService.navegarPagina('tabs/cardapio');
  }

  public calcular(carrinho: Array<ICarrinho> | null) {
    const quantidade = carrinho
      .map((item) => item.qtd)
      .reduce((valor: number, item: number) => valor + item, 0);

    const total = carrinho
      .map((item) => item.preco.total.valor)
      .reduce((valor: number, item: number) => valor + item, 0);

    this.dadosCarrinhoSubject.next({ total: total, qtd: quantidade });
  }

  public calcularTotalFinal(
    carrinho: Array<ICarrinho>,
    loja: ILoja,
    desconto: IDesconto | null
  ) {

    const valor_carrinho_itens = carrinho
      .map((item) => item.preco.total.valor)
      .reduce((valor: number, item: number) => valor + item, 0);

    const valor_beneficio_itens = carrinho
      .filter((item) => item.beneficio)
      .map((item) => item.preco.total.valor)
      .reduce((valor: number, item: number) => valor + item, 0);

    let frete: number = this.loja.frete.valor;
    let valor_frete = valor_carrinho_itens - valor_beneficio_itens + frete;

    let total: number;
    let valor_desconto_porc: number;

    if (desconto !== null && desconto.tipo !== 'beneficio') {
      frete =
        desconto.frete > 0
          ? (loja.frete.valor * desconto.frete) / 100
          : loja.frete.valor;
      valor_frete = valor_carrinho_itens  - valor_beneficio_itens + frete;

      if (desconto.tipo === 'fixo') {
        total = valor_frete - desconto.desconto.valor;
        valor_desconto_porc = desconto.desconto.valor + valor_beneficio_itens;
      } else if (desconto.tipo === 'porcentagem') {
        valor_desconto_porc =
          (desconto.desconto.valor * (valor_carrinho_itens - valor_beneficio_itens)) / 100;
        total = valor_carrinho_itens - valor_desconto_porc - valor_beneficio_itens + frete;
        valor_desconto_porc = valor_desconto_porc + valor_beneficio_itens;
      }
    } else {
      if(valor_beneficio_itens > 0){
        desconto = {
          categoriaId: null,
          codProduto: null,
          desconto: {
            valor: valor_beneficio_itens,
            texto: this.utilsService.floatToReal(valor_beneficio_itens),
          },
          frete: null,
          cupomId: null,
          id: null,
          tipo: 'beneficio',
          cupom: null,
        }
        valor_desconto_porc = desconto.desconto.valor ;
      }

      total = valor_frete;
    }

    this.valorFinalCarrinhoSubject.next({
      valor_itens: valor_carrinho_itens,
      valor_frete: valor_frete,
      valor_desconto_porc: valor_desconto_porc,
      frete: loja.frete.valor,
      desconto: desconto,
      total: total,
    });


    if (desconto === null){

      if (this.utilsService.getStorage('desconto')){
        this.utilsService.removeStorage('desconto');
      }

      if (this.utilsService.getStorage('beneficio-shinguru')?.tipo === 'cupom'){
        if(this.utilsService.getStorage('beneficio-shinguru') != null){
          this.shinguruService.cancelarReserva(this.utilsService.getStorage('beneficio-shinguru'));
        }
      }
    }

  }

  public async buscarDesconto(cupom: string, carrinho: Array<ICarrinho>) {
    if (cupom === '') {
      this.utilsService.exibirToast('O cupom é obrigatório!', 'erro');
      return;
    }

    const req = {
      cupom: cupom,
      lojaId: this.loja.id,
      carrinho: carrinho,
      modalidade: 'DLV',
      plataforma: 'PWA',
    };

    const res = await this.apiService.post('cupom', req);

    let validade: boolean;

    if (res.status === 'success') {
      validade = true;
      let desconto: IDesconto = {
        ...res.data,
        cupom: cupom,
      };

      carrinho.forEach((item) => {
        if (desconto.codProduto !== null && desconto.codProduto !== '0') {
          if (item.cod !== desconto.codProduto) {
            return;
          }
        }

        if (desconto.categoriaId !== 0) {
          if (item.categoriaId !== desconto.categoriaId) {
            return;
          }
        }
      });

      this.utilsService.setStorage('desconto', desconto);

      this.calcularTotalFinal(carrinho, this.loja, desconto);
    } else {
      validade = false;

      this.utilsService.exibirToast(res.message, 'erro');
      this.calcularTotalFinal(carrinho, this.loja, null);
    }

    const event = this.analyticsService.addCoupon(
      cupom,
      validade,
      this.loja.nome
    );
    this.analyticsService.registrarEvento(event);
  }

  public async adicionarItem(produto: IProduto, toast: boolean = true, beneficio: boolean = false, fechaModal: boolean = true) {

    if (this.loja != null && this.loja.statusLoja == false) {
      this.utilsService.exibirToast('Restaurante fechado no momento.', 'erro');
      if (CELULAR) this.utilsService.navegarPagina('tabs/cardapio');
      return;
    }

    let carrinho: Array<ICarrinho> = [];
    if (this.utilsService.getStorage('carrinho') !== null)
      carrinho = this.utilsService.getStorage('carrinho');

    let opcionais = [];
    let obrigatorios = [];
    let erros = 0;
    let errosAdicionais = 0;

    if (produto.observacoes != undefined) {
      produto.observacoes.forEach((observacoes: any) => {
        switch (observacoes.tipo_opcional) {
          case 'Adicional':
            let adicionaisSelecionados: number = 0;
            if (observacoes.adicionais.length > 0) {
              observacoes.adicionais.forEach((item) => {
                if (item.qtd > 0) {
                  adicionaisSelecionados += item.qtd;
                }
              });

              if (adicionaisSelecionados < observacoes.min) {
                errosAdicionais++;
              }
            }
            break;

          case 'Opcional':
            let opcionalSelecionado = 0;
            if (observacoes.opcionais.length > 0) {
              observacoes.opcionais.forEach((item) => {
                if (item.selecionado) {
                  opcionalSelecionado++;
                }
                opcionais.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                });
              });
              if (opcionalSelecionado == 0) {
                erros++;
              }
            }
            break;

          case 'Obrigatório':
            let obrigatorioSelecionado = 0;
            if (observacoes.obrigatorios.length > 0) {
              observacoes.obrigatorios.forEach((item) => {
                if (item.selecionado) {
                  obrigatorioSelecionado++;
                }
                obrigatorios.push({
                  cod: item.cod,
                  opcional: item.opcional,
                  selecionado: item.selecionado,
                  obrigatorio: true,
                });
              });
              if (obrigatorioSelecionado == 0) {
                erros++;
              }
            }
            break;
          default:
            break;
        }
      });
    }

    if (errosAdicionais > 0) {
      this.utilsService.exibirToast('Por favor preencha todos os campos.');
      return false;
    }


    if (erros != 0) {
      this.utilsService.exibirToast('Por favor preencha todos os campos.');

      const modal_aberta = await this.modalController.getTop();

      if (modal_aberta == undefined) {
        this.modalsService.abrirModal(
          `modal-produto`,
          `modal-produto`,
          ProdutoPage
        );
      }

      return;
    }

    const adicionais = this.adicionais(produto);
    if (typeof adicionais == 'number') {
      this.utilsService.exibirToast(
        `Você deve selecionar ${adicionais} ${adicionais === 1 ? 'item' : 'itens'
        }`
      );

      return false;
    }

    const objProduto = this.criarObjetoProduto(
      produto,
      adicionais,
      opcionais,
      obrigatorios,
      beneficio,
    );

    carrinho.push(objProduto);

    if (carrinho.length == 1) {
      this.utilsService.setStorage('carrinho-loja-id', this.loja.id);
    }

    if (this.loja.id !== this.utilsService.getStorage('carrinho-loja-id')) {
      this.utilsService.exibirToast(
        'Parece que seu carrinho está salvo com outra loja.',
        'erro'
      );
      this.limparCarrinho();
      return;
    }

    this.atualizarCarrinho(carrinho);

    if (await this.modalsService.existeModal() && fechaModal) {
      this.modalsService.fecharModal();
    }

    if (toast) this.utilsService.exibirToast('Produto adicionado com sucesso!', 'sucesso');

    const event = this.analyticsService.item(
      'add_to_cart',
      objProduto,
      this.loja,
      this.utilsService.getStorage('canal-compra') == 'whatsapp'
        ? 'CHATBOT'
        : 'PWA'
    );

    this.analyticsService.registrarEvento(event);
  }

  private adicionais(produto: IProduto): Array<IAdicionais> | number {
    let adicionais: Array<IAdicionais> = [];
    let adicional_selecionado: number = 0;

    if (produto.observacoes !== undefined) {
      for (const observacao of produto.observacoes) {
        if (observacao.tipo_opcional === 'Adicional') {
          for (const adicional of observacao.adicionais) {
            adicional_selecionado += adicional.qtd;

            adicionais.push({
              cod: adicional.cod,
              nome: adicional.opcional,
              preco: {
                unitario: {
                  valor: adicional.preco.unitario,
                  texto: this.utilsService.floatToReal(
                    adicional.preco.unitario
                  ),
                },
                total: {
                  valor: adicional.preco.unitario * adicional.qtd,
                  texto: this.utilsService.floatToReal(
                    adicional.preco.unitario * adicional.qtd
                  ),
                },
              },
              desconto: {
                unitario: {
                  valor: adicional.preco.unitario,
                  texto: this.utilsService.floatToReal(
                    adicional.preco.unitario
                  ),
                },
                total: {
                  valor: adicional.preco.unitario * adicional.qtd,
                  texto: this.utilsService.floatToReal(
                    adicional.preco.unitario * adicional.qtd
                  ),
                },
              },
              qtd: adicional.qtd,
              itemCategory2:
                adicional.sem_escolha != undefined &&
                  adicional.sem_escolha == 'ativo'
                  ? false
                  : true,
            });
          }

          if (adicional_selecionado < parseInt(observacao.min as string) && produto.visivel_cardapio == false) {
            return parseInt(observacao.min as string);
          }
        }
      }
    }

    return adicionais;
  }

  private criarObjetoProduto(
    produto: IProduto,
    adicionais: Array<IAdicionais>,
    opcionais: Array<IOpcionaisObrigatorios>,
    obrigatorios: Array<IOpcionaisObrigatorios>,
    beneficio: boolean = false
  ): ICarrinho {
    return {
      tipo: 'Individual',
      categoria: produto.categoria,
      categoriaId: produto.categoriaId,
      cod: produto.cod,
      id: produto.id,
      imagem: produto.imagem,
      nome: produto.produto,
      descricao: produto.descricao,
      beneficio: beneficio,
      preco: {
        unitario: {
          valor: produto.preco.unitario,
          texto: this.utilsService.floatToReal(produto.preco.unitario),
        },
        total: {
          valor: produto.preco.valor,
          texto: this.utilsService.floatToReal(produto.preco.valor),
        },
      },
      desconto: {
        unitario: {
          valor: produto.preco.unitario,
          texto: this.utilsService.floatToReal(produto.preco.unitario),
        },
        total: {
          valor: produto.preco.valor,
          texto: this.utilsService.floatToReal(produto.preco.valor),
        },
      },
      qtd: produto.qtd,
      adicionais: adicionais,
      obrigatorios: obrigatorios,
      opcionais: opcionais,
      observacao: produto.observacao,
      visivel_cardapio: produto.visivel_cardapio,
      itemCategory2: produto.itemCategory2,
    };
  }

  public quantidadePessoas(qtde: number) {
    this.utilsService.setStorage('qtde-pessoas', qtde);
  }
}
