import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalController } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { AnalyticsService } from 'src/app/services/analytics/analytics.service';
import { CarrinhoService } from 'src/app/services/observers/carrinho.service';
import { EnderecoService } from 'src/app/services/endereco.service';
import { LojaService } from 'src/app/services/observers/loja.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import {
  ICarrinho,
  IDadosTotalCarrinho,
} from 'src/app/interfaces/ICarrinho.interface';
import { IEndereco } from 'src/app/interfaces/IEndereco.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { SlideOptions } from 'src/app/interfaces/ISlide';
import { IProduto } from 'src/app/interfaces/IProduto.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { ProdutoPage } from '../produto/produto.page';
import { ResumoValoresComponent } from 'src/app/components/pedido/resumo-valores/resumo-valores.component';

@Component({
  selector: 'app-carrinho',
  templateUrl: './carrinho.page.html',
  styleUrls: ['./carrinho.page.scss'],
})
export class CarrinhoPage implements OnInit {
  public sugestivosOpt: SlideOptions = {
    slidesPerView: 3.5,
    spaceBetween: 15,
  };

  public usuario: IUsuario;
  public carrinho: Array<ICarrinho>;
  public valores: IDadosTotalCarrinho;
  public cupom: string = '';
  public sugestivos: Array<IProduto> = [];
  public loja: ILoja;
  public endereco: IEndereco;
  public topo: boolean;
  public celular: boolean;
  public carregando: boolean;

  constructor(
    private apiService: ApiService,
    private analyticsService: AnalyticsService,
    private carrinhoService: CarrinhoService,
    private enderecoService: EnderecoService,
    private lojaService: LojaService,
    private mobileService: MobileService,
    private modalController: ModalController,
    private modalService: ModalsService,
    private utilsService: UtilsService,
    private usuarioService: UsuarioService
  ) {
    this.subscribers();

    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;

      if (celular) {
        this.sugestivosOpt = {
          ...this.sugestivosOpt,
          slidesPerView: 2.5,
        };
      }
    });
  }

  subscribers() {
    const loja_sugestivos = combineLatest([
      this.lojaService.loja$,
      this.lojaService.sugestivos$,
    ]);

    loja_sugestivos.subscribe(([loja, sugestivos]) => {
      this.loja = loja;
      this.sugestivos = sugestivos;
    });

    this.enderecoService.enderecoList$.subscribe((enderecos) => {
      if (enderecos.length > 0) this.endereco = enderecos[0];
    });

    const carrinho_valor = combineLatest([
      this.carrinhoService.carrinho$,
      this.carrinhoService.valorFinalCarrinho$,
    ]);

    carrinho_valor.subscribe(([carrinho, valorFinalCarrinho]) => {
      this.carrinho = carrinho;
      this.valores = valorFinalCarrinho;
    });

    if (this.carrinho == null) {
      if (this.celular) this.utilsService.navegarPagina('/tabs/cardapio');
      else this.dismiss();

      this.utilsService.exibirToast('Seu carrinho está vazio.');
    }

    this.carrinhoService.calcularTotalFinal(
      this.carrinho,
      this.loja,
      this.valores.desconto
    );

    if (this.valores.desconto) this.cupom = this.valores.desconto.cupom;

    this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );
  }

  async ngOnInit() {
    this.topo = this.utilsService.mostrarTopo('/tabs/cardapio', '/sacola');

    this.lojaService.sugestivos(this.loja);
  }

  ionViewWillEnter(){
    this.verificarBeneficio();
  }

  ionViewDidEnter() {
    const event = this.analyticsService.itens(
      'view_cart',
      this.carrinho,
      this.loja,
      this.valores
    );

    this.analyticsService.registrarEvento(event);
  }

  private async verificarBeneficio(): Promise<void> {
    let carrinho = this.carrinho || [];
    const beneficio = this.utilsService.getStorage('beneficio-shinguru');
    const itensBeneficio = carrinho.filter(item => item.beneficio);

    if (beneficio) {
        const res = await this.apiService.get('fidelidade_cupom', beneficio);

        if (res.status !== 'success') {
            carrinho = carrinho.filter(item => !item.beneficio);

            this.carrinhoService.atualizarCarrinho(carrinho);
            this.utilsService.removeStorage('desconto');
            this.utilsService.removeStorage('beneficio-shinguru');
            this.utilsService.exibirToast('Encontramos um problema ao resgatar seu benefício. Por favor, tente realizar a reserva novamente..', 'erro');
        }
    } else if (itensBeneficio.length > 0) {
        carrinho = carrinho.filter(item => !item.beneficio);

        this.utilsService.exibirToast('Houve um problema ao resgatar seu benefício. Por favor, adicione-o ao carrinho novamente.', 'erro');
        this.carrinhoService.atualizarCarrinho(carrinho);
    }
  }

  public limpar(): void {
    const event = this.analyticsService.itens(
      'remove_from_cart',
      this.carrinho,
      this.loja,
      this.valores
    );

    this.analyticsService.registrarEvento(event);

    this.carrinhoService.limparCarrinho();
  }

  public acaoDesconto(acao: string): void {
    switch (acao) {
      case 'utilizar':
        this.carrinhoService.buscarDesconto(this.cupom, this.carrinho);
        break;
      case 'remover':
        this.carrinhoService.calcularTotalFinal(this.carrinho, this.loja, null);
      default:
        break;
    }
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public navegar(caminho: string, produto: IProduto = null): void {
    if (!this.celular) {
      this.dismiss();
    }

    if (produto !== null) {
      this.utilsService.setStorage('produto', produto);

      if (!this.celular) {
        this.abrirModal('modal-produto', 'modal-produto', 'produto');

        return;
      }
    }

    if (caminho === 'finalizar-pedido' && this.usuario == null) {
      this.utilsService.exibirToast('Faça login para fechar seu pedido.', 'erro');
      this.utilsService.navegarPagina('login');
    }

    this.utilsService.navegarPagina(caminho);
  }

  public abrirModal(id: string, cssClass: string, component: string): void {
    let el: Function;
    let data: any;

    if (component === 'produto') {
      el = ProdutoPage;
      data = {};
    } else if (component === 'valores') {
      el = ResumoValoresComponent;
      data = this.valores;
    }

    this.modalService.abrirModal(id, cssClass, el, data);
  }
}
