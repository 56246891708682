import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { IonicModule } from '@ionic/angular';

import { Subscription } from 'rxjs';

import { BeneficioResgatavel } from 'src/app/interfaces/Shinguru.interface';
import { CarrinhoPage } from 'src/app/modals/carrinho/carrinho.page';

import { IDadosCarrinho, ICarrinho } from 'src/app/interfaces/ICarrinho.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';

import { ApiService } from 'src/app/services/api.service';
import { CarrinhoService } from 'src/app/services/observers/carrinho.service';
import { LojaService } from './../../../services/observers/loja.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { ShinguruService } from 'src/app/services/shinguru/shinguru.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from './../../../services/utils.service';

import { ModalListagemProdutosPage } from '../../shinguru/modal-listagem-produtos/modal-listagem-produtos.page';

@Component({
  selector: 'app-beneficio-resgatavel',
  templateUrl: './beneficio-resgatavel.component.html',
  styleUrls: ['./beneficio-resgatavel.component.scss'],
  standalone: true,
  imports: [CommonModule, IonicModule],
})
export class BeneficioResgatavelComponent implements OnInit {
  public data: BeneficioResgatavel;

  public circunferencia: number = 2 * Math.PI * 45;
  public strokeDashoffset: number = this.circunferencia;

  private usuario: IUsuario;
  private usuarioSubscription: Subscription;

  private loja: ILoja;
  private lojaSubscription: Subscription;
  private carrinho: Array<ICarrinho>;
  private dados_carrinho: IDadosCarrinho;
  private mobile: boolean = false;

  constructor(
    private apiService: ApiService,
    private carrinhoService: CarrinhoService,
    private lojaService: LojaService,
    private modalsService: ModalsService,
    private mobileService: MobileService,
    private shinguruService: ShinguruService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService,
  ) {

    this.carrinhoService.dadosCarrinho$.subscribe((dadosCarrinho) => {
      this.dados_carrinho = dadosCarrinho;
    })

    this.carrinhoService.carrinho$.subscribe((carrinho) => {
      this.carrinho = carrinho;
    })

    this.mobileService.celular$.subscribe((celular) => {
      this.mobile = celular;
    });

  }

  ngOnInit(): void { }

  ionViewWillEnter(): void {
    this.usuarioSubscription = this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );

    this.lojaSubscription = this.lojaService.loja$.subscribe(
      (loja) => (this.loja = loja)
    );

    this.ativacoes(
      this.data.carteira.regras.limite_cliente.vezes_utilizado,
      this.data.carteira.regras.limite_cliente.limite
    );
  }

  ionViewWillLeave(): void {
    this.usuarioSubscription.unsubscribe();
    this.lojaSubscription.unsubscribe();
  }

  public async verificarBeneficio(canal: string = 'App'): Promise<void> {
    if (this.loja.habilitadoShinguru) {

      const req = {
        jwt: this.usuario.jwt,
        loja_id: this.loja.id,
        beneficio_id: this.data.carteira.id_beneficio,
      }

      const res = await this.apiService.get('beneficios', req);

      if (res.status === 'success') {
        const beneficio = res.data;

        switch (beneficio.tipo) {
          case 'unico':
            this.reservar(canal);
            break;
          case 'cupom':

            const beneficio_shinguru = {
              tipo: 'cupom',
              id_beneficio: this.data.carteira.id_beneficio,
              loja_id: this.loja.id,
              id_empresa_cupom: this.data.carteira.cupom.id_empresa_cupom,
              id_cupom: this.data.carteira.cupom.id_cupom,
              versao_cupom: this.data.carteira.cupom.versao_cupom,
              sequencial: this.data.carteira.cupom.sequencial,
            }

            this.utilsService.setStorage('desconto', beneficio.cupom);
            this.utilsService.setStorage('beneficio-shinguru', beneficio_shinguru);

            const status_reserva = await this.reservar(canal, false, false);

            if (status_reserva) {
              if (this.dados_carrinho.qtd > 0) {
                this.carrinhoService.buscarDesconto(beneficio.cupom.cupom, this.carrinho);

                if (this.mobile) {
                  this.dismiss();
                  this.utilsService.navegarPagina('sacola');
                } else {
                  this.dismiss();
                  this.utilsService.navegarPagina('tabs/cardapio');
                  this.abrirModal(
                    'modal-carrinho',
                    'modal-carrinho',
                    'sacola'
                  );
                }
              } else {
                this.dismiss();
                this.utilsService.exibirToast('Seu carrinho está vazio, selecione quais itens você deseja adicionar para aproveitar seu benefício.');
                this.utilsService.navegarPagina('tabs/cardapio');
              }
            }

            break;
          case 'produto':
            this.modalsService.abrirModal(
              'modal-listagem-produtos',
              'modal-listagem-produtos',
              ModalListagemProdutosPage,
              beneficio
            ).then(async (res) => {

              if (res?.data?.status === 'success') {

                const beneficio_shinguru = {
                  tipo: 'produto',
                  id_beneficio: this.data.carteira.id_beneficio,
                  loja_id: this.loja.id,
                  id_empresa_cupom: this.data.carteira.cupom.id_empresa_cupom,
                  id_cupom: this.data.carteira.cupom.id_cupom,
                  versao_cupom: this.data.carteira.cupom.versao_cupom,
                  sequencial: this.data.carteira.cupom.sequencial,
                }

                this.utilsService.setStorage('beneficio-shinguru', beneficio_shinguru);

                const status_reserva = await this.reservar(canal, false, false);

                if (status_reserva) {
                  if (this.mobile) {
                    this.dismiss();
                    this.utilsService.navegarPagina('sacola');
                  } else {
                    this.dismiss();
                    this.utilsService.navegarPagina('tabs/cardapio');
                    this.abrirModal(
                      'modal-carrinho',
                      'modal-carrinho',
                      'sacola'
                    );
                  }
                }
              }
            });
            break;
        }
      } else {
        this.utilsService.exibirToast(res.message, 'erro');
      }
    } else {
      this.utilsService.exibirToast('Ativação do benefício indisponível para este restaurante, por favor selecione outro.', 'erro');
    }
  }

  public async reservar(canal: string = 'App', fecharModal: boolean = true, exibirToast: boolean = true) {
    const req = {
      jwt: this.usuario.jwt,
      canal: canal,
      id_beneficio: this.data.carteira.id_beneficio,
      nome: this.data.carteira.nome,
      loja_id: this.loja.id,
      id_empresa_cupom: this.data.carteira.cupom.id_empresa_cupom,
      id_cupom: this.data.carteira.cupom.id_cupom,
      versao_cupom: this.data.carteira.cupom.versao_cupom,
      sequencial: this.data.carteira.cupom.sequencial,
    };

    const res = await this.apiService.post('fidelidade_cupom', req);

    if (res.status === 'success') {

      this.shinguruService.carteiraFidelidade(this.usuario.jwt);
      if (exibirToast) this.utilsService.exibirToast(res.message, 'sucesso');
      if (fecharModal) this.dismiss();
      return true
    } else {

      let carrinho = this.carrinho || [];

      for (const item of carrinho) {
        if (item.beneficio) carrinho = carrinho.filter((el) => el.id !== item.id);
      }

      this.carrinhoService.atualizarCarrinho(carrinho);

      this.utilsService.removeStorage('desconto');
      this.utilsService.removeStorage('beneficio-shinguru');
      this.utilsService.exibirToast(res.message, 'erro');
      return false;
    }
  }

  public abrirModal(id: string, cssClass: string, elemento: string): void {
    let el: Function;

    if (this.loja == null) {
      this.utilsService.exibirToast(
        'Parece que o carrinho está cadastrado em outra loja. Vamos limpá-lo, tá?',
        'erro'
      );
      this.carrinhoService.limparCarrinho();
    }

    if (this.dados_carrinho.qtd > 0) el = CarrinhoPage;
    else {
      this.utilsService.exibirToast('Sua sacola está vazia.');
      return;
    }

    this.modalsService.abrirModal(id, cssClass, el);
  }

  private ativacoes(numerador: number, denominador: number): void {
    const progresso: number = (numerador / denominador) * 100;
    const offset =
      this.circunferencia - (progresso / 100) * this.circunferencia;
    this.strokeDashoffset = offset;
  }

  public dismiss(): void {
    this.modalsService.fecharModal();
  }

  public cancelarReserva(): void {
    let carrinho = this.carrinho || [];

    for (const item of carrinho) {
      if (item.beneficio) carrinho = carrinho.filter((el) => el.id !== item.id);
    }

    this.carrinhoService.atualizarCarrinho(carrinho);

    const beneficio_shinguru = {
      loja_id: this.loja.id,
      id_empresa_cupom: this.data.carteira.cupom.id_empresa_cupom,
      id_cupom: this.data.carteira.cupom.id_cupom,
      versao_cupom: this.data.carteira.cupom.versao_cupom,
      sequencial: this.data.carteira.cupom.sequencial,
    };

    this.shinguruService.cancelarReserva(beneficio_shinguru, true);

    this.modalsService.fecharModal();
  }
}
