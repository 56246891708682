import { ApiService } from 'src/app/services/api.service';
import { Component, OnInit } from '@angular/core';

import { ModalsService } from 'src/app/services/modals/modals.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ModalDoacaoPage } from '../modal-doacao/modal-doacao.page';

interface DadosPix {
  status: string;
  codigoPix: string;
  qrcodePix: string;
  nomeLoja: string;
  pedido: number;
}

@Component({
  selector: 'app-modal-pagamento-pix-doacao',
  templateUrl: './modal-pagamento-pix-doacao.page.html',
  styleUrls: ['./modal-pagamento-pix-doacao.page.scss'],
})
export class ModalPagamentoPixDoacaoPage implements OnInit {
  public data!: { valor: number; pixId: string };
  public dados_pix: DadosPix;

  public icon: string = 'copy-outline';

  private interval: any;

  constructor(
    private apiService: ApiService,
    private modalsService: ModalsService,
    private utilsService: UtilsService
  ) {
    this.interval = setInterval(() => {
      this.buscarPix();
    }, 5000);
  }

  ngOnInit(): void {
    this.buscarPix();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  private async buscarPix(): Promise<void> {
    const res = await this.apiService.get('pix', { pixId: this.data.pixId });

    if (res.status === 'success') {
      this.dados_pix = res.data;

      if (res.data.status == 'Pago') {
        this.modalsService
          .abrirModal(
            'modal-pagamento-doacao-pix',
            'modal-doacao',
            ModalDoacaoPage
          )
          .then(() => {
            this.dismiss('conluir-fluxo');
          });

        this.interval = clearInterval(this.interval);
      }
    }
  }

  public dismiss(acao: string = ''): void {
    this.modalsService.fecharModal(acao);
  }

  public copiarCodigo(texto: string): void {
    this.utilsService.copyToClipboard(texto).then((res) => {
      if (res) this.icon = 'copy';
    });
  }
}
