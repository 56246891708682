import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ModalController } from '@ionic/angular';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ICartao } from 'src/app/interfaces/IUsuario.interface';
import { AdicionarCartaoPage } from 'src/app/modals/usuario-v2/adicionar-cartao/adicionar-cartao.page';
import { CELULAR } from 'src/app/app.component';

@Component({
  selector: 'app-meus-cartoes',
  templateUrl: './meus-cartoes.component.html',
  styleUrls: ['./meus-cartoes.component.scss'],
})
export class MeusCartoesComponent implements OnInit {
  @Input() origem: boolean;
  public cartao_selecionado: ICartao;
  public cartoes: Array<ICartao>;
  public data: any;
  public selecionar: boolean = false;
  public celular: boolean = CELULAR;

  constructor(
    private location: Location,
    private modalController: ModalController,
    private modalsService: ModalsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService,
    private api: ApiService
  ) {
    this.usuarioService.usuario$.subscribe((usuario) => {
      let cartoes = usuario?.cartoes;
      if (cartoes?.length === 0 || cartoes == null || cartoes == undefined) {
        this.cartoes = null;
      } else {
        let cartoes = usuario.cartoes.filter(
          (cartao) => cartao.metodo !== 'braspag'
        );

        this.cartoes = cartoes;
      }
    });
  }

  ionViewWillEnter(){
    this.buscaCartao();
  }

  ngOnInit() {
    if (this.location.path(false).includes('/finalizar-pedido')) {
      this.selecionar = true;
    }

    if (this.data) this.cartao_selecionado = this.data.data.cartaoSelecionado;
  }

  public async buscaCartao(){
    const usuario = await this.utilsService.getStorage('usuario');
    const loja = await this.utilsService.getStorage('loja');

    if (usuario != null) {

      const carteira = await this.api.get('carteira', { 'metodo': (loja.metodo_pagamento != null) ? loja.metodo_pagamento : 'mundipagg' });

      if (carteira.data) {
        usuario.cartoes = carteira.data;
        this.cartoes = usuario.cartoes;
      } else {
        usuario.cartoes = [];
      }

      this.utilsService.setStorage('usuario', usuario);
      this.usuarioService.atualizarUsuario(usuario);
    }
  }

  public adicionarCartao() {
    this.modalsService.abrirModal(
      'modal-adicionar-cartao',
      'modal-adicionar-cartao',
      AdicionarCartaoPage,
      {},
      (params: any) => {
        this.buscaCartao();
      }
    );
  }

  public navegar(caminho: string): void {
    this.utilsService.navegarPagina(caminho);
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }
}
