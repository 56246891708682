import { Component, OnInit } from '@angular/core';

import { ModalController } from '@ionic/angular';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-modal-perguntar-doacao',
  templateUrl: './modal-perguntar-doacao.page.html',
  styleUrls: ['./modal-perguntar-doacao.page.scss'],
})
export class ModalPerguntarDoacaoPage implements OnInit {
  public valor_doacao: number | string = '';
  public outro_valor: boolean = false;

  public mask = {
    money: true,
    thousand: '.',
    decimalCaracter: ',',
    decimal: 2,
  };

  constructor(
    private modalController: ModalController,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.utilsService.removeStorage('forma-pagamento-doacao');
  }

  public outroValor(): void {
    this.outro_valor = !this.outro_valor;
    this.valor_doacao = '';
  }

  public valorDoacao(valor: string): void {
    this.valor_doacao = valor;
  }

  public handleDoacao(valor: number | string): void {
    this.modalController.dismiss({
      data: 'executar',
      valor:
        typeof valor === 'string'
          ? this.utilsService.realToFloat(valor)
          : valor,
    });
  }

  public dismiss(acao: string): void {
    this.modalController.dismiss(acao);
  }
}
