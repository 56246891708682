import { Component, OnInit } from '@angular/core';

import { Subscription, combineLatest } from 'rxjs';

import { ModalsService } from 'src/app/services/modals/modals.service';
import { PedidoService } from 'src/app/services/observers/pedido.service';
import { UtilsService } from 'src/app/services/utils.service';

import { IDadosTotalCarrinho } from 'src/app/interfaces/ICarrinho.interface';
import { IPagamentoSelecionado } from 'src/app/interfaces/IPagamento.interface';
import {
  IDetalhesPedido,
  ITracking,
} from 'src/app/interfaces/IPedido.interface';

import { ModalPerguntarDoacaoPage } from 'src/app/modals/modais-instituto-crianca/modal-perguntar-doacao/modal-perguntar-doacao.page';
import { ModalFormaPagamentoDoacaoPage } from 'src/app/modals/modais-instituto-crianca/modal-forma-pagamento-doacao/modal-forma-pagamento-doacao.page';
import { TabsService } from 'src/app/pages/tabs/tabs.service';

@Component({
  selector: 'app-detalhes-pedido',
  templateUrl: './detalhes-pedido.component.html',
  styleUrls: ['./detalhes-pedido.component.scss'],
})
export class DetalhesPedidoComponent implements OnInit {
  public pedido: IDetalhesPedido;
  public progresso: (number | string)[] = [0, 0, 0, 0, ''];
  public resumo_valores: IDadosTotalCarrinho;
  public pagamento: IPagamentoSelecionado;
  public tracking_status: ITracking;
  public topo: boolean;
  public carregando: boolean;
  public visibilidade_tracking: boolean = false;
  private timer: any;

  private pedidoSubscription: Subscription;

  constructor(
    private modalService: ModalsService,
    private pedidoService: PedidoService,
    private tabsService: TabsService,
    private utilsService: UtilsService
  ) {
    this.topo = this.utilsService.mostrarTopo(
      '/tabs/historico',
      '/tabs/detalhes-pedido'
    );
  }

  ngOnInit(): void {
    this.buscarPedido();
    this.subscribePedido();

    this.tabsService.detalhesPedido$.subscribe((detalhes) => {
      if (detalhes.doacao) this.perguntarDoacao();

      if (detalhes.detalhes === '') clearInterval(this.timer);
    });
  }

  ngOnDestroy(): void {
    this.utilsService.removeStorage('pedido-id');
    this.visibilidade_tracking = false;

    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.pedidoSubscription) {
      this.pedidoSubscription.unsubscribe();
    }

    this.tabsService.detalhesPedido('', false);
  }

  ionViewWillEnter(): void {
    this.buscarPedido();
    this.subscribePedido();
  }

  ionViewWillLeave(): void {
    this.utilsService.removeStorage('pedido-id');
    this.visibilidade_tracking = false;

    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.pedidoSubscription) {
      this.pedidoSubscription.unsubscribe();
    }

    this.tabsService.detalhesPedido('', false);
  }

  private subscribePedido(): void {
    const dados_pedido = combineLatest([
      this.pedidoService.pedido$,
      this.pedidoService.resumoValores$,
      this.pedidoService.pagamento$,
      this.pedidoService.progresso$,
      this.pedidoService.trackingStatus$,
    ]);

    this.pedidoSubscription = dados_pedido.subscribe(
      ([pedido, resumoValores, pagamento, progresso, trackingStatus]) => {
        this.pedido = pedido;
        this.resumo_valores = resumoValores;
        this.pagamento = pagamento;
        this.progresso = progresso;
        this.tracking_status = trackingStatus;
      }
    );
  }

  private buscarPedido(): void {
    this.pedidoService.buscarPedido().then(() => {
      if (this.timer) {
        clearInterval(this.timer);
      }

      this.timer = setInterval(() => {
        if (
          this.tracking_status.status == 'Aguardando pagamento' ||
          this.tracking_status.status == 'Pendente' ||
          this.tracking_status.status == 'Confirmado' ||
          this.tracking_status.status == 'Em preparo' ||
          this.tracking_status.status == 'Saiu para entrega' ||
          this.tracking_status.status == 'Pronto para retirar'
        ) {
          this.pedidoService.buscarPedido();
        }
      }, 3000);
    });
  }

  public perguntarDoacao(): void {
    this.modalService.abrirModal(
      'modal-perguntar-doacao',
      'modal-doacao perguntar',
      ModalPerguntarDoacaoPage,
      {},
      (params: any) => {
        if (
          params.data &&
          params.data.data === 'executar' &&
          params.data.valor
        ) {
          this.selecionarPagamento(params.data.valor);
        } else {
          this.utilsService.removeStorage('forma-pagamento-doacao');
          this.carregando = false;
        }
      }
    );
  }

  private selecionarPagamento(valor: number): void {
    this.modalService.abrirModal(
      'modal-pagamento-doacao',
      'modal-doacao',
      ModalFormaPagamentoDoacaoPage,
      { data: valor },
      (params: any) => {
        if (params.data === 'executar') {
        } else {
          this.carregando = false;
        }
      }
    );
  }

  public navegar(caminho: string): void {
    this.utilsService.navegarPagina(caminho);

    if (this.pedidoSubscription) {
      this.pedidoSubscription.unsubscribe();
    }

    this.tabsService.detalhesPedido('', false);
  }
}
