import { Injectable } from '@angular/core';

@Injectable({
  providedIn:'root'
})
export class AuthService {

  private semana:object;
  private dias:object;
  private meses: object;
  
  constructor() { 

    this.semana = {
      0: 'J68rK',
      1: 'gu2OJ',
      2: '2k7o3',
      3: 'nyBzK',
      4: 'WaTbH',
      5: 'o67Ly',
      6: 'Esmcv',
    }; 

    this.dias = {
      1: '7LaTO',
      2: '4IUtc',
      3: 'SCsxF',
      4: '0iHZD',
      5: 'V7wYF',
      6: 'XlKZb',
      7: '28Fm1',
      8: 'PkAKh',
      9: '1Xu2g',
      10: '4QlWz',
      11: 'TklWy',
      12: 'ocMTD',
      13: 'U2swM',
      14: 'gFBaZ',
      15: '6sLjw',
      16: 'oYJw3',
      17: 'WVPZv',
      18: 'DaVLM',
      19: 'bwqiH',
      20: '3laOJ',
      21: 'zP6td',
      22: 'f1omD',
      23: '1a6L8',
      24: 'K19xF',
      25: '2PTE8',
      26: 'CUAWu',
      27: 'KspT5',
      28: 'WkYhG',
      29: '6Cm2n',
      30: 'gtWho',
      31: '8GqTa'
    }; 

    this.meses = {
      1: 'CouhQ',
      2: 'aVHsN',
      3: 'SjFws',
      4: '5HA8r',
      5: 'V4uZ8',
      6: '4rGNa',
      7: 'qWhcP',
      8: 'fQRgr',
      9: 'njxZo',
      10: '2yc4k',
      11: 'HS1fA',
      12: 'IrFlB'
    }; 

  }

  public gerarEndpoint() {
    const hoje = new Date();
    return `${this.semana[hoje.getDay()]}.${this.dias[hoje.getDate()]}.${this.meses[hoje.getMonth() + 1]}`;
  }

}
