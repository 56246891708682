import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private utilsService: UtilsService) {}

  private removerEspacos(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      const control = formGroup.controls[key];
      if (control instanceof FormGroup) {
        this.removerEspacos(control);
      } else if (control.value && typeof control.value === 'string') {
        control.setValue(control.value.trim());
      }
    });
  }

  public validaForm(form: FormGroup, control: Record<string, any>) {
    this.removerEspacos(form);

    if (form.valid) {
      return true;
    } else {
      for (const key in form.controls) {
        if (
          form.controls[key].errors != null &&
          form.controls[key].errors?.['required']
        ) {
          this.utilsService.exibirToast(
            `O campo ${control[key]} é obrigatório!`,
            'erro'
          );
          return false;
        }

        if (form.controls[key].errors?.['cpf']) {
          this.utilsService.exibirToast('CPF inválido!', 'erro');
          return false;
        }

        if (form.controls[key].errors?.['email']) {
          this.utilsService.exibirToast('E-mail inválido!', 'erro');
          return false;
        }

        if (form.controls[key].errors?.['celular']) {
          this.utilsService.exibirToast('Número de celular inválido!', 'erro');
          return false;
        }

        if (form.controls[key].errors?.['senha']) {
          this.utilsService.exibirToast(
            'A senha não atende aos requisitos mínimos!',
            'erro'
          );
          return false;
        }

        if (form.controls[key].errors?.['data_nascimento']) {
          this.utilsService.exibirToast('Data de nascimento inválida!', 'erro');
          return false;
        }

        if (form.controls[key].errors?.['cep']) {
          this.utilsService.exibirToast('CEP inválido!', 'erro');
          return false;
        }

        if (form.controls[key].errors?.['validade']) {
          this.utilsService.exibirToast('Data de validade expirada!', 'erro');
          return false;
        }
      }

      return false;
    }
  }

  public validaCelular(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (control.value.length < 15) {
        return { celular: true };
      }

      return null;
    };
  }

  public validaCEP(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      if (control.value.length < 9) {
        return { cep: true };
      }

      return null;
    };
  }

  public validaCPF(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value) {
        return of(null);
      }

      const cpf = control.value.replace(/\D/g, '');

      let soma: number = 0;
      let resto: number;

      const cpfs = [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ];

      if (cpfs.includes(cpf)) {
        return of({ cpf: true });
      }

      for (let index = 1; index <= 9; index++) {
        soma += parseInt(cpf.substring(index - 1, index)) * (11 - index);
      }

      resto = (soma * 10) % 11;
      resto = resto === 10 || resto === 11 ? 0 : resto;

      if (resto !== parseInt(cpf.substring(9, 10))) {
        return of({ cpf: true });
      }

      soma = 0;

      for (let index = 1; index <= 10; index++) {
        soma += parseInt(cpf.substring(index - 1, index)) * (12 - index);
      }

      resto = (soma * 10) % 11;
      resto = resto === 10 || resto === 11 ? 0 : resto;

      if (resto !== parseInt(cpf.substring(10, 11))) {
        return of({ cpf: true });
      }

      return of(null);
    };
  }

  public validaData(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const data = control.value;

      if (!data) {
        return null;
      }

      if (data.length < 10) {
        return { data_nascimento: true };
      }

      const [dia, mes, ano] = data.split('/').map(Number);
      const data_nascimento = new Date(ano, mes - 1, dia);
      const idade = this.calcularIdade(data_nascimento);

      if (isNaN(data_nascimento.getTime()) || idade < 16 || idade > 100) {
        return { data_nascimento: true };
      }

      return null;
    };
  }

  private calcularIdade(data: Date): number {
    const hoje = new Date();
    const nascimento = new Date(data);
    const idade = hoje.getFullYear() - nascimento.getFullYear();

    return hoje.setFullYear(2000) < nascimento.setFullYear(2000)
      ? idade - 1
      : idade;
  }

  public validaSenha(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const senha = control.value;

      if (senha && (senha.length < 5 || senha.length > 30)) {
        return { senha: true };
      }

      const maiscula = /[A-Z]/.test(senha);
      const minuscula = /[a-z]/.test(senha);
      const numero = /[0-9]/.test(senha);

      if (!(maiscula && minuscula && numero)) {
        return { senha: true };
      }

      return null;
    };
  }

  public validaValidade(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const data = control.value;
      if (!data) {
        return null;
      }

      const [mes, ano] = data.split('/');
      const anoNumerico =
        Number(ano) > 40 ? 1900 + Number(ano) : 2000 + Number(ano);

      const hoje = new Date();
      const validade = new Date(anoNumerico, Number(mes) - 1, 1);

      if (hoje.getTime() > validade.getTime()) {
        return { validade: true };
      } else {
        return null;
      }
    };
  }
}
