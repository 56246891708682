import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ModalsService } from './../../../services/modals/modals.service';
import { PedidoService } from 'src/app/services/observers/pedido.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';
import {
  IAcao,
  ModalGenericaPage,
} from 'src/app/modals/modal-generica/modal-generica/modal-generica.page';
import { ICartao, IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { MeusCartoesComponent } from '../../usuario-v2/meus-cartoes/meus-cartoes.component';
import { IPagamentoSelecionado } from 'src/app/interfaces/IPagamento.interface';
import { BANDEIRAS as bandeiras } from 'src/app/services/observers/formas-pagamento.service';

@Component({
  selector: 'app-card-cartao',
  templateUrl: './card-cartao.component.html',
  styleUrls: ['./card-cartao.component.scss'],
})
export class CardCartaoComponent implements OnInit {
  @Output() trocarCartao: EventEmitter<any> = new EventEmitter();

  @Input() doacao: boolean = false;
  @Input() cartao_selecionado_doacao: ICartao;

  @Input() cartao: ICartao;
  @Input() cartao_selecionado: ICartao;

  @Input() fundo_escuro: boolean;

  @Input() selecionar: boolean;
  @Input() deletar: boolean = false;
  @Input() trocar: boolean = false;

  private usuario!: IUsuario | null;

  constructor(
    private apiService: ApiService,
    private modalsService: ModalsService,
    private pedidoService: PedidoService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.usuarioService.usuario$.subscribe(
      (usuario) => (this.usuario = usuario)
    );
  }

  ngOnInit() {}

  public selecionarCartao(cartao: ICartao): void {
    let data: IPagamentoSelecionado = {
      tipo_forma: 'no app',
      tipo_forma_pgto: 'online',
      forma: '',
      label: cartao.tipo,
      bandeira: cartao.bandeira,
      nome: this.capitalizeWords(cartao.bandeira),
      imagem: '',
      cartao: cartao,
    };

    if (cartao.tipo === 'Crédito') data.forma = 'CREDIT';
    else if (cartao.tipo === 'Débito') data.forma = 'DEBIT';

    const bandeira = Object.keys(bandeiras).find((bandeira) => {
      return bandeira === cartao.bandeira.toLocaleUpperCase();
    });

    data.imagem = bandeiras[bandeira].imagem;

    if(this.doacao){
      if (cartao) {
        this.cartao_selecionado_doacao = cartao;
        this.utilsService.setStorage('forma-pagamento-doacao', data);

        let dataformaPagamento: IPagamentoSelecionado = {
          tipo_forma: 'na entrega',
          tipo_forma_pgto: 'entrega',
          forma: 'CASH',
          label: 'Dinheiro',
          bandeira: '',
          nome: '',
          imagem: '/assets/icon/pagamento_dinheiro.svg',
          cartao: null,
          valor: 0,
        };

        this.pedidoService.formaPagamento(dataformaPagamento);
      }
    }else{
      this.modalsService.fecharModal();
      this.pedidoService.formaPagamento(data);
    }


  }

  public excluirCartaoModal(cartao: ICartao): void {
    const data: IAcao = {
      titulo: 'Excluir cartão',
      paragrafos: [
        '',
        'Você está excluindo o cartão:',
        'Ao confirmar, ele não estará mais na sua lista de cartões salvos no Gurumê.',
      ],
      paragrafo_secundario:
        'Ao confirmar, ele não estará mais na sua lista de cartões salvos no Gurumê.',
      strong: `Cartão de ${cartao.tipo} • ${cartao.bandeira} • *** ${cartao.final}`,
      label_acao: 'Excluir cartão',
      acao: 'excluir-cartao',
    };

    this.modalsService.abrirModal(
      'modal-generica',
      'modal-generica',
      ModalGenericaPage,
      {
        data: data,
      },
      (params: { data: string }) => {
        if (params.data === 'executar') {
          this.excluirCartao(cartao);
        }
      }
    );
  }

  private async excluirCartao(cartaoSelecionado: ICartao): Promise<void> {
    this.utilsService.exibirLoading();

    let endpoint: string = 'cartao';

    if (this.doacao) endpoint = 'cartao_cielo';

    const res = await this.apiService.delete(endpoint, {
      cartao_id: cartaoSelecionado.id,
    });

    this.utilsService.ocultarLoading();

    if (res.status === 'success') {
      let cartoes: Array<ICartao> = this.usuario.cartoes;
      cartoes.splice(
        cartoes.findIndex((cartao) => cartao.id === cartaoSelecionado.id),
        1
      );

      this.usuario.cartoes = cartoes;

      this.usuarioService.atualizarUsuario(this.usuario);

      const formaPagamento = this.utilsService.getStorage('forma-pagamento');

      if(formaPagamento && formaPagamento.cartao.id === cartaoSelecionado.id){
        let data: IPagamentoSelecionado = {
          tipo_forma: 'na entrega',
          tipo_forma_pgto: 'entrega',
          forma: 'CASH',
          label: 'Dinheiro',
          bandeira: '',
          nome: '',
          imagem: '/assets/icon/pagamento_dinheiro.svg',
          cartao: null,
          valor: 0,
        };

        this.pedidoService.formaPagamento(data);
      }

      this.utilsService.exibirToast(res.message, 'sucesso');
    } else {
      this.utilsService.exibirToast(res.message, 'erro');
    }

    if (this.doacao) {
      this.cartao_selecionado_doacao = null;
      this.utilsService.removeStorage('forma-pagamento-doacao');
    }
  }

  public capitalizeWords(string: string): string {
    return this.utilsService.capitalizeWords(string);
  }
}
