import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IDadosTotalCarrinho } from 'src/app/interfaces/ICarrinho.interface';
import { IPagamentoSelecionado } from 'src/app/interfaces/IPagamento.interface';
import {
  IDetalhesPedido,
  ITracking,
} from 'src/app/interfaces/IPedido.interface';
import { AnalyticsService } from '../analytics/analytics.service';
import { ApiService } from 'src/app/services/api.service';
import { CarrinhoService } from './carrinho.service';
import { LojaService } from './loja.service';
import { UtilsService } from 'src/app/services/utils.service';

@Injectable({
  providedIn: 'root',
})
export class PedidoService {
  private pedidoSubject = new BehaviorSubject<IDetalhesPedido>(null);
  pedido$: Observable<IDetalhesPedido> = this.pedidoSubject.asObservable();

  private progressoSubject = new BehaviorSubject<(number | string)[]>([
    0,
    0,
    0,
    0,
    '',
  ]);
  progresso$: Observable<(number | string)[]> =
    this.progressoSubject.asObservable();

  private resumoValoresSubject = new BehaviorSubject<IDadosTotalCarrinho>(null);
  resumoValores$: Observable<IDadosTotalCarrinho> =
    this.resumoValoresSubject.asObservable();

  public pagamentoSubject = new BehaviorSubject<IPagamentoSelecionado>(null);
  pagamento$: Observable<IPagamentoSelecionado> =
    this.pagamentoSubject.asObservable();

  public pagamentoSelecionadoSubject =
    new BehaviorSubject<IPagamentoSelecionado>(null);
  pagamentoSelecionado$: Observable<IPagamentoSelecionado> =
    this.pagamentoSelecionadoSubject.asObservable();

  private trackingStatusSubject = new BehaviorSubject<ITracking>(null);
  trackingStatus$: Observable<ITracking> =
    this.trackingStatusSubject.asObservable();

  constructor(
    private analyticsService: AnalyticsService,
    private apiService: ApiService,
    private carrinhoService: CarrinhoService,
    private lojaService: LojaService,
    private utilsService: UtilsService
  ) {
    const pagamento_selecionado =
      this.utilsService.getStorage('forma-pagamento');

    if (pagamento_selecionado)
      this.pagamentoSelecionadoSubject.next(pagamento_selecionado);
  }

  private definirPedido(pedido: IDetalhesPedido) {
    this.pedidoSubject.next(pedido);
  }

  private progressoPedido(status: ITracking) {
    switch (status.status) {
      case 'Aguardando pagamento':
        this.progressoSubject.next([1, 0, 0, 0, 'success']);
        break;
      case 'Pendente':
        this.progressoSubject.next([1, 0, 0, 0, 'success']);
        break;
      case 'Confirmado':
        this.progressoSubject.next([1, 0.5, 0, 0, 'success']);
        break;
      case 'Em preparo':
        this.progressoSubject.next([1, 1, 0.5, 0, 'success']);
        break;
      case 'Saiu para entrega':
        this.progressoSubject.next([1, 1, 1, 0, 'success']);
        break;
      case 'Pronto para retirar':
        this.progressoSubject.next([1, 1, 1, 1, 'success']);
        break;
      case 'Concluído':
        this.progressoSubject.next([1, 1, 1, 1, 'success']);
        break;
      case 'Cancelado':
        this.progressoSubject.next([0.5, 0, 0, 0, 'danger']);
        break;
      default:
        this.progressoSubject.next([0, 0, 0, 0, '']);
        break;
    }
  }

  async buscarPedido() {
    const res = await this.apiService.get('pedido', {
      pedidoId: this.utilsService.getStorage('pedido-id'),
    });

    if (res.status === 'success') {
      let pedido: IDetalhesPedido = res.data;

      this.definirPedido(pedido);

      this.trackingStatusSubject.next(
        pedido.historicoStatus[pedido.historicoStatus.length - 1]
      );
      let tracking_status = this.trackingStatusSubject.value;

      this.progressoPedido(tracking_status);

      this.resumoValoresSubject.next({
        valor_itens: this.utilsService.realToFloat(pedido.subtotal),
        frete: this.utilsService.realToFloat(pedido.frete),
        valor_desconto_porc: this.utilsService.realToFloat(pedido.desconto),
        total: this.utilsService.realToFloat(pedido.total),
      });
    }
  }

  public formaPagamento(params: IPagamentoSelecionado): void {
    if (params === undefined || params === null) {
      this.pagamentoSelecionadoSubject.next(
        this.utilsService.getStorage('forma-pagamento')
      );
    } else {
      this.pagamentoSelecionadoSubject.next(params);
      this.utilsService.setStorage('forma-pagamento', params);
    }

    let valores: IDadosTotalCarrinho;
    let payment_type = {
      payment_type: `${
        this.pagamentoSelecionadoSubject?.value?.bandeira
          ? this.pagamentoSelecionadoSubject.value.bandeira.toLocaleLowerCase()
          : ''
      } ${
        this.pagamentoSelecionadoSubject?.value?.forma
          ? this.pagamentoSelecionadoSubject.value.forma.toLocaleLowerCase()
          : ''
      } ${
        this.pagamentoSelecionadoSubject?.value?.tipo_forma_pgto
          ? this.pagamentoSelecionadoSubject.value.tipo_forma_pgto.toLocaleLowerCase()
          : ''
      }`,
    };

    this.carrinhoService.valorFinalCarrinho$.subscribe((valorFinal) => {
      valores = valorFinal;
    });

    const event = this.analyticsService.itens(
      'add_payment_info',
      this.carrinhoService.carrinhoSubject.value,
      this.lojaService.lojaSubject.value,
      valores,
      payment_type
    );

    this.analyticsService.registrarEvento(event);
  }
}
