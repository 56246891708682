import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';

import {
  AcaoBotao,
  Elementos,
  Estilos,
  Links,
  Tipo,
} from 'src/app/interfaces/Topo.interface';
import { IDadosCarrinho } from 'src/app/interfaces/ICarrinho.interface';
import { IEndereco } from 'src/app/interfaces/IEndereco.interface';
import { ILoja } from 'src/app/interfaces/ILoja.interface';
import { IUsuario } from 'src/app/interfaces/IUsuario.interface';
import { Nivel } from 'src/app/interfaces/Shinguru.interface';

import { CarrinhoService } from 'src/app/services/observers/carrinho.service';
import { EnderecoService } from 'src/app/services/endereco.service';
import { LojaService } from 'src/app/services/observers/loja.service';
import { MobileService } from 'src/app/services/mobile/mobile.service';
import { ModalsService } from 'src/app/services/modals/modals.service';
import { ShinguruService } from 'src/app/services/shinguru/shinguru.service';
import { TabsService } from 'src/app/pages/tabs/tabs.service';
import { UsuarioService } from 'src/app/services/usuario/usuario.service';
import { UtilsService } from 'src/app/services/utils.service';

import { MeusEnderecosComponent } from '../usuario-v2/meus-enderecos/meus-enderecos.component';
import { CarrinhoPage } from 'src/app/modals/carrinho/carrinho.page';

@Component({
  selector: 'app-topo',
  templateUrl: './topo.component.html',
  styleUrls: ['./topo.component.scss'],
})
export class TopoComponent implements OnInit {
  @Input() tipo: Tipo = {
    padrao: true,
    generico: false,
  };
  @Input() estilos: Estilos = {
    carrinho: false,
    pesquisa: false,
    produto: false,
    marrom: false,
  };
  @Input() el: Elementos = {
    endereco: false,
    shinguru: false,
    ul: true,
  };

  @Input() voltar: boolean = false;
  @Input() fechar: boolean = false;
  @Input() lupa: boolean = true;

  @Output() navegar: EventEmitter<void> = new EventEmitter<void>();
  @Output() limpar: EventEmitter<void> = new EventEmitter<void>();
  @Output() pesquisar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() palavraChave: EventEmitter<any> = new EventEmitter<any>();

  @Input() texto: string;

  private subscriptions = new Subscription();

  public celular: boolean;

  public convite: boolean = false;

  public endereco: IEndereco;
  public loja: ILoja;
  public usuario: IUsuario;
  public dados_fidelidade: Nivel | null;

  public acao_botao: AcaoBotao;
  public dados_carrinho: IDadosCarrinho;

  public links: Links[] = [
    {
      link: '/tabs/cardapio',
      label: 'Cardápio',
    },
    {
      link: '/tabs/historico',
      label: 'Pedidos',
    },
    // {
    //   link: '/tabs/cupons',
    //   label: 'Cupons',
    // },
    {
      link: '/tabs/institucional-gurume',
      label: 'O Gurumê',
    },
    {
      link: '/tabs/reservas',
      label: 'Reserva',
    },
    {
      link: '/tabs/restaurantes',
      label: 'Restaurantes',
    },
  ];

  constructor(
    private carrinhoService: CarrinhoService,
    private enderecoService: EnderecoService,
    private lojaService: LojaService,
    private mobileService: MobileService,
    private modalService: ModalsService,
    private shinguruService: ShinguruService,
    private tabService: TabsService,
    private usuarioService: UsuarioService,
    private utilsService: UtilsService
  ) {
    this.mobileService.celular$.subscribe((celular) => {
      this.celular = celular;
    });
  }

  ngOnInit(): void {
    this.subscribers();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public irPagina(caminho: string): void {
    if (caminho.includes('shinguru')) {
      this.shinguruService.verConvite(this.usuario.jwt);
    }
  }

  private async subscribers(): Promise<void> {
    this.subscriptions.add(
      this.enderecoService.enderecoList$.subscribe((enderecos) => {
        this.endereco = enderecos && enderecos.length > 0 ? enderecos[0] : null;
      })
    );

    this.subscriptions.add(
      this.lojaService.loja$.subscribe((loja) => {
        this.loja = loja;
        this.shinguruLink(loja, this.usuario);
      })
    );

    this.subscriptions.add(
      this.usuarioService.usuario$.subscribe(async (usuario) => {
        this.usuario = usuario;
        this.acao_botao = this.handleAcaoBotao(usuario);
        this.dados_fidelidade = await this.handleFidelidade(usuario);
        this.shinguruLink(this.loja, usuario);
      })
    );

    this.subscriptions.add(
      this.carrinhoService.dadosCarrinho$.subscribe((dadosCarrinho) => {
        this.dados_carrinho = dadosCarrinho;
      })
    );

    this.shinguruService.convite$.subscribe((convite) => {
      if (convite) {
        this.convite = convite;
      } else {
        this.convite = this.utilsService.getStorage('convite');
      }
    });

    this.shinguruLink(this.loja, this.usuario);
  }

  private shinguruLink(loja: ILoja, usuario: IUsuario): void {
    if (
      usuario &&
      this.dados_fidelidade &&
      this.dados_fidelidade.nivel === 4 &&
      loja
    ) {
      if (this.links.filter((link) => link.label == 'Shinguru').length == 0) {
        this.links.push({
          link: '/tabs/shinguru', label: 'Shinguru'
        });
      }

    } else {
      this.links = this.links.filter((link) => link.label !== 'Shinguru');
    }
  }

  private handleAcaoBotao(usuario: IUsuario): AcaoBotao {
    return {
      pagina: usuario ? '/tabs/perfil' : '/login',
      alt: usuario ? 'Vá para o perfil' : 'Faça login!',
    };
  }

  private async handleFidelidade(usuario: IUsuario): Promise<Nivel | null> {
    return usuario
      ? await this.shinguruService.saldoFidelidade(usuario.jwt)
      : null;
  }

  public abrirModal(id: string, cssClass: string, elemento: string): void {
    let el: Function;

    switch (elemento) {
      case 'lista-endereco':
        el = MeusEnderecosComponent;
        break;
      case 'sacola':
        if (this.loja == null) {
          this.utilsService.exibirToast(
            'Parece que o carrinho está cadastrado em outra loja. Vamos limpá-lo, tá?',
            'erro'
          );
          this.carrinhoService.limparCarrinho();
        }

        if (this.dados_carrinho.qtd > 0) el = CarrinhoPage;
        else {
          this.utilsService.exibirToast('Sua sacola está vazia.');
          return;
        }
        break;
      default:
        break;
    }

    this.modalService.abrirModal(id, cssClass, el);
  }

  public irPara(caminho: string): void {
    this.utilsService.navegarPagina(caminho);

    if (caminho === '/tabs/perfil')
      this.tabService.emitirTabSelecionada('tabs/minha-conta');
  }

  get estilosPersonalizados() {
    const nivel = this.dados_fidelidade?.nivel === 4;
    const background = nivel ? this.dados_fidelidade?.border_color : this.dados_fidelidade?.background || '';
    const borderColor = nivel ? this.dados_fidelidade?.background : this.dados_fidelidade?.border_color || '';

    return {
      '--border-color': borderColor,
      '--background': background,
    };
  }
}
